import { rewardBundle } from '../actions/actionTypes';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';
import axios from 'axios';
import { setRewardBundles } from '@/modules/casino/store/actions/reward_bundle';
import { put, takeLatest } from 'redux-saga/effects';

function* fetchRewardBundlesSaga(action: any): any {
  const apiUrl = window.config.rewardBundlesApiUrl + '/player/status';

  const state = ClientAPI.getStore().getState();
  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    throw Error('not authenticated');
  }

  try {
    const data = yield axios.post(
      apiUrl,
      {
        amount: 100,
      },
      {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      },
    );
    console.log(data);
    if (data.status === 200 && data.data) {
      yield put(setRewardBundles(data.data));
    }
  } catch (error) {
    console.error(error);
  }
}


export default function* watchRewardBundle() {
    yield takeLatest(rewardBundle.FETCH, fetchRewardBundlesSaga);
}
