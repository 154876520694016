import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { useMediaQuery } from '../../../../utils/useQueryMedia';
import { betsSlipSetForceShow } from '@/modules/bets/store/actions/betsSlip';

import './index.scss';

type PopupBetslip = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const PopupBetslip = (componentProps: PopupBetslip) => {
  const noBets = useAppSelector((state) => {
    const bst = state.bets;
    const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

    return ct.prematch.selected.length + ct.live.selected.length;
  });
  const dispatch = useAppDispatch();
  const betBuilder = useAppSelector((state) => state.bets.betBuilder);
  const [fullBetslip, setFullBetslip] = React.useState(false);
  const [expandedBetBuilder, setExpandedBetBuilder] = React.useState(false);
  const isDesktop = useMediaQuery('(min-width:900px)');
  const matches = useMatches();

  const forceShowBetslip = useAppSelector((state) => state.bets.betsSlip.forceShowBetslip);

  const onToggleFullBetslip = () => {
    if (forceShowBetslip) {
      dispatch(betsSlipSetForceShow(false));
    } else {
      if (betBuilder.selected.length > 0 && betBuilder.open) {
        setExpandedBetBuilder((v) => !v);
        return;
      }
      setFullBetslip((v) => {
        if (!v) {
          document.body.style.overflow = 'hidden';
        } else if (document.body.style.overflow === 'hidden') {
          document.body.style.overflow = '';
        }
        return !v;
      });
    }
  };

  const onNotifyOpen = (willOpen: boolean) => {
    if (willOpen) {
      if (betBuilder.selected.length > 0 && betBuilder.open) {
        setExpandedBetBuilder(true);
      } else {
        setFullBetslip(true);
      }
      document.body.style.overflow = 'hidden';
    }
  };

  const onNotifyClose = (willClose: boolean) => {
    if (willClose) {
      if (betBuilder.selected.length > 0 && betBuilder.open) {
        setExpandedBetBuilder(false);
      } else {
        setFullBetslip(false);
      }
      document.body.style.overflow = '';
    }
  };

  React.useEffect(() => {
    if (fullBetslip && noBets === 0) {
      setFullBetslip(false);
      document.body.style.overflow = '';
    }
  }, [noBets, fullBetslip]);

  React.useEffect(() => {
    if (!(betBuilder.selected.length > 0 && betBuilder.open)) {
      setExpandedBetBuilder(false);
    }
  }, [betBuilder]);

  const contextValue = React.useMemo(() => {
    const showBetBuilderSummary =
      betBuilder.selected.length > 0 && betBuilder.open && window.config.betBuilderEnable === '1';
    const fsb = !showBetBuilderSummary && forceShowBetslip;

    return {
      showBetBuilderSummary:
        betBuilder.selected.length > 0 && betBuilder.open && window.config.betBuilderEnable === '1',
      betBuilderExpanded: expandedBetBuilder,
      noBets: fsb ? 1 : noBets,
      fullBetslip: fsb ? true : fullBetslip,
      onToggleFullBetslip: onToggleFullBetslip,
      onNotifyOpen,
      onNotifyClose,

      toggleForceShowBetslip: () => {
        dispatch(betsSlipSetForceShow(!forceShowBetslip));
      },
    };
  }, [noBets, fullBetslip, onToggleFullBetslip, expandedBetBuilder, onNotifyOpen, onNotifyClose, forceShowBetslip]);

  if (isDesktop) {
    if (!componentProps.properties.onDesktop) {
      return null;
    }
  }

  if (matches?.length > 0 && !componentProps.properties.preventHide) {
    const path = matches[matches.length - 1].pathname;

    let hide = true;
    if (path === '/') {
      hide = false;
    } else if (path.indexOf('/bets/') === 0) {
      hide = false;
    } else if (path.indexOf('/winnerfun/') === 0) {
      hide = false;
    } else if (path.indexOf('/betfeed') === 0) {
      hide = false;
    } else if (path.indexOf('/test') === 0) {
      hide = false;
    }

    if (hide) {
      return null;
    }
  }

  if (matches?.length > 0 && componentProps.properties.hideOnHomepage) {
    const path = matches[matches.length - 1].pathname;

    if (path === '/') {
      return null;
    }
  }

  //console.log('PopupBetslip[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default PopupBetslip;
