import {
  Card,
  PaymentProviderInterface,
} from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import Utils from '@/modules/casino/utils/Utils';
import PaymentProvider, {
  PaymentProviderConstructor,
} from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import {
  HTML_3DS_IFRAME_ID,
  HTML_3DS_IFRAME_WRAPPER,
  PaymentProvider as PaymentProviderConstant,
  typeCards,
} from '@/constants/paymentProvider';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker';
import {
  bridgerPayDepositResponse,
  fetchPlayerPaymentMethods,
  finishBridgerPayDeposit,
  initiateBridgerPayDeposit,
} from '@/modules/casino/store/actions/deposit';
import BridgerApplePay from '@/components/classes/PaymentProviders/Deposit/Bridger/BridgerApplePay';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';

const RETRIES = 50;

class Bridger extends PaymentProvider implements PaymentProviderInterface {
  private applePay: undefined | BridgerApplePay;
  private cardsFetched: boolean = false;
  private cashierToken: string | boolean | undefined;
  private apCashierKey: string | boolean | undefined;
  private apCashierToken: string | boolean | undefined;
  private bloxController: any;
  private bloxSettings: any = {};
  private bridgerCardInfo:
    | {
        token: string;
        encryptedCVV: string;
      }
    | undefined;
  private inputTimeout: any;
  private retryNo: number = RETRIES;
  private authInProgress: boolean = false;
  private validateCardInfo: any = false;
  private refProcessBridgerPayMessages: any;
  private refProcessMessage: any;
  private unmaskedCVV: boolean = false;

  constructor(data: PaymentProviderConstructor) {
    super(data);
    try {
      this.setType(PaymentProviderConstant.bridger);
      // unescape the string
      this.bloxSettings = data?.settings?.blox ? JSON.parse(data.settings.blox) : false;
      if (data?.settings?.cards) {
        this.setCards(data.settings.cards);
      }
      if (data?.settings?.validateCardInfo) {
        this.validateCardInfo = data.settings.validateCardInfo;
      }
      if (data?.settings?.amount) {
        this.setAmount(data.settings.amount);
        this.initBridgerApplePay();
      }
      if (data?.settings?.bonusId) {
        this.setBonusId(data.settings.bonusId);
      }
      if (data?.settings?.rewardId) {
        this.setRewardId(data.settings.rewardId);
      }
      if (data?.settings?.unmaskedCVV) {
        this.unmaskedCVV = data.settings.unmaskedCVV;
      }
      this.refProcessBridgerPayMessages = this.processBridgerPayMessages.bind(this);
      this.refProcessMessage = this.processMessage.bind(this);
    } catch (e) {
      console.warn(e);
    }
  }

  injectScript(): void {
    // setTimeout(() => {
    Utils.injectScript('https://ng-checkout.bridgerpay.com/sdk', 'bridger-sdk', this.bridgerScriptInserted.bind(this), {
      async: true,
    });
    // TODO: why 3500ms?
    // }, 350);
  }

  init(data: any): void {
    super.init(data);

    // add div for 3ds
    let div = document.getElementById(HTML_3DS_IFRAME_ID);
    if (!div) {
      div = document.createElement('div');
      div.setAttribute('id', HTML_3DS_IFRAME_ID);
      const wrapper = document.getElementById(HTML_3DS_IFRAME_WRAPPER);
      if (wrapper) {
        wrapper.appendChild(div);
      } else {
        document.body.appendChild(div);
      }
    }

    this.injectScript();

    this.cashierToken = data.cashierToken ?? false;
    this.apCashierKey = data.apCashierKey ?? false;
    this.apCashierToken = data.apCashierToken ?? false;
    if (this.cashierToken) {
      this.authInProgress = false;
    }
    if (!this.apCashierKey || !this.apCashierToken) {
      this.initBridgerApplePay();
    }
    this.cardsFetched = data.cardsFetched ?? false;

    if (this.cardsFetched) {
    }

    this.generateBloxStyle();
  }

  setCashierToken(cashierToken: string): void {
    this.cashierToken = cashierToken;
    // remove event listeners
    window.removeEventListener('message', this.refProcessMessage);
    // add event listener
    window.addEventListener('message', this.refProcessMessage, false);
  }

  initBridgerApplePay() {
    this.applePay = new BridgerApplePay({
      cashierKey: this.apCashierKey,
      cashierToken: this.apCashierToken,
      triggerSuccess: this.triggerSuccess,
      triggerError: this.triggerError,
      dispatch: this.dispatch,
    });
    this.applePay.setPaymentDetails({
      amount: this.paymentDetails.amount,
      bonusId: this.paymentDetails.bonusId,
      rewardId: this.paymentDetails.rewardId,
    });
    this.applePay.startSession();
    return this.applePay;
  }

  setAmount(amount: number) {
    super.setAmount(amount);
    this.applePay?.setPaymentDetails({
      amount: amount,
      bonusId: this.paymentDetails.bonusId,
      rewardId: this.paymentDetails.rewardId,
    });
  }

  destroy(): void {
    this.dispatch(bridgerPayDepositResponse({ reset: true }));
    this.bloxController?.destroy();
    window.removeEventListener('message', this.refProcessBridgerPayMessages);
    clearTimeout(this.inputTimeout);
  }

  processBridgerPayMessages = (e: any) => {
    if (e.data.event == '[bp]:fingerprint') {
      // check if fingerprint is already sent
      if (window.sessionStorage.getItem(`fp${this.cashierToken}`) === '1') {
        return;
      }
      window.sessionStorage.setItem(`fp${this.cashierToken}`, '1');

      const DDC = e.data.fingerprint;
      this.dispatch(
        finishBridgerPayDeposit({
          amount: this.paymentDetails.amount,
          bonusId: this.paymentDetails.bonusId,
          rewardId: this.paymentDetails.rewardId,
          card: {
            token: this.bridgerCardInfo?.token,
            encryptedCVV: this.bridgerCardInfo?.encryptedCVV,
          },
          fingerprint: DDC,
        }),
      );
    }
  };

  confirmDepositValue() {
    this.auth();
    this.fetchCards();
  }

  auth() {
    if (!this.isAuthenticated() && !this.authInProgress) {
      this.authInProgress = true;
      this.dispatch(initiateBridgerPayDeposit({})); // auth token
    }
  }

  fetchCards() {
    if (!this.cardsFetched) {
      this.dispatch(fetchPlayerPaymentMethods());
    }
  }

  setCards(cards: any) {
    // check if cards is of type Cards
    let foundDefault = false;
    const cardsInitialized = cards.length > 0 && cards[0].cardHolderName === undefined;
    const cardsNotInitialized = cards.length > 0 && cards[0].cardHolderName !== undefined;
    const cardsList: Card[] = cardsInitialized
      ? cards
      : cards.map((card: any): Card | undefined => {
          const c = this.createCard(card);

          if (card.is_default) {
            c.selected = true;
            foundDefault = true;
            this.setPaymentDetails({
              ...this.paymentDetails,
              card: c,
            });
          }

          return c;
        });
    if (!foundDefault && cardsNotInitialized) {
      cardsList[0].selected = true;
      this.setPaymentDetails({
        ...this.paymentDetails,
        card: cardsList[0],
      });
    }
    super.setCards(cardsList);
  }

  setPaymentDetails(paymentDetails: any, setPaymentDetailsCallBack?: () => void): any {
    this.applePay?.setPaymentDetails({
      amount: paymentDetails?.amount ?? 0,
      bonusId: paymentDetails?.bonusId ?? null,
      rewardId: paymentDetails?.rewardId ?? null,
    });

    return super.setPaymentDetails(paymentDetails ?? null, setPaymentDetailsCallBack);
  }

  setBonusId(bonusId: string | number | null): void {
    super.setBonusId(bonusId);
    this.applePay?.setPaymentDetails({
      bonusId: bonusId,
      amount: this.paymentDetails.amount,
      rewardId: this.paymentDetails.rewardId,
    });
  }

  setRewardId(rewardId: number | string | null) {
    super.setRewardId(rewardId);
    this.applePay?.setPaymentDetails({
      rewardId: rewardId,
      amount: this.paymentDetails.amount,
      bonusId: this.paymentDetails.bonusId,
    });
  }

  createCard(card: any): Card {
    const c = super.createCard({
      card_token: card.token,
      card_type_id: card.cardBrand,
      card_number: card.cardNumber,
      expiration_date: card.expirationDate,
      card_full_name: card.cardHolderName,
      bin: card.cardBin,
    });

    let cardNumber = c.number;
    // format cardNumber to be displayed as **** **** **** 1234
    const cardNumberRaw = cardNumber?.replace(/\s/g, '');
    if (cardNumberRaw?.length === 16) {
      // replace first chars with *
      cardNumber = cardNumberRaw?.replace(/.{12}/, '**** **** **** ') ?? '';
    }

    if (c.type.toLowerCase() in typeCards) {
      c.type = c.type.toLowerCase();
    }

    return { ...c, number: cardNumber };
  }

  bridgerScriptInserted = () => {
    // @ts-ignore
    window.addEventListener('message', this.refProcessMessage, false);
  };

  error() {
    this.hide3DS();
    this.triggerError();
    this.destroy3DS();
  }

  success() {
    this.hide3DS();
    this.triggerSuccess();
    this.destroy3DS();
  }

  pending() {
    this.hide3DS();
    this.triggerPending();
    this.destroy3DS();
  }

  show3DS() {
    const wrapper = document.getElementById(HTML_3DS_IFRAME_ID);
    wrapper?.setAttribute('class', 'show');
  }

  hide3DS() {
    const wrapper = document.getElementById(HTML_3DS_IFRAME_ID);
    wrapper?.setAttribute('class', '');
  }

  destroy3DS() {
    const wrapper = document.getElementById(HTML_3DS_IFRAME_ID);
    wrapper?.remove();
  }

  processMessage(event: any) {
    const { data } = event;
    // check if data.event is string
    if (typeof data.event !== 'string') {
      return;
    }
    // check if data.event starts with [bp][checkout:${this.cashierToken}]
    if (!data.event.startsWith('[bp]')) {
      return;
    }
    switch (data.event) {
      case '[bp]:init':
        if (data.type !== 'success') {
          this.error();
        }
        break;
      case '[bp]:create-session':
        if (data.type !== 'success') {
          this.error();
        }
        break;
      case '[bp]:contentRendered':
        break;
      case '[bp]:requestPaymentError':
      case '[bp]:error':
        this.error();
        break;
      case '[bp]:loadingPSP':
      case '[bp]:processingPSP':
        if (data.value) {
          this.showLoader();
        } else {
          // add a timeout to hide the loader
          // bridger have a fade away effect
          setTimeout(() => {
            this.hideLoader();
          }, 200);
        }
        break;
      case '[bp]:deposit':
        switch (data.type) {
          case 'success':
          case 'approved':
            this.success();
            break;
          case 'error':
          case 'declined':
            this.error();
            break;
          case 'pending':
            this.pending();
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  deposit(): void {
    this.showLoader();
    // payment with new card
    if (this.bloxController.isAllValid === false) {
      this.bloxController.validateAll();
      return;
    }
    // add event listener, but make sure it is added only once
    window.removeEventListener('message', this.refProcessBridgerPayMessages);
    window.addEventListener('message', this.refProcessBridgerPayMessages, false);

    if (this.paymentDetails?.cardInfo?.holderName) {
      this.bloxController
        .createPaymentCardToken({
          // @ts-ignore
          cardHolderName: this.paymentDetails.cardInfo?.holderName, // This field is mandatory
          singleUse: false,
        })
        .then((response: any) => {
          this.bridgerCardInfo = {
            token: response.token,
            encryptedCVV: response.encryptedCvv,
          };
          this.dispatch(
            finishBridgerPayDeposit({
              card: {
                bin: response.card.bin,
              },
            }),
          );
        });
    } else {
      // payment with saved card
      this.bloxController.encryptCvv().then((response: any) => {
        const pd = this.getPaymentDetails();
        if (pd.card) {
          this.bridgerCardInfo = {
            token: pd.card.token,
            encryptedCVV: response.encryptedCvv,
          };
          this.dispatch(
            finishBridgerPayDeposit({
              card: {
                bin: pd.card.bin,
              },
            }),
          );
        }
      });
    }
  }

  fingerprint() {}

  getApplePay(): BridgerApplePay | undefined {
    return this.applePay;
  }

  confirmPayment(): void {
    try {
      this.deposit();
    } catch (e) {
      console.warn(e);
    }
  }

  sendConfirmation(info: any) {
    const axios = ClientAPI.getInstance();
    const pac = PlayerAbuseChecker.getInfo();
    const data = {
      chargeToken: info.data.chargeToken,
      amount: info.amount,
      ...pac,
    };

    if (info.bonusId) {
        // @ts-ignore
        data.bonusId = info.bonusId;
    }
    if (info.rewardId) {
        // @ts-ignore
        data.rewardId = info.rewardId;
    }
    if (PlayOnline.getGclid()) {
        // @ts-ignore
        data.gclid = PlayOnline.getGclid();
    }

    axios({
      url: '/api/pay-checkout/charge-token',
      method: 'post',
      data: data,
    })
      .then((response) => {
        // @ts-ignore
        if (response?.result?.ResponseCode) {
          this.triggerError();
        } else {
          // @ts-ignore
          if (response?.status === 'OK') {
            setTimeout(this.triggerSuccess, 500);
          } else {
            this.triggerError();
          }
        }
      })
      .catch(() => {
        this.triggerError();
      });
  }

  getBloxSettings() {
    return {
      token: this.cashierToken,
      ...this.bloxSettings,
    };
  }

  generateBloxStyle() {
    const style = window.getComputedStyle(document.body);

    // TODO: check if we can get font sources from the backend

    this.bloxSettings = {
      fontsSources: this.bloxSettings.fontsSources ?? [],
      style: {
        base: {
          color: style.getPropertyValue('--deposit-input-on-fill-head'),
          caretColor: style.getPropertyValue('--deposit-input-on-fill-head'),
          textOverflow: 'ellipsis',
          font: style.getPropertyValue('--deposit-input-head-typo'),
          height: style.getPropertyValue('--deposit-input-height'),
          minHeight: style.getPropertyValue('--deposit-input-height'),
          maxHeight: style.getPropertyValue('--deposit-input-height'),
          gap: style.getPropertyValue('--deposit-input-y-gap'),
          borderRadius: style.getPropertyValue('--deposit-input-radius'),
          borderTop: `${style.getPropertyValue('--deposit-input-border-top')} solid ${style.getPropertyValue('--deposit-input-border-default')}`,
          borderRight: `${style.getPropertyValue('--deposit-input-border-right')} solid ${style.getPropertyValue('--deposit-input-border-default')}`,
          borderBottom: `${style.getPropertyValue('--deposit-input-border-bottom')} solid ${style.getPropertyValue('--deposit-input-border-default')}`,
          borderLeft: `${style.getPropertyValue('--deposit-input-border-left')} solid ${style.getPropertyValue('--deposit-input-border-default')}`,
          padding: `0px ${style.getPropertyValue('--form-input-space-right')} 0px ${style.getPropertyValue('--form-input-space-left')}`,
          // background: style.getPropertyValue('--deposit-input-fill-default'),
          boxShadow: style.getPropertyValue('--deposit-input-shadow-default'),
          display: 'flex',
          width: '100%',
          margin: 0,
          alignItems: 'center',
          lineHeight: '120% !important',
          ':focus': {
            outline: 'none',
            borderTop: `${style.getPropertyValue('--form-input-border-top')} solid ${style.getPropertyValue('--form-border-focus')}`,
            borderRight: `${style.getPropertyValue('--form-input-border-right')} solid ${style.getPropertyValue('--form-border-focus')}`,
            borderBottom: `${style.getPropertyValue('--form-input-border-bottom')} solid ${style.getPropertyValue('--form-border-focus')}`,
            borderLeft: `${style.getPropertyValue('--form-input-border-left')} solid ${style.getPropertyValue('--form-border-focus')}`,
            padding: `16px ${style.getPropertyValue('--form-input-space-right')} 0px ${style.getPropertyValue('--form-input-space-left')}`,
            transition: 'padding .2s',
          },
          ':-webkit-autofill': {
            '-webkit-text-fill-color': '#bbb7cb',
            '-webkit-box-shadow': '0 0 0 1000px #2E2F3B inset'
          },
          ':-internal-autofill-selected': {
            'backgroundColor': 'initial !important',
            'color': '#bbb7cb !important',
            'background': '#e7f0fe !important'
          }
        },
        'invalid': {
          borderRadius: style.getPropertyValue('--deposit-input-radius'),
          borderTop: `${style.getPropertyValue('--deposit-input-border-top')} solid ${style.getPropertyValue('--deposit-input-border-error')}`,
          borderRight: `${style.getPropertyValue('--deposit-input-border-right')} solid ${style.getPropertyValue('--deposit-input-border-error')}`,
          borderBottom: `${style.getPropertyValue('--deposit-input-border-bottom')} solid ${style.getPropertyValue('--deposit-input-border-error')}`,
          borderLeft: `${style.getPropertyValue('--deposit-input-border-left')} solid ${style.getPropertyValue('--deposit-input-border-error')}`,
          background: style.getPropertyValue('--deposit-input-fill-error'),
        },

      }
    };
  }

  initInputsForNewCard() {
    this.showInputsLoader();
    const elementsExists =
      document.getElementById('bridger-card-number') &&
      document.getElementById('bridger-card-exp') &&
      document.getElementById('bridger-card-cvv');

    // @ts-ignore
    if (typeof window.BP === 'undefined' || !this.cashierToken || !elementsExists) {
      if (!this.cashierToken) {
        this.auth();
      }
      if (this.retryNo > 0) {
        clearTimeout(this.inputTimeout);
        this.inputTimeout = setTimeout(
          () => {
            this.initInputsForNewCard();
          },
          300,
          this,
        );
        this.retryNo--;
      } else {
        this.retryNo = RETRIES;
      }
      return;
    }
    if (this.cashierToken) {
      super.initInputsForNewCard();

      this.bloxController?.destroy();

      // @ts-ignore
      this.bloxController = new window.BP.BloxController(this.getBloxSettings());

      const cardNumber = this.bloxController.createBlox('cardNumber', {
        placeholder: '0000 0000 0000 0000',
      });
      cardNumber.on('change', (e: any) => (this.validateCardInfo ? this.validateCardInfo('number', e) : null));
      cardNumber.on('validate', (e: any) => (this.validateCardInfo ? this.validateCardInfo('number', e) : null));
      // cardNumber.mount('#bridger-card-number .eko-bridger-input');

      const cardExpiryDate = this.bloxController.createBlox('cardExpiryDate', {
        placeholder: 'MM / YY',
      });
      cardExpiryDate.on('change', (e: any) => (this.validateCardInfo ? this.validateCardInfo('expiry', e) : null));
      cardExpiryDate.on('validate', (e: any) => (this.validateCardInfo ? this.validateCardInfo('expiry', e) : null));
      // cardExpiryDate.mount('#bridger-card-exp .eko-bridger-input');

      const cardCvv = this.bloxController.createBlox('cardCvv', {
        placeholder: 'CVV',
        unmaskedCVV: this.unmaskedCVV,
      });
      cardCvv.on('change', (e: any) => (this.validateCardInfo ? this.validateCardInfo('cvv', e) : null));
      cardCvv.on('validate', (e: any) => (this.validateCardInfo ? this.validateCardInfo('cvv', e) : null));
      // cardCvv.mount('#bridger-card-cvv .eko-bridger-input');
      Promise.all([
        cardNumber.mount('#bridger-card-number .eko-bridger-input'),
        cardExpiryDate.mount('#bridger-card-exp .eko-bridger-input'),
        cardCvv.mount('#bridger-card-cvv .eko-bridger-input'),
      ]).then(
        () => {
          this.hideInputsLoader();
        },
        () => {
          this.hideInputsLoader();
        },
      );
    }
  }

  initInputsForSavedCards() {
    this.showInputsLoader();
    const elementsExists = document.getElementById('bridger-card-cvv');

    // @ts-ignore
    if (typeof window.BP === 'undefined' || !this.cashierToken || !elementsExists) {
      if (!this.cashierToken) {
        this.auth();
      }
      if (this.retryNo > 0) {
        clearTimeout(this.inputTimeout);
        this.inputTimeout = setTimeout(
          () => {
            this.initInputsForSavedCards();
          },
          300,
          this,
        );
        this.retryNo--;
      } else {
        this.retryNo = RETRIES;
      }
      return;
    }
    if (this.cashierToken) {
      super.initInputsForSavedCards();
      this.bloxController?.destroy();

      // @ts-ignore
      this.bloxController = new window.BP.BloxController(this.getBloxSettings());

      const cardCvv = this.bloxController.createBlox('cardCvv', {
        placeholder: 'CVV',
        unmaskedCVV: this.unmaskedCVV,
      });
      cardCvv.on('change', (e: any) => (this.validateCardInfo ? this.validateCardInfo('cvv', e) : null));
      cardCvv.on('validate', (e: any) => (this.validateCardInfo ? this.validateCardInfo('cvv', e) : null));
      cardCvv.mount('#bridger-card-cvv .eko-bridger-input').then(
        () => {
          this.hideInputsLoader();
        },
        () => {
          this.hideInputsLoader();
        },
      );
    }
  }

  quickDeposit(data: any) {
    this.setPaymentDetails(data);
    if (!data.loading) {
      this.auth();
    }

    // retry mechanism
    if (this.isAuthenticated()) {
      this.confirmPayment();
    } else {
      setTimeout(() => {
        this.quickDeposit({ ...data, loading: true });
      }, 300);
    }
  }

  isAuthenticated() {
    return !!this.cashierToken;
  }

  initApplePay(data: any) {
    // this.applePay?.destroy();
    this.applePay?.injectAppleScript(data);
  }

  redirect(url: string): void {
    this.showLoader();
    // check if iframe exists and remove it
    let iframe = document.getElementById('bridger-iframe');
    if (iframe) {
      iframe.remove();
    }
    // create iframe and inject it in HTML_3DS_IFRAME_ID div
    iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('id', 'bridger-iframe');
    const wrapper = document.getElementById(HTML_3DS_IFRAME_ID);
    if (wrapper) {
      wrapper.appendChild(iframe);
      wrapper.setAttribute('class', 'show');
    } else {
      this.triggerError();
    }
  }
}

export default Bridger;
