import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { getMarketingOffer } from '@/modules/casino/store/actions';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';
import Utils from '@/modules/casino/utils/Utils';

export function useMarketingOffer() {
  const dispatch = useAppDispatch();
  const { marketingOffer } = useAppSelector((state) => state.marketingOffer);

  const ui_elements = React.useMemo(() => {
    const data = JSON.parse(marketingOffer?.ui_elements ?? '{}');
    data.bonusId = marketingOffer?.bonus_preset_id ?? false;
    return data;
  }, [marketingOffer]);

  React.useEffect(() => {
    const moid = PlayOnline.getSavedField('moid');
    if (!marketingOffer || marketingOffer.moid !== moid) {
      dispatch(getMarketingOffer(moid));
    }
  }, []);

  const getRegistrationBonusContext = React.useMemo(() => {
    return {
      value: marketingOffer?.register_value_units_numeric ?? 0,
      currency: marketingOffer?.register_value_units_text
        ? Utils.formatCurrency(marketingOffer?.register_value_units_text)
        : '',
      image: marketingOffer?.register_image_wl ?? '',
      bonusUI: ui_elements,
      title: marketingOffer?.register_value_sub_title_text ?? '',
    };
  }, [marketingOffer]);

  return useMemo(
    () => ({
      ui_elements,
      rawInfo: marketingOffer,
      getRegistrationBonusContext,
    }),
    [ui_elements, marketingOffer, getRegistrationBonusContext],
  );
}

export default useMarketingOffer;
