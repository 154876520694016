import { rewardBundle } from '../actions/actionTypes';

export function fetchRewardBundles(): any {
  return {
    type: rewardBundle.FETCH,
  };
};

export function setRewardBundles(data: any): any {
    return {
        type: rewardBundle.SET,
        data,
    };
}