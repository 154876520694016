import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { closeIGRPopup, IdleGameRecommendationReducer, openIGRPopupAsync } from '@/store/slices/idleGameRecommendation';
//import './index.scss';

type IdleGameRecommendationProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ALLOWED_PAGES = [
  '/slots',
  '/casino',
  '/live-casino',
  '/new-games',
  '/casino-games',
  '/jackpots',
  '/eagle-exclusive-games',
  'slot-game',
];

const IGR_TIMER = parseInt(window?.config?.idleGameRecomendationSeconds) * 1000;
const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const useIdle = (timeout: number = IGR_TIMER) => {
  const [isIdle, setIsIdle] = React.useState(false);
  let timeoutId: NodeJS.Timeout;

  const debounce = (func: () => void, delay: number) => {
    return () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(func, delay);
    };
  };

  React.useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchmove'];
    const handleEvent = debounce(() => {
      setIsIdle(false);
      timeoutId = setTimeout(() => {
        setIsIdle(true);
      }, timeout);
    }, 1000);

    events.forEach((event) => {
      document.addEventListener(event, handleEvent);
    });

    return () => {
      clearTimeout(timeoutId);
      events.forEach((event) => {
        document.removeEventListener(event, handleEvent);
      });
    };
  }, [timeout]);

  return isIdle;
};

const IdleGameRecommendation = (componentProps: IdleGameRecommendationProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const idleGameRecommendation = useAppSelector(
    (state) => state.idleGameRecommendation,
  ) as IdleGameRecommendationReducer;

  const [isDisabled, setIsDisabled] = React.useState(false);

  const isIdle = useIdle(IGR_TIMER);

  React.useEffect(() => {
    if (!isDisabled && isIdle && !idleGameRecommendation.isOpen && !location?.pathname?.includes('slot-game')) {
      dispatch(openIGRPopupAsync());
    }
  }, [isIdle]);

  React.useEffect(() => {
    dispatch(closeIGRPopup());

    if (
      !(
        window?.config?.disableIdleGameRecommendation === '0' &&
        (ALLOWED_PAGES.some((value) => location?.pathname.includes(value)) || location?.pathname === '/')
      )
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [location?.pathname]);

  const closePopupHandler = () => {
    dispatch(closeIGRPopup());
    if (location?.pathname?.includes('slot-game')) {
      navigate('/');
    }
  };

  const contextValue = React.useMemo(() => {
    return {
      IGRPopupActive: idleGameRecommendation.isOpen && !isDisabled,
      recommendedGame: idleGameRecommendation?.recommendedGame,
      isLoading: idleGameRecommendation?.isLoading,
      closePopupHandler: closePopupHandler,
    };
  }, [idleGameRecommendation.isOpen, closePopupHandler]);

  if (isDisabled) return null;
  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default IdleGameRecommendation;
