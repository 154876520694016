import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { useBlocker, useLocation } from 'react-router-dom';
import { useAppSelector } from '@/store';

type ExitIntentHandlerProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ExitIntentHandler = (componentProps: ExitIntentHandlerProps) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const templatePopupID = props.properties.popupID;
  const isBlockRouteReloadEnabled = props.properties.isBlockRouteReloadEnabled;

  const [canNavigate, setCanNavigate] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const isSignedUp = useAppSelector((state: any) => state.register.signedUp);

  const blockedRoutes = window.config?.blockedRoutes;
  const blockedRoute = blockedRoutes?.find((route) => route.path === location.pathname);

  const blocker = useBlocker(() => {
    if (blockedRoute?.popupID === templatePopupID) {
      if (!canNavigate) {
        setShowPopup(true);

        return true;
      }
    }
    return false; 
  });

  useEffect(() => {
    // TODO: this should be changed to accept some trigger from whatever is responsible for the signup process
    setCanNavigate(isSignedUp);
  }, [isSignedUp]);

  useEffect(() => {
    // Handle the beforeunload event for reload
    const handleBeforeUnload = (e: { preventDefault: () => void; }) => {
      if (blockedRoute?.popupID === templatePopupID) {
          e.preventDefault();
          setShowPopup(true);
        }

        return null;
    };
    
    if (isBlockRouteReloadEnabled) {
      window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
    };
  }, []);

  const closePopupHandler = () => {
    setShowPopup(false);
  };

  const continuePopupHandler = () => {
    setShowPopup(false);
    if (blocker.proceed) {
      blocker?.proceed();
    } else {
      window.location.reload();
    }
    
  };

  const contextValue = useMemo(() => {
    return {
      showPopup,
      closePopupHandler,
      continuePopupHandler,
    };
  }, [dataElementContext, componentProps, showPopup, closePopupHandler, continuePopupHandler]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ExitIntentHandler;

