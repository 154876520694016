import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '../../../page-components/utils/processComponentProps';
import { fetchWheelsDataSource } from '@/store/slices/wheelsDataSource';

type ChestProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Chest = (componentProps: ChestProps) => {
  let props = componentProps;

  const apiUrl = window.config.betsApiUrl + '/wheel/player';

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const wheelSetups = useAppSelector((state) => state.wheelsDataSource.wheels);
  const authentication = useAppSelector((state) => state.authentication);
  const dispatch = useAppDispatch();

  const [selectedChest, setSelectedChest] = React.useState<number | null>(null);
  const [state, setState] = React.useState<string>('closed'); // closed, opening, opened
  const [status, setStatus] = React.useState<any>(null);
  const [spinData, setSpinData] = React.useState<any>(null);

  const defaultChests = [...Array(9).keys()].map((i) => ({
    id: i,
  }));
  const [chests, setChests] = React.useState<any[]>(defaultChests);

  const loadStatus = React.useCallback(() => {
    axios
      .get(apiUrl + '/status?scope=MYSTERY_CHEST', {
        headers: {
          Authorization: 'Bearer ' + authentication.access_token,
        },
      })
      .then((response) => {
        if (response && response.data) {
          const rsp = response.data;
          let state = 'closed';

          if (rsp.status === 'not_available') {
            if (rsp.free_spin?.status === 'not_available' && rsp.free_spin?.next_spin_after != null) {
              // There is a cooldown
              state = 'waiting';
            } else if (rsp.free_spin?.status === 'not_available' && rsp.free_spin?.next_spin_after == null) {
              state = 'no-token';
            }
          }

          setStatus(rsp);
          setState(state);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    dispatch(fetchWheelsDataSource({ id: 'player_wheel_setups' }));
    loadStatus();
  }, []);

  const onNextSpin = React.useCallback(() => {
    loadStatus();
  }, []);

  const onChestClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      if (state !== 'closed') {
        return;
      }

      if (e.currentTarget.dataset.id) {
        const id = parseInt(e.currentTarget.dataset.id);
        console.log('Chest[onChestClick]', id);

        setSelectedChest(id);
        setState('opening');

        const headers = {
          Authorization: 'Bearer ' + authentication.access_token,
        };
        const postData = {
          type: 'free',
          //wheel_setup_id: status.wheel_setup,
          scope: 'MYSTERY_CHEST',
        };

        /*
        setTimeout(() => {
        setSpinData({
          results: { product_id: '994434951193886721' },
          });
          setState('opened');
        }, 300);          
        */

        const now = new Date().getTime();

        axios
          .post(apiUrl + '/spin', postData, { headers })
          .then((response) => {
            if (response && response.data) {
              const data = response.data;

              const delay = Math.max(0, 300 - (new Date().getTime() - now));

              setTimeout(() => {
                setSpinData(data);
                setState('opening2');
              }, delay);

              setTimeout(() => {
                setState('opened');
              }, delay + 2000);
            }
          })
          .catch((error) => {
            console.log('Chest[onChestClick]', error);
          });
      }
    },
    [authentication, state],
  );

  const shuffle = React.useCallback((array: any[]) => {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  }, []);

  React.useEffect(() => {
    const chests = [...Array(9).keys()].map((i) => ({
      id: i,
    }));

    if (spinData?.results?.product_id) {
      const products: any[] = [];
      let wonProduct: any = null;
      const cfg =
        wheelSetups && status && status.wheel_setup && typeof wheelSetups[status.wheel_setup] !== 'undefined'
          ? JSON.parse(JSON.stringify(wheelSetups[status.wheel_setup]))
          : null;

      if (cfg) {
        for (let i = 0; i < cfg.products?.slots?.length; i++) {
          const product = cfg.products?.slots?.[i];
          if (product && product.id === spinData.results.product_id) {
            wonProduct = product;
          } else {
            products.push(product);
          }
        }
      }

      shuffle(products);

      chests.forEach((chest, i) => {
        if (i === selectedChest) {
          const newP = {
            ...wonProduct,
            won: true,
            id: chest.id,
          };
          chests[i] = newP;
        } else {
          const newP = {
            ...products.pop(),
            won: false,
            id: chest.id,
          };
          chests[i] = newP;
        }
      });
    }

    setChests(chests);
  }, [spinData]);

  const contextValue = React.useMemo(() => {
    return {
      chests,
      selectedChest,
      onChestClick,
      state,
      status,
      canSelect: status?.status === 'available',
      spinData,
      wheelSetups,
      onNextSpin,
    };
  }, [
    dataElementContext,
    componentProps,
    selectedChest,
    onChestClick,
    state,
    status,
    spinData,
    wheelSetups,
    onNextSpin,
    chests,
  ]);

  console.log('Chest[contextValue]', contextValue, state);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Chest;
