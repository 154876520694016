import React from 'react';

import { requestDocuments, requestDocumentsMaxFileSize } from '@/modules/casino/store/actions';
import { useAppDispatch, useAppSelector } from '@/store';

import { GENERIC_FILE_MAX_SIZE } from './constants';

export function useDocumentsState() {
  const dispatch = useAppDispatch();
  const documents = useAppSelector((state) => state.documents);

  React.useEffect(() => {
    if (!documents.documentsMaxFileSize || documents.documentsMaxFileSize === 0) {
      dispatch(requestDocumentsMaxFileSize());
    }

    if (!documents.loadingDocuments && !documents.loadingDocumentsUpload) {
      dispatch(requestDocuments());
    }
  }, [dispatch, documents.documentsMaxFileSize, documents.loadingDocumentsUpload]);

  return React.useMemo(
    () => ({
      documentsMaxSize: documents.documentsMaxFileSize ? String(documents.documentsMaxFileSize) : GENERIC_FILE_MAX_SIZE,
      documentsLoading: documents.loadingDocumentsUpload,
    }),
    [documents.documentsMaxFileSize, documents.loadingDocumentsUpload],
  );
}
