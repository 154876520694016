import React, { useMemo } from 'react';
import {
  RegisterConfig,
  RegisterModuleStateProps,
  RegisterStep,
} from '@/components/modules/register/register-dynamic/types';
import { DEBUG_REGISTER_STEPS } from '@/components/modules/register/register-dynamic/const';

export function useRegisterConfig(
  state: RegisterModuleStateProps,
  setState: React.Dispatch<React.SetStateAction<RegisterModuleStateProps>>,
  propsConfig: any,
) {
  const steps: RegisterStep[] = React.useMemo(() => {
    const configError: RegisterConfig = {
      steps: [
        {
          stepperIndex: 1,
          components: [
            {
              layout: 'text',
              label: 'REGISTER_CONFIG_ERROR',
            },
          ],
        },
      ],
    };
    try {
      if (DEBUG_REGISTER_STEPS) {
        // @ts-ignore
        const config = DEBUG_REGISTER_STEPS ? DEBUG_REGISTER_STEPS : configError;
        console.warn('DEBUG_REGISTER_STEPS', config);
        return config.steps;
      } else {
        const config = propsConfig ? JSON.parse(propsConfig) : configError;
        return config.steps;
      }
    } catch (e) {
      return { ...configError.steps };
    }
  }, [propsConfig]);
  const stepsMap = React.useMemo(() => {
    let finalStep = steps.length - 1; // when the signup will be triggered; by default it's the last step
    let ocrStep = -1; // we use this to do some optimizations; -1 means it's not present
    let otpStep = -1; // we use this to do some optimizations; -1 means it's not present
    let marketingOfferStep = -1; // we use this to do some optimizations; -1 means it's not present
    let congratsModalStep = -1; // we use this to do some optimizations; -1 means it's not present
    let congratsElementStep = -1; // we use this to do some optimizations; -1 means it's not present
    // we use this to do some optimizations; make it the last step by default
    // if no t&c step is found, it will remain the last step. this should be combined cu tcAcceptedByDefault
    let tcStep = finalStep;

    steps?.forEach((step, index) => {
      step.components.forEach((component) => {
        if (component.layout === 'ocr-scanner' && ocrStep === -1) {
          ocrStep = index;
        }
        if (component.layout === 'input' && component.type === 'otp' && otpStep === -1) {
          otpStep = index;
        }
        if (component.layout === 'registration-bonus-card' && marketingOfferStep === -1) {
          marketingOfferStep = index;
        }
        if (component.layout === 'congrats-modal' && congratsModalStep === -1) {
          congratsModalStep = index;
        }
        if (component.layout === 'congrats-element' && congratsElementStep === -1) {
          congratsElementStep = index;
        }
        if (component.layout === 'checkbox' && component.type === 'tc' && tcStep === finalStep) {
          tcStep = index;
        }
      });
    });

    // if last step is congrats element, final step should be the previous one, congratsElementStep is the last one
    if (congratsElementStep === finalStep) {
      finalStep--;
    }

    return {
      final: finalStep,
      ocr: ocrStep,
      otp: otpStep,
      marketingOfferStep: marketingOfferStep,
      congratsModalStep: congratsModalStep,
      congratsElementStep: congratsElementStep,
      tc: tcStep,
    };
  }, [steps]);

  const getStepperIndex = (currentStep: number) => {
    return steps[currentStep]?.stepperIndex ?? 0;
  };

  const getPreviousStep = (currentStep: number): number => {
    let found = false;
    let prevStep = currentStep - 1;
    const currentStepperIndex = steps[currentStep].stepperIndex;
    const prevStepperIndex = currentStepperIndex - 1;
    while (!found) {
      if (prevStep < 0) {
        prevStep = 0;
        found = true;
        break;
      }
      if (steps[prevStep].stepperIndex === prevStepperIndex) {
        if (steps[prevStep].stepperSubIndex === 0 || steps[prevStep].stepperSubIndex === undefined) {
          found = true;
        }
      }
      if (!found) {
        prevStep--;
      }
      if (prevStep < 0) {
        prevStep = 0;
        found = true;
      }
    }
    return prevStep;
  };

  return useMemo(
    () => ({
      steps,
      stepsMap,
      getStepperIndex,
      getPreviousStep,
    }),
    [steps, stepsMap],
  );
}