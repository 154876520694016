import React from 'react';

import styled from 'styled-components';
import * as EmailValidator from 'email-validator';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { getMarketingOffer } from '../../../../modules/casino/store/actions/marketing_offers';

import PhoneNumberValidator from '../../../../modules/casino/utils/PhoneNumberValidator';
import {
  register,
  sendRegisterSMS,
  setPartialAccount,
  setRegisterSignUpLoading,
  validateField,
} from '../../../../modules/casino/store/actions/register';
import ResponseErrorMessages from '../../../../modules/casino/store/errors/ResponseErrorMessages';
import PasswordValidator, { PasswordValidatorPlaceholders } from '@/utils/PasswordValidator';

import { loginMethod, loginPassword, loginStart, loginUsername } from '@/modules/casino/store/actions/login';
import { IS_EMAIL } from '@/modules/casino/utils/LoginMethods';
import NINValidator from '@/modules/casino/utils/NINValidator';
import ExpiringLocalStorage from '../../../../modules/casino/utils/ExpiringLocalStorage';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';
import MarketingEvents from '@/utils/marketing/MarketingEvents';
import { requestDocumentsMaxFileSize } from '@/modules/casino/store/actions';
import { scanDocument } from '@/modules/casino/store/actions/druid';
import { useTranslation } from 'react-i18next';
import Utils from '@/modules/casino/utils/Utils';
import { getText } from '@/modules/lobby/utils/functions';
import i18n from '@/utils/i18n';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker.js';
import { logRegistrationEvent, trackCustomMetaEvent } from '@/analytics';

type RegisterProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  showCheckboxes: boolean;
  currentStep: string;
  savedStep: string;
  tcAccepted: boolean;
  marketingAccepted: {
    email: boolean;
    sms: boolean;
    phone: boolean;
    partner: boolean;
  };
  errors: {
    email: string | undefined | boolean;
    phone: string | undefined | boolean;
    otp: string | undefined | boolean;
    password: string | undefined | boolean;
    nickname: string | undefined | boolean;
    firstName: string | undefined | boolean;
    lastName: string | undefined | boolean;
    nin: string | undefined | boolean;
    address: string | undefined | boolean;
    OCR: string | undefined | boolean;
    global: string | undefined | boolean;
  };
  fields: {
    email: string;
    phone: string;
    password: string;
    passwordConfirm: string;
    otp: string;
    nickname: string;
    firstName: string;
    lastName: string;
    nin: string;
    address: string;
    ocr_scan: string | null;
  };
  focusedFields: {
    phone: boolean;
    email: boolean;
    password: boolean;
    otp: boolean;
    nickname: boolean;
    firstName: boolean;
    lastName: boolean;
    nin: boolean;
    address: boolean;
  };
  bonusValueUnlocked: number;
  bonusValueToUnlock: number;
  resendTimer: number;
  passwordInputType: string;
  passwordStrengthScore: number;
  passwordStrengthShortLabel: string;
  passwordStrengthLongLabel: string;
  passwordInputIcon: string;
  showConfirmPassword: boolean;
  passwordMessagePlaceholders: PasswordValidatorPlaceholders;

  dialogOpen: boolean;
  loading: boolean;

  file: any;
  uploadedOCRFile: any;
  OCRModalOpen: boolean;
  OCRModalScreen: string;
  documentUploadError: boolean | string;
  trigger: string;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const REGISTER_CURACAO_ONE_PAGE = '1';
const REGISTER_CURACAO_TWO_PAGE = '2';
const REGISTER_RO_THREE_PAGE = '3'; // step1 -> phone_email, step2 -> first_name_last_name_nin_address, step3 -> password, final (if no modal)
const REGISTER_RO_FIVE_PAGE = '4'; // wip pe eagle-register branch
const REGISTER_RO_FOUR_PAGE = '5'; // wip pe hotspins-register branch
const REGISTER_AFRICA_ONE_PAGE = '6'; // BacelaBet, Malawi

const REGISTER_TYPE: string = window.config.registerSettings.registerType;

/**
 * @deprecated
 * @param componentProps
 * @constructor
 */
const RegisterLegacy = (componentProps: RegisterProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const NICKNAME_SERVER_VALIDATION = false;

  // const DEFAULT_TRIGGER_TIME = 3000;
  const DEFAULT_TRIGGER_TIME = 500;
  const DEFAULT_INCREMENT_TIME = 1000;

  const stepBackMappping = {
    [REGISTER_RO_THREE_PAGE]: {
      step2: 'step1',
      step3: 'step2',
      OTP: 'step1',
      final: 'step3',
    },
    [REGISTER_CURACAO_ONE_PAGE]: {
      OTP: 'step1',
      final: 'step1',
    },
    [REGISTER_CURACAO_TWO_PAGE]: {
      OTP: 'step1',
      step2: 'step1',
      final: 'step2',
    },
    [REGISTER_AFRICA_ONE_PAGE]: {
      OTP: 'step1',
      'step-try-login': 'OTP',
      step2: 'step-try-login',
    },
  };

  const TYPE_EMAIL = 'email';
  const TYPE_PHONE = 'phone';
  const TYPE_OTP = 'otp';
  const TYPE_PASSWORD = 'password';
  const TYPE_NICKNAME = 'nickname';
  const TYPE_FIRST_NAME = 'firstName';
  const TYPE_LAST_NAME = 'lastName';
  const TYPE_NIN = 'nin';
  const TYPE_ADDRESS = 'address';
  const RESTRICT_SPECIAL_CHARACTERS = window.config.password.restrictSpecialCharacters === '1' ? true : false;

  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const { marketingOffer } = useAppSelector((state) => state.marketingOffer);
  const { errors, signedUp, signedUpErrors, partialAccount } = useAppSelector((state) => state.register);
  const { client_player_id } = useAppSelector((state) => state.profile);
  const { documentsMaxFileSize } = useAppSelector<any>((state) => state.documents);
  const { currentCountry, validationPhoneError, phoneValidationMessage, loading } = useAppSelector<any>(
    (state) => state.allCountries,
  );
  const druid = useAppSelector<any>((state) => state.druid);

  const loadRegisterFieldsFromStorage = () => {
    const loadedFields = ExpiringLocalStorage.get('registerFields');

    if (loadedFields) {
      return JSON.parse(loadedFields);
    }
    return {};
  };

  const initialState = {
    currentStep: 'step1',
    savedStep: 'step1',
    loading: false,
    errors: {
      email: errors.email ?? false,
      phone: errors.phone ?? false,
      otp: errors.otp ?? false,
      password: errors.password ?? false,
      global: errors.global ?? false,
      nickname: false,
      firstName: false,
      lastName: false,
      nin: false,
      address: false,
      OCR: false,
    },
    fields: {
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      otp: '',
      nickname: '',
      firstName: '',
      lastName: '',
      nin: '',
      address: '',
      ocr_scan: null,
    },
    focusedFields: {
      phone: false,
      email: false,
      password: false,
      otp: false,
      nickname: false,
      firstName: false,
      lastName: false,
      nin: false,
      address: false,
    },
    tcAccepted: false,
    // tcAccepted: true,
    marketingAccepted: {
      email: loadRegisterFieldsFromStorage().marketingAccepted?.email ?? false,
      sms: loadRegisterFieldsFromStorage().marketingAccepted?.sms ?? false,
      phone: loadRegisterFieldsFromStorage().marketingAccepted?.phone ?? false,
      partner: loadRegisterFieldsFromStorage().marketingAccepted?.partner ?? false,
    },
    bonusValueUnlocked: 0,
    bonusValueToUnlock: 0,
    resendTimer: 0,
    showCheckboxes: false,

    passwordInputType: 'password',
    passwordStrengthScore: 0,
    passwordStrengthShortLabel: 'Poor',
    passwordStrengthLongLabel: 'Password strength is poor',
    passwordInputIcon: 'bi-eye-fill',
    showConfirmPassword: false,
    passwordMessagePlaceholders: {
      ERROR_MESSAGE: '',
    },

    dialogOpen: false,
    file: null,
    uploadedOCRFile: null,
    OCRModalOpen: false,
    OCRModalScreen: '',
    documentUploadError: false,
    trigger: 'initial-State',
  };

  const BONUS_FIELDS: string[] = [];
  if (props.properties.emailBonus) {
    BONUS_FIELDS.push('email');
  }
  if (props.properties.phoneBonus) {
    BONUS_FIELDS.push('phone');
  }
  if (props.properties.nicknameBonus) {
    BONUS_FIELDS.push('nickname');
  }
  if (props.properties.firstNameBonus) {
    BONUS_FIELDS.push('firstName');
  }
  if (props.properties.lastNameBonus) {
    BONUS_FIELDS.push('lastName');
  }
  if (props.properties.ninBonus) {
    BONUS_FIELDS.push('nin');
  }
  if (props.properties.addressBonus) {
    BONUS_FIELDS.push('address');
  }
  const [state, setState] = React.useState<ModuleStateProps>(initialState);
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);
  const [iTimer, setITimer] = React.useState<NodeJS.Timeout | null>(null);
  const [actionsTaken, setActionsTaken] = React.useState<string[]>([]);

  const calculateBonusValueUnlocked = () => {
    if (!marketingOffer) {
      return 0;
    }
    const ui = JSON.parse(marketingOffer.ui_elements);

    let startValue = 0;
    if (ui?.register_starting_value) {
      startValue = parseInt(ui.register_starting_value, 10) ?? 0;
    }

    let fieldsValid = 0;
    BONUS_FIELDS.forEach((field) => {
      // @ts-ignore
      if (state.fields[field]) {
        // @ts-ignore
        fieldsValid += typeof state.errors[field] !== 'undefined' && state.errors[field] === false ? 1 : 0;
      }
    });
    const bonusValueToUnlock =
      startValue +
      Math.round(((marketingOffer.register_value_units_numeric - startValue) / BONUS_FIELDS.length) * fieldsValid);
    setState((prevState) => ({
      ...prevState,
      bonusValueToUnlock: isNaN(bonusValueToUnlock) ? 0 : bonusValueToUnlock,
      trigger: 'calculateBonusValueUnlocked',
    }));
  };

  const saveRegisterFieldsInStorage = () => {
    const fieldValues = {
      email: state.fields.email,
      phone: state.fields.phone,

      // nickname: state.fields.nickname,
      firstName: state.fields.firstName,
      lastName: state.fields.lastName,
      nin: state.fields.nin,
      address: state.fields.address,
      marketingAccepted: {
        email: state.marketingAccepted.email,
        sms: state.marketingAccepted.sms,
        phone: state.marketingAccepted.phone,
        partner: state.marketingAccepted.partner,
      },
    };

    const storageValues = loadRegisterFieldsFromStorage();

    if (storageValues) {
      if (JSON.stringify(fieldValues) !== JSON.stringify(storageValues)) {
        ExpiringLocalStorage.set('registerFields', JSON.stringify(fieldValues));
      }
    }
  };

  React.useEffect(() => {
    saveRegisterFieldsInStorage();
  }, [
    state.fields.phone,
    state.fields.email,
    state.fields.firstName,
    state.fields.lastName,
    state.fields.nin,
    state.fields.address,
    state.marketingAccepted,
  ]);

  React.useEffect(() => {
    if (druid.inProgress === false && druid.data.ResultId) {
      const globalConfidence = 0.6;

      let readingOK = druid.data.Status === 'Complete';
      if (readingOK) {
        // check confidence for each field
        readingOK =
          druid.data.Confidence >= globalConfidence &&
          druid.data.Data['Face.Confidence'] >= globalConfidence &&
          druid.data.Data['Nume.Confidence'] >= globalConfidence &&
          druid.data.Data['Prenume.Confidence'] >= globalConfidence &&
          druid.data.Data['CNP.Confidence'] >= globalConfidence &&
          druid.data.Data['Domiciliu.Confidence'] >= globalConfidence;

        if (readingOK) {
          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              firstName: druid.data.Data.Prenume,
              lastName: druid.data.Data.Nume,
              nin: druid.data.Data.CNP,
              address: druid.data.Data.Domiciliu,
            },
            trigger: 'druid',
          }));

          triggerValidator(TYPE_FIRST_NAME, druid.data.Data.Prenume, 0);
          triggerValidator(TYPE_LAST_NAME, druid.data.Data.Nume, 0);
          triggerValidator(TYPE_NIN, druid.data.Data.CNP, 0);
          triggerValidator(TYPE_ADDRESS, druid.data.Data.Domiciliu, 0);
        }
      }

      setState((prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          ocr_scan: readingOK ? druid.data.ResultId : null,
        },
        errors: {
          ...prevState.errors,
          OCR: readingOK ? false : t('Scan failed. Ensure the ID photo is clear and try again.'),
        },
        OCRModalOpen: false,
        trigger: 'druid-failed',
      }));
    } else {
      if (druid.data.error) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            OCR: t('Scan failed. Ensure the ID photo is clear and try again.'),
          },
          OCRModalOpen: false,
          trigger: 'druid-failed-2',
        }));
      }
    }
  }, [druid]);

  React.useEffect(() => {
    // force processing the url
    PlayOnline.processUrl(); // in some cases this function is triggered two times. This is not a problem
    // autocomplete from localstorage
    const loadedFields = loadRegisterFieldsFromStorage();

    const firstName = loadedFields.firstName ?? '';
    const lastName = loadedFields.lastName ?? '';
    const nin = loadedFields.nin ?? '';
    const address = loadedFields.address ?? '';

    setState((prevState) => ({
      ...prevState,
      ...initialState,
      fields: {
        ...prevState.fields,
        firstName: firstName,
        lastName: lastName,
        nin: nin,
        address: address,
      },
      trigger: 'useEffect-[]',
    }));

    if (firstName) {
      triggerValidator(TYPE_FIRST_NAME, firstName, 0);
    }
    if (lastName) {
      triggerValidator(TYPE_LAST_NAME, lastName, 0);
    }
    if (nin) {
      triggerValidator(TYPE_NIN, nin, 0);
    }
    if (address) {
      triggerValidator(TYPE_ADDRESS, loadedFields.address ?? '', 0);
    }
    dispatch(getMarketingOffer(PlayOnline.getSavedField('moid')));
    if (!documentsMaxFileSize || documentsMaxFileSize === 0) {
      dispatch(requestDocumentsMaxFileSize());
    }
  }, []);

  // Effect for page visibility and unload
  React.useEffect(() => {
    let isLogging = false;
    let isPageClosing = false;

    const handleLog = (eventName: string) => {
      if (isLogging || actionsTaken.length === 0) return;
      try {
        isLogging = true;
        logRegistrationEvent(eventName, actionsTaken, state.currentStep, state.errors);
      } finally {
        isLogging = false;
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden && !isPageClosing) {
        handleLog('registration_page_hidden');
      }
    };

    const handleUnload = () => {
      isPageClosing = true;
      handleLog('registration_page_closed');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [actionsTaken]);

  React.useEffect(() => {
    // skip if it is calculateBonusValueUnlocked or useEffect-errors and all Blur and Focus events
    if (
      state.trigger === 'calculateBonusValueUnlocked' ||
      state.trigger === 'useEffect-errors' ||
      state.trigger.includes('Focus') ||
      state.trigger.includes('Blur')
    ) {
      return;
    }

    setActionsTaken((prevState) => {
      return [...prevState, state.trigger];
    });
  }, [state.trigger]);

  React.useEffect(() => {
    if (!partialAccount?.loading) {
      if (state.savedStep === 'step-try-login') {
        if (partialAccount.existingAccount) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            trigger: 'step-try-login-1',
          }));

          navigate('/login');
        }
      }

      if (partialAccount?.error) {
        if (state.currentStep === 'OTP') {
          setState((prevState) => ({
            ...prevState,
            errors: {
              ...prevState.errors,
              otp:
                partialAccount && partialAccount.error && partialAccount.errorMessage
                  ? partialAccount.errorMessage
                  : t('Invalid OTP'), // handle parcial account error
            },
            loading: false,
            trigger: 'invalid otp',
            // fields: { ...prevState.fields, otp: '' },
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            currentStep: 'step1',
            errors: { ...prevState.errors, global: t('Unknown error') },
            loading: false,
            trigger: 'unknown error',
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          currentStep: prevState.savedStep,
          loading: false,
          trigger: 'partialAccount state change',
        }));
      }
    }
  }, [partialAccount]);

  React.useEffect(() => {
    if (props && props.properties && props.properties.tcAcceptedByDefault === state.tcAccepted) return;
    if (props.properties.tcAcceptedByDefault !== true) return;

    setState((prevState) => ({
      ...prevState,
      tcAccepted: true,
      trigger: 'tcAcceptedByDefault',
    }));
  }, [state, props.properties.tcAcceptedByDefault]);

  React.useEffect(() => {
    if (iTimer) {
      clearInterval(iTimer);
    }
    let nextValue = state.bonusValueUnlocked;
    const incrementType = state.bonusValueUnlocked < state.bonusValueToUnlock ? 1 : -1;
    try {
      const ui = JSON.parse(marketingOffer.ui_elements);

      let startValue = 0;
      if (ui?.register_starting_value) {
        startValue = parseInt(ui.register_starting_value, 10) ?? 0;
      }
      if (nextValue < startValue) {
        nextValue = startValue;
      }
    } catch (e) {}

    const lTimer = setInterval(
      () => {
        if (nextValue >= state.bonusValueToUnlock || nextValue < 0) {
          if (lTimer) {
            clearInterval(lTimer); // optimization! should be lTimer not iTimer; this prevents infinite looping
          }
          setState((prevState) => ({
            ...prevState,
            bonusValueUnlocked: state.bonusValueToUnlock,
            trigger: 'bonusValueUnlocked-useEffect-1',
          }));
          return;
        } else {
          nextValue = nextValue + incrementType;
        }

        setState((prevState) => ({
          ...prevState,
          bonusValueUnlocked: nextValue,
          trigger: 'bonusValueUnlocked-useEffect-2',
        }));
      },
      DEFAULT_INCREMENT_TIME / (state.bonusValueToUnlock - state.bonusValueUnlocked),
    );

    setITimer(lTimer);
  }, [state.bonusValueToUnlock]);

  React.useEffect(() => {
    // loop through errors and set state

    const tmpErrorState = {};
    Object.keys(errors).forEach((key) => {
      let error: boolean | string | undefined = undefined;
      if (errors[key]) {
        // @ts-ignore
        const lError = errors[key];
        if (typeof lError.ResponseCode !== 'undefined') {
          error = ResponseErrorMessages.get(lError.ResponseCode);
        } else {
          if (typeof lError === 'string') {
            error = lError;
          }
        }

        if (key === 'phone') {
          error = phoneValidationMessage;
        }
      }

      if (typeof error !== 'undefined') {
        // @ts-ignore
        tmpErrorState[key] = error;
      } else if (errors?.[key]?.length === 0) {
        // @ts-ignore
        tmpErrorState[key] = false;
      }
    });

    setState({
      ...state,
      errors: {
        ...state.errors,
        ...tmpErrorState,
      },
      loading: false,
      trigger: 'useEffect-errors',
    });
  }, [errors, state.currentStep, state.fields, validationPhoneError, phoneValidationMessage, loading]);

  React.useEffect(() => {
    // timeout to decrease resend timer every second
    if (state.resendTimer > 0) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          resendTimer: prevState.resendTimer - 1,
          trigger: 'useEffect-resendTimer',
        }));
      }, 1000);
    }
  }, [state.resendTimer]);

  React.useEffect(() => {
    calculateBonusValueUnlocked();
  }, [state.errors, marketingOffer]);

  React.useEffect(() => {
    // console.log('RUNNING useEffect signedUp', { signedUp, state });
    if (signedUp) {
      if (REGISTER_TYPE === REGISTER_AFRICA_ONE_PAGE) {
        setState({
          ...initialState,
          // replaced final step from page to modal
          currentStep: 'step2',
          dialogOpen: false,
          loading: false,
          // keep some info to be used after popup is open
          bonusValueToUnlock: marketingOffer?.register_value_units_numeric ?? 0,
          bonusValueUnlocked: marketingOffer?.register_value_units_numeric ?? 0,
          trigger: 'signedUp-AFK',
        });

        dispatch(loginUsername(state.fields.phone));
        dispatch(loginMethod(undefined));
      } else {
        setState({
          ...initialState,
          // replaced final step from page to modal
          currentStep: 'step3',
          dialogOpen: true,
          loading: false,
          // keep some info to be used after popup is open
          bonusValueToUnlock: marketingOffer?.register_value_units_numeric ?? 0,
          bonusValueUnlocked: marketingOffer?.register_value_units_numeric ?? 0,
          trigger: 'signedUp-RO',
        });

        dispatch(loginUsername(state.fields.email));
        dispatch(loginMethod(IS_EMAIL));
      }

      dispatch(loginPassword(state.fields.password));
      dispatch(loginStart(undefined));
      ExpiringLocalStorage.set('registerFields', JSON.stringify({}), 0);
      PlayOnline.clearPaid();
      MarketingEvents.signUp();

      logRegistrationEvent('registration_completed', actionsTaken, state.currentStep, state.errors);

      // Facebook Pixel
      trackCustomMetaEvent('web_registration_complete', {
        email: state.fields.email,
      });

      // Google Tag Manager
      try {
        // @ts-ignore
        if (window.dataLayer) {
          // @ts-ignore
          window.dataLayer.push({
            registration: 'complete',
            event: 'registration-complete',
          });
        }
      } catch (err) {}
    } else {
      setState({
        ...state,
        currentStep: initialState.currentStep,
        loading: false,
        trigger: 'signedUp-false',
      });
    }
  }, [signedUp]);

  React.useEffect(() => {
    if (signedUp) {
      MarketingEvents.signUpCompleted({ playerId: client_player_id });
    }
  }, [client_player_id]);

  const goBack = () => {
    // @ts-ignore
    if (stepBackMappping?.[REGISTER_TYPE]?.[state.currentStep]) {
      logRegistrationEvent('registration_go_back', actionsTaken, state.currentStep, state.errors);

      setState((prevState) => ({
        ...prevState,
        // @ts-ignore
        currentStep: stepBackMappping[REGISTER_TYPE][prevState.currentStep],
        trigger: 'goBack',
      }));
    }
  };

  const triggerValidator = (type: string, value: string, time: number) => {
    // delayed validation to prevent multiple calls
    if (timer) {
      clearTimeout(timer);
    }
    const lTimer = setTimeout(() => {
      switch (type) {
        case TYPE_EMAIL:
          emailValidator(value);
          break;
        case TYPE_PHONE:
          phoneValidator(value);
          break;
        case TYPE_PASSWORD:
          passwordValidator(value);
          break;
        case TYPE_NICKNAME:
          nicknameValidator(value);
          break;
        case TYPE_FIRST_NAME:
          firstNameValidator(value);
          break;
        case TYPE_LAST_NAME:
          lastNameValidator(value);
          break;
        case TYPE_NIN:
          ninValidator(value);
          break;
        case TYPE_ADDRESS:
          addressValidator(value);
          break;
        case TYPE_OTP:
          otpValidator(value);
          break;
        default:
          break;
      }
    }, time);
    setTimer(lTimer);
  };

  const emailValidator = (email: string) => {
    if (email.trim() === '') {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: t('Invalid email address'),
          // email: 'REGISTER_EMAIL_REQUIRED_ERROR',
        },
        trigger: 'emailValidator-empty',
      }));
      return;
    }
    const valid = EmailValidator.validate(email);
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: !valid ? t('Invalid email address') : false,
        },
        trigger: 'emailValidator-invalid',
      }));
    } else {
      if (props.properties.emailServerValidation) {
        dispatch(validateField(email, 'email'));
      } else {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            email: !valid ? t('Invalid email address') : false,
          },
          trigger: 'emailValidator-valid',
        }));
      }
    }
  };

  const otpValidator = (otp: string) => {
    if (otp.trim() === '') {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          otp: t('Invalid OTP'),
          // otp: 'REGISTER_OTP_REQUIRED_ERROR',
        },
        trigger: 'otpValidator-empty',
      }));
      return;
    }
    const valid = otp.length > 3;
    setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        otp: !valid ? t('Invalid OTP') : false,
      },
      trigger: 'otpValidator',
    }));
  };

  const passwordValidator = (password: string) => {
    const passwordIsValid = PasswordValidator(password, t);

    setState((prevState) => ({
      ...prevState,
      passwordStrengthScore: passwordIsValid.score,
      passwordStrengthShortLabel: passwordIsValid.labels.short,
      passwordStrengthLongLabel: passwordIsValid.labels.long,
      passwordConditionsNotMet: passwordIsValid.conditionsNotMet,
      passwordMessagePlaceholders: passwordIsValid.placeholders,
      errors: {
        ...prevState.errors,
        password: !passwordIsValid.success ? passwordIsValid.message : false,
      },
      trigger: 'passwordValidator',
    }));
  };

  const phoneValidator = (phone: string) => {
    // no empty string or only spaces
    if (phone.trim() === '') {
      setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            phone: t('Invalid phone number'),
            // phone: 'REGISTER_PHONE_REQUIRED_ERROR',
          },
          trigger: 'phoneValidator-empty',
        };
      });
      return;
    }
    const valid = PhoneNumberValidator.validate(phone, currentCountry);
    // const valid = !validationPhoneError && PhoneNumberValidator.validate(phone, currentCountry);

    // console.log('RUNNING phoneValidator', { phone, valid, validationPhoneError, phoneValidationMessage });
    if (!valid) {
      setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            phone: !valid ? t('Invalid phone number') : false,
          },
          trigger: 'phoneValidator-invalid',
        };
      });
    } else {
      if (props.properties.phoneServerValidation) {
        dispatch(validateField(phone, 'phone'));
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            errors: {
              ...prevState.errors,
              // phone: !valid ? t('Invalid phone number') : false,
              phone: validationPhoneError ? t('Invalid phone number') : false,
            },
            trigger: 'phoneValidator-valid',
          };
        });
      }
    }
  };

  React.useEffect(() => {
    // loop through phone validation if phone redux validation is done
    phoneValidator(state.fields.phone);
  }, [validationPhoneError, phoneValidationMessage, loading]);

  const nicknameValidator = (nickname: string) => {
    // no empty string or only spaces
    if (nickname.trim() === '') {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          nickname: t('Invalid nickname'),
          // nickname: 'REGISTER_NICKNAME_REQUIRED_ERROR',
        },
        trigger: 'nicknameValidator-empty',
      }));
      return;
    }

    // minimum 4 chars and maximum 100 chars
    const valid = nickname.length >= 4 && nickname.length <= 100;
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          nickname: !valid ? t('Invalid nickname') : false,
        },
        trigger: 'nicknameValidator',
      }));
    } else {
      if (NICKNAME_SERVER_VALIDATION) {
        dispatch(validateField(nickname, 'nickname'));
      } else {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            nickname: !valid ? t('Invalid nickname') : false,
          },
          trigger: 'nicknameValidator-2',
        }));
      }
    }
  };

  const firstNameValidator = (firstName: string) => {
    // no empty string or only spaces
    if (firstName.trim() === '') {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          firstName: t('Invalid first name'),
          // firstName: 'REGISTER_FIRST_NAME_REQUIRED_ERROR',
        },
        trigger: 'firstNameValidator-empty',
      }));
      return;
    }

    // minimum 3 chars and maximum 100 chars
    const valid = firstName.length >= 3 && firstName.length <= 100;
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          firstName: !valid ? t('Invalid first name') : false,
        },
        trigger: 'firstNameValidator-invalid',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          firstName: false,
        },
        trigger: 'firstNameValidator-valid',
      }));
    }
  };

  const lastNameValidator = (lastName: string) => {
    // no empty string or only spaces
    if (lastName.trim() === '') {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          lastName: t('Invalid last name'),
          // lastName: 'REGISTER_LAST_NAME_REQUIRED_ERROR',
        },
        trigger: 'lastNameValidator-empty',
      }));
      return;
    }

    // minimum 3 chars and maximum 100 chars
    const valid = lastName.length >= 3 && lastName.length <= 100;
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          lastName: !valid ? t('Invalid last name') : false,
        },
        trigger: 'lastNameValidator-invalid',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          lastName: false,
        },
        trigger: 'lastNameValidator-valid',
      }));
    }
  };

  const addressValidator = (address: string) => {
    // no empty string or only spaces
    if (address.trim() === '') {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          address: t('Invalid address'),
          // address: 'REGISTER_ADDRESS_REQUIRED_ERROR',
        },
        trigger: 'addressValidator-empty',
      }));
      return;
    }

    // minimum 3 chars and maximum 100 chars
    const valid = address.length >= 3 && address.length <= 100;
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          address: !valid ? t('Invalid address') : false,
        },
        trigger: 'addressValidator-invalid',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          address: false,
        },
        trigger: 'addressValidator-valid',
      }));
    }
  };

  const ninValidator = (nin: string) => {
    if (nin.trim() === '') {
      setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            nin: t('Invalid national identification number'),
            // nin: 'REGISTER_NIN_REQUIRED_ERROR',
          },
          trigger: 'ninValidator-empty',
        };
      });
    }
    const valid = NINValidator.validate(nin);
    if (!valid) {
      setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            nin: !valid ? t('Invalid national identification number') : false,
          },
          trigger: 'ninValidator-invalid',
        };
      });
    } else {
      if (props.properties.ninServerValidation) {
        dispatch(validateField(nin, 'nin'));
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            errors: {
              ...prevState.errors,
              nin: !valid ? t('Invalid national identification number') : false,
            },
            trigger: 'ninValidator-valid',
          };
        });
      }
    }
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        email: email,
      },
      trigger: 'onEmailChange',
    }));
    triggerValidator(TYPE_EMAIL, email, DEFAULT_TRIGGER_TIME);
  };

  const onNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nickname = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        nickname: nickname,
      },
      trigger: 'onNicknameChange',
    }));
    triggerValidator(TYPE_NICKNAME, nickname, DEFAULT_TRIGGER_TIME);
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        firstName: firstName,
        ocr_scan: null,
      },
      trigger: 'onFirstNameChange',
    }));
    triggerValidator(TYPE_FIRST_NAME, firstName, DEFAULT_TRIGGER_TIME);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        lastName: lastName,
        ocr_scan: null,
      },
      trigger: 'onLastNameChange',
    }));
    triggerValidator(TYPE_LAST_NAME, lastName, DEFAULT_TRIGGER_TIME);
  };

  const onNinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nin = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        nin: nin,
        ocr_scan: null,
      },
      trigger: 'onNinChange',
    }));
    triggerValidator(TYPE_NIN, nin, DEFAULT_TRIGGER_TIME);
  };

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const address = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        address: address,
        ocr_scan: null,
      },
      trigger: 'onAddressChange',
    }));
    triggerValidator(TYPE_ADDRESS, address, DEFAULT_TRIGGER_TIME);
  };

  const onEmailBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        email: false,
      },
      trigger: 'onEmailBlur',
    }));

    triggerValidator(TYPE_EMAIL, state.fields.email, 0);
  };

  const onNicknameBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        nickname: false,
      },
      trigger: 'onNicknameBlur',
    }));

    triggerValidator(TYPE_NICKNAME, state.fields.nickname, 0);
  };

  const onFirstNameBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        firstName: false,
      },
      trigger: 'onFirstNameBlur',
    }));

    triggerValidator(TYPE_FIRST_NAME, state.fields.firstName, 0);
  };

  const onLastNameBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        lastName: false,
      },
      trigger: 'onLastNameBlur',
    }));

    triggerValidator(TYPE_LAST_NAME, state.fields.lastName, 0);
  };

  const onNinBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        nin: false,
      },
      trigger: 'onNinBlur',
    }));

    triggerValidator(TYPE_NIN, state.fields.nin, 0);
  };

  const onAddressBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        address: false,
      },
      trigger: 'onAddressBlur',
    }));

    triggerValidator(TYPE_ADDRESS, state.fields.address, 0);
  };

  const onFocusEmail = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, email: undefined },
      focusedFields: { ...prevState.focusedFields, email: true },
      trigger: 'onFocusEmail',
    }));
  };

  const onFocusNickname = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, nickname: undefined },
      focusedFields: { ...prevState.focusedFields, nickname: true },
      trigger: 'onFocusNickname',
    }));
  };

  const onFocusFirstName = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, firstName: undefined },
      focusedFields: { ...prevState.focusedFields, firstName: true },
      trigger: 'onFocusFirstName',
    }));
  };

  const onFocusLastName = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, lastName: undefined },
      focusedFields: { ...prevState.focusedFields, lastName: true },
      trigger: 'onFocusLastName',
    }));
  };

  const onFocusNin = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, nin: undefined },
      focusedFields: { ...prevState.focusedFields, nin: true },
      trigger: 'onFocusNin',
    }));
  };

  const onFocusAddress = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, address: undefined },
      focusedFields: { ...prevState.focusedFields, address: true },
      trigger: 'onFocusAddress',
    }));
  };

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        phone: phone,
      },
      trigger: 'onPhoneChange',
    }));
    triggerValidator(TYPE_PHONE, phone, DEFAULT_TRIGGER_TIME);
  };

  const onPhoneBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    // for eko input we have no event so we take the value from state
    const phone = event ? event.target.value : state.fields.phone;

    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        phone: false,
      },
      errors: {
        ...prevState.errors,
        phone: false,
      },
      trigger: 'onPhoneBlur',
    }));

    triggerValidator(TYPE_PHONE, phone, 0);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;

    if (RESTRICT_SPECIAL_CHARACTERS) {
      const specialCharacters = /[^A-Za-z0-9]/g;
      if (password.match(specialCharacters)) {
        console.log('Special characters are not allowed');
        // setState((prevState) => ({
        //   ...prevState,
        //   errors: {
        //     ...prevState.errors,
        //     password: t('Special characters are not allowed'),
        //   },
        // }));
        return;
      }
    }

    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        password: password,
      },
      trigger: 'onPasswordChange',
    }));
    triggerValidator(TYPE_PASSWORD, password, 0);
  };

  const onPasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        passwordConfirm: password,
      },
      trigger: 'onPasswordConfirmChange',
    }));
  };

  const onOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const otp = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        otp: otp,
      },
      errors: {
        ...prevState.errors,
        otp: false,
      },
      trigger: 'onOtpChange',
    }));
  };

  const onOtpBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        otp: false,
      },
      trigger: 'onOtpBlur',
    }));
    triggerValidator(TYPE_OTP, state.fields.otp, 0);
  };

  const onOtpFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, otp: undefined },
      focusedFields: { ...prevState.focusedFields, otp: true },
      trigger: 'onOtpFocus',
    }));
  };

  const onPasswordFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, password: undefined },
      focusedFields: { ...prevState.focusedFields, password: true },
      trigger: 'onPasswordFocus',
    }));
  };

  const onPasswordBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        password: false,
      },
      trigger: 'onPasswordBlur',
    }));

    triggerValidator(TYPE_PASSWORD, state.fields.password, 0);
  };

  const onTcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tcAccepted = event.target.checked;

    if (props.properties.tcAcceptedByDefault === true) return;

    setState((prevState) => ({
      ...prevState,
      tcAccepted,
      trigger: 'onTcChange',
    }));
  };

  const onMarketingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        email: marketingAccepted,
        sms: marketingAccepted,
        phone: marketingAccepted,
        partner: marketingAccepted,
      },
      trigger: 'onMarketingChange',
    }));
  };

  const onMarketingEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        email: marketingAccepted,
      },
      trigger: 'onMarketingEmailChange',
    }));
  };

  const onMarketingSmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        sms: marketingAccepted,
      },
      trigger: 'onMarketingSmsChange',
    }));
  };

  const onMarketingPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        phone: marketingAccepted,
      },
      trigger: 'onMarketingPhoneChange',
    }));
  };

  const toggleShowCheckboxes = () => {
    setState((prevState) => ({
      ...prevState,
      showCheckboxes: !prevState.showCheckboxes,
      trigger: 'toggleShowCheckboxes',
    }));
  };

  const onMarketingPartnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        partner: marketingAccepted,
      },
      trigger: 'onMarketingPartnerChange',
    }));
  };
  const onPhoneFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, phone: undefined },
      focusedFields: { ...prevState.focusedFields, phone: true },
      trigger: 'onPhoneFocus',
    }));
  };

  const onNicknameFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, nickname: undefined },
      focusedFields: { ...prevState.focusedFields, nickname: true },
      trigger: 'onNicknameFocus',
    }));
  };

  const onFirstNameFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, firstName: undefined },
      focusedFields: { ...prevState.focusedFields, firstName: true },
      trigger: 'onFirstNameFocus',
    }));
  };

  const onLastNameFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, lastName: undefined },
      focusedFields: { ...prevState.focusedFields, lastName: true },
      trigger: 'onLastNameFocus',
    }));
  };

  const onNinFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, nin: undefined },
      focusedFields: { ...prevState.focusedFields, nin: true },
      trigger: 'onNinFocus',
    }));
  };

  const onAddressFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, address: undefined },
      focusedFields: { ...prevState.focusedFields, address: true },
      trigger: 'onAddressFocus',
    }));
  };

  const isDisabled = () => {
    switch (state.currentStep) {
      case 'step1': {
        switch (REGISTER_TYPE) {
          case REGISTER_CURACAO_TWO_PAGE:
            return !state.tcAccepted || state.errors.email || state.errors.phone; // add state.errors.global ??
          case REGISTER_RO_THREE_PAGE:
            return !state.tcAccepted || state.errors.email || state.errors.phone; //|| state.errors.global;
          case REGISTER_CURACAO_ONE_PAGE:
            return (
              !state.tcAccepted ||
              state.errors.email ||
              state.errors.phone ||
              !state.fields.password ||
              state.errors.password
            );
          case REGISTER_AFRICA_ONE_PAGE:
            return (
              validationPhoneError ||
              loading ||
              state.errors.global ||
              !state.tcAccepted ||
              state.errors.phone ||
              !state.fields.password ||
              !state.fields.passwordConfirm ||
              state.fields.password !== state.fields.passwordConfirm ||
              state.errors.password
            );
          default:
            console.warn('Register type not supported for this step!');
            return false;
        }
        break;
      }
      case 'step2': {
        switch (REGISTER_TYPE) {
          case REGISTER_CURACAO_TWO_PAGE:
            return !state.fields.password || state.errors.password;
          case REGISTER_RO_THREE_PAGE:
            return state.errors.firstName || state.errors.lastName || state.errors.nin || state.errors.address;
          case REGISTER_AFRICA_ONE_PAGE:
            return false;
          default:
            console.warn('Register type not supported for this step!');
            return false;
        }
        break;
      }
      case 'step3': {
        switch (REGISTER_TYPE) {
          case REGISTER_RO_THREE_PAGE:
            return !state.fields.password || state.errors.password;
          default:
            console.warn('Register type not supported for this step!');
            return false;
        }
      }
      case 'OTP': {
        switch (REGISTER_TYPE) {
          case REGISTER_AFRICA_ONE_PAGE:
            return loading || validationPhoneError || !state.fields.otp || state.errors.otp || state.errors.global;
          case REGISTER_RO_THREE_PAGE:
            return loading || validationPhoneError || !state.fields.otp || state.errors.otp;
          default:
            console.warn('Register type not supported for this step!');
            return false;
        }
      }
      case 'step-try-login': {
        // return state.errors.global;
        return loading || validationPhoneError;
        break;
      }
      default: {
        return true;
      }
    }
  };

  const sendSMS = () => {
    if (state.resendTimer > 0) {
      return;
    }

    dispatch(
      sendRegisterSMS({
        phone: state.fields.phone,
        phone_country_code: currentCountry ?? '',
      }),
    );

    setState((prevState) => ({
      ...prevState,
      resendTimer: window.config.resendSMSTimer,
      trigger: 'sendSMS',
    }));
  };

  const signUp = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      trigger: 'signUp',
    }));
    const pac = PlayerAbuseChecker.getInfo();

    const data = {
      phone: state.fields.phone,
      phone_country_code: currentCountry ?? 'RO',
      nickname: state.fields.nickname,
      email: state.fields.email ?? '',
      password: state.fields.password,
      marketing_email: state.marketingAccepted.email ? 1 : 0,
      marketing_phone: state.marketingAccepted.phone ? 1 : 0,
      marketing_sms: state.marketingAccepted.sms ? 1 : 0,
      marketing_partners: state.marketingAccepted.partner ? 1 : 0,
      marketingAgreement:
        state.marketingAccepted.email ||
        state.marketingAccepted.phone ||
        state.marketingAccepted.sms ||
        state.marketingAccepted.partner
          ? 1
          : 0,
      moid: marketingOffer ? marketingOffer.moid : undefined,
      firstName: state.fields.firstName,
      lastName: state.fields.lastName,
      nin: state.fields.nin,
      address: state.fields.address,
      // phoneValidationCode: state.fields.otp,
      paid: PlayOnline.getPaid(), // todo remove paid if not needed
      refferenceCode: PlayOnline.getSavedField('aff_id'),
      /** */
      sub_id: PlayOnline.getSavedField('sub_id'),
      gclid: PlayOnline.getSavedField('gclid'),
      lpid: PlayOnline.getSavedField('lpid'),
      // product_id: not implemented on FE yet;
      winner_referral_id: PlayOnline.getSavedField('winner_referral_id'),
      ocr_scan: state.fields.ocr_scan,
      // dvfp: send automatically from saga
      // nwfp: send automatically from saga
      // newfp: send automatically from saga
    };

    if (window.config.registerSettings.registerUsePartialAccount !== '1') {
      // @ts-ignore
      data.phoneValidationCode = state.fields.otp;
    }

    MarketingEvents.signUpStarted();

    // console.log('DEBUG RUNNING [signUp] DISPATCH', { data, state });
    dispatch(setRegisterSignUpLoading(true));
    dispatch(register(data));
  };

  const onNextSteptWithAllConditions = () => {
    // console.log('onNextSteptWithAllConditions', state.currentStep, isDisabled());

    if (state.currentStep !== 'step1' || isDisabled()) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        email: true,
        sms: true,
        phone: true,
        partner: true,
      },
      trigger: 'onNextSteptWithAllConditions',
    }));

    nextStep();
  };

  const onNextSteptWithBasicMarketingAccepted = () => {
    // console.log('onNextSteptWithBasicMarketingAccepted', state.currentStep, isDisabled());

    if (state.currentStep !== 'step1' || isDisabled()) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        email: false,
        sms: false,
        phone: false,
        partner: false,
      },
      trigger: 'onNextSteptWithBasicMarketingAccepted',
    }));

    nextStep();
  };

  const usePartialAccount = (nextStep: string) => {
    // if (!props.properties.usePartialAccount) {
    if (window.config.registerSettings.registerUsePartialAccount !== '1') {
      // go to next step
      setState((prevState) => ({
        ...prevState,
        currentStep: nextStep,
        trigger: 'usePartialAccount-inactive',
      }));
      return;
    } else {
      // save next step for later
      setState((prevState) => ({
        ...prevState,
        savedStep: nextStep,
        trigger: 'usePartialAccount-active',
      }));
    }

    const data: any = {
      paid: PlayOnline.getPaid(),
      aff_id: PlayOnline.getSavedField('aff_id'),
      sub_id: PlayOnline.getSavedField('sub_id'),
      gclid: PlayOnline.getSavedField('gclid'),
      lpid: PlayOnline.getSavedField('lpid'),
      moid: PlayOnline.getSavedField('moid'),
      phone: state.fields.phone ?? null,
      phone_country_code: currentCountry ?? 'RO',
      email: state.fields.email ?? null,
      nin: state.fields.nin ?? null,
      first_name: state.fields.firstName ?? null,
      last_name: state.fields.lastName ?? null,
      marketing:
        (state.marketingAccepted?.email ||
          state.marketingAccepted.sms ||
          state.marketingAccepted.phone ||
          state.marketingAccepted.partner) ??
        null,
      marketing_sms: state.marketingAccepted.sms ?? null,
      marketing_email: state.marketingAccepted.email ?? null,
      marketing_phone: state.marketingAccepted.phone ?? null,
      marketing_partner: state.marketingAccepted.partner ?? null,
      address: state.fields.address ?? null,
      ocr_scan: state.fields.ocr_scan ?? null, // TODO: add ocr scan when implementing druid
    };
    if (state.fields.otp && state.currentStep === 'OTP') {
      data.phoneValidationCode = state.fields.otp; // !!!!use this only when you are on OTP screen!!!!
    }
    setState((prevState) => ({
      ...prevState,
      loading: true,
      trigger: 'usePartialAccount',
    }));

    // console.log('DEBUG RUNNING [usePartialAccount] DISPATCH', { data, state });
    dispatch(setPartialAccount(data));
  };

  const nextStep = () => {
    // check if we can go to next step

    // console.log('RUNNING onNextStept', {
    //   currentStep: state.currentStep,
    //   isDisabled: isDisabled(),
    //   regType: REGISTER_TYPE,
    // });

    if (isDisabled()) {
      return;
    }

    switch (state.currentStep) {
      case 'step1': {
        switch (REGISTER_TYPE) {
          case REGISTER_CURACAO_ONE_PAGE: {
            if (props.properties.otpActive) {
              sendSMS();
              setState((prevState) => ({ ...prevState, currentStep: 'OTP', trigger: 'nextStep-otp-curacao' }));
            } else {
              signUp();
            }
            break;
          }
          case REGISTER_CURACAO_TWO_PAGE: {
            usePartialAccount('step2');
            break;
          }
          case REGISTER_RO_THREE_PAGE: {
            if (props.properties.otpActive) {
              sendSMS();
              setState((prevState) => ({ ...prevState, currentStep: 'OTP', trigger: 'nextStep-otp-ro' }));
            } else {
              usePartialAccount('step2');
            }
            break;
          }
          case REGISTER_AFRICA_ONE_PAGE: {
            sendSMS();
            setState((prevState) => ({ ...prevState, currentStep: 'OTP', trigger: 'nextStep-otp-africa' }));
            break;
          }
          default:
            console.warn('Register type not supported for next step!');
            break;
        }
        break;
      }
      case 'step2': {
        switch (REGISTER_TYPE) {
          case REGISTER_CURACAO_TWO_PAGE: {
            if (props.properties.otpActive) {
              sendSMS();
              setState((prevState) => ({ ...prevState, currentStep: 'OTP', trigger: 'nextStep-otp-curacao' }));
            } else {
              signUp();
            }
            break;
          }
          case REGISTER_RO_THREE_PAGE: {
            usePartialAccount('step3');
            break;
          }
          case REGISTER_AFRICA_ONE_PAGE: {
            // signUp();
            break;
          }
          default: {
            console.warn('Register type not supported for next step!');
          }
        }
        break;
      }
      case 'step3': {
        switch (REGISTER_TYPE) {
          case REGISTER_RO_THREE_PAGE: {
            signUp();
            break;
          }
          default: {
            console.warn('Register type not supported for next step!');
            break;
          }
        }
        break;
      }
      case 'OTP': {
        switch (REGISTER_TYPE) {
          case REGISTER_CURACAO_ONE_PAGE:
          case REGISTER_CURACAO_TWO_PAGE: {
            signUp();
            break;
          }
          case REGISTER_RO_THREE_PAGE: {
            // usePartialAccount('step2');
            usePartialAccount('step-try-login');
            break;
          }
          case REGISTER_AFRICA_ONE_PAGE: {
            usePartialAccount('step-try-login');
            break;
          }
          default: {
            console.warn('Register type not supported for next step!');
            break;
          }
        }
        break;
      }
      case 'step-try-login': {
        switch (REGISTER_TYPE) {
          case REGISTER_RO_THREE_PAGE: {
            usePartialAccount('step2');
            break;
          }
          case REGISTER_AFRICA_ONE_PAGE: {
            if (signedUp)
              setState((prevState) => ({ ...prevState, currentStep: 'step2', trigger: 'nextStep-2-africa' }));
            break;
          }
          default: {
            console.warn('Register type not supported for next step!');
            break;
          }
        }
        break;
      }
      default: {
        return;
      }
    }

    // log event and add to actionsTaken
    logRegistrationEvent(
      `registration_${state.currentStep}_continue_clicked`,
      actionsTaken,
      state.currentStep,
      state.errors,
    );
    setActionsTaken((prevState) => [...prevState, `registration_${state.currentStep}_continue_clicked`]);
  };

  React.useEffect(() => {
    if (state.currentStep !== 'step-try-login') return;
    if (partialAccount?.existingAccount) {
      navigate('/login');
      return;
    }

    // for africa -> signup
    // for ro -> use partial account

    if (partialAccount?.loading) {
      return;
    }

    switch (REGISTER_TYPE) {
      case REGISTER_RO_THREE_PAGE: {
        usePartialAccount('step2');
        break;
      }
      case REGISTER_AFRICA_ONE_PAGE: {
        if (!signedUp && !partialAccount?.error) {
          signUp();
        }

        if (signedUp) {
          setState((prevState) => ({ ...prevState, currentStep: 'step2', trigger: 'usePartialAccount-africa' }));
        }
        break;
      }
      default: {
        console.warn('Register type not supported for next step!');
        break;
      }
    }

    // console.log('DEBUG RUNNING useEffect [step-try-login]', { state: state.currentStep, partialAccount, signedUp });
  }, [state.currentStep, partialAccount, signedUp]);

  const toggleShowPassword = () => {
    setState((prevState) => ({
      ...prevState,
      passwordInputType: state.passwordInputType === 'password' ? 'text' : 'password',
      passwordInputIcon: state.passwordInputType === 'password' ? 'bi-eye-slash-fill' : 'bi-eye-fill',
      trigger: 'toggleShowPassword',
    }));
  };

  const toggleShowConfirmPassword = () => {
    setState((prevState) => ({
      ...prevState,
      showConfirmPassword: !prevState.showConfirmPassword,
      trigger: 'toggleShowConfirmPassword',
    }));
  };

  const showUploadScreen = (e: any) => {
    const el = e.currentTarget ?? (e.currentTarget as HTMLElement);
    if (!documentsMaxFileSize || documentsMaxFileSize === 0) {
      dispatch(requestDocumentsMaxFileSize());
    }

    setState({
      ...state,
      OCRModalOpen: true,
      OCRModalScreen: 'upload',
      trigger: 'showUploadScreen',
    });
  };

  const toggleUploadScreen = () => {
    setState({
      ...state,
      OCRModalOpen: !state.OCRModalOpen,
      OCRModalScreen: 'upload',
      file: null,
      uploadedOCRFile: null,
      documentUploadError: false,
      trigger: 'toggleUploadScreen',
    });
  };

  const uploadDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    // upload document
    dispatch(scanDocument(state.file));
  };
  const openCamera = (e: any) => {
    // open camera
    onSelectFile(e);
  };

  const onSelectFile = (e: any) => {
    // get files from input event e
    const files = e?.target?.files;
    setState((v) => ({ ...v, documentUploadError: false, trigger: 'onSelectFile-reset' }));

    const size = documentsMaxFileSize ? documentsMaxFileSize.replace('Mb', '') : '2';
    Object.keys(files).forEach((attr) => {
      if (files[attr].size / 1048576 < size) {
        // check if file is image
        if (files[attr].type.includes('image')) {
          // use file reader
          const reader = new FileReader();
          const currentFile = files[attr];
          reader.onload = (e: any) => {
            setState({
              ...state,
              uploadedOCRFile: e.target.result,
              file: currentFile,
              OCRModalScreen: 'confirm-upload',
              documentUploadError: false,
              trigger: 'onSelectFile',
            });
          };
          return reader.readAsDataURL(files[attr]);
        } else {
          setState((v) => ({
            ...v,
            documentUploadError: 'DOCUMENT_UPLOAD_FILE_TYPE_NOT_SUPPORTED',
            trigger: 'onSelectFile-error-1',
          }));
        }
        return;
      } else {
        setState((v) => ({
          ...v,
          documentUploadError: 'DOCUMENT_UPLOAD_FILE_TOO_BIG',
          trigger: 'onSelectFile-error-2',
        }));

        console.warn('File size is too big');
      }
    });
  };

  const onCancelOCRUpload = () => {
    setState((v) => ({
      ...v,
      OCRModalScreen: 'upload',
      uploadedOCRFile: null,
      file: null,
      documentUploadError: false,
      trigger: 'onCancelOCRUpload',
    }));
  };

  const ui_elements = JSON.parse(marketingOffer?.ui_elements ?? '{}');
  ui_elements.bonusId = marketingOffer?.bonus_preset_id ?? false;

  const getRegisterStep = () => {
    switch (REGISTER_TYPE) {
      case REGISTER_CURACAO_ONE_PAGE: {
        return 0;
      }

      case REGISTER_CURACAO_TWO_PAGE: {
        return 0;
      }

      case REGISTER_RO_THREE_PAGE: {
        switch (state.currentStep) {
          case 'step1':
          case 'OTP': {
            return 1;
          }
          case 'step2': {
            return 2;
          }
          case 'step3': {
            return 3;
          }
          default:
            return 0;
        }
      }

      case REGISTER_AFRICA_ONE_PAGE: {
        switch (state.currentStep) {
          case 'step1': {
            return 1;
          }
          case 'step-try-login':
          case 'OTP': {
            return 2;
          }
          case 'step2': {
            return 3;
          }
          default:
            return 0;
        }
      }

      default:
        return 0;
    }
  };

  const contextValue = {
    ...state,
    // registerType: props.properties.registerType,
    registerType: window.config.registerSettings.registerType,
    usePartialAccount: window.config.registerSettings.registerUsePartialAccount,
    currentStepperStep: getRegisterStep(),
    emailError: state.errors.email === undefined || !state.errors.email ? '' : state.errors.email,
    phoneError: state.errors.phone === undefined || !state.errors.phone ? '' : state.errors.phone,
    otpError: state.errors.otp === undefined || !state.errors.otp ? '' : state.errors.otp,
    passwordError: state.errors.password === undefined || !state.errors.password ? '' : state.errors.password,
    nicknameError: state.errors.nickname === undefined || !state.errors.nickname ? '' : state.errors.nickname,
    firstNameError: state.errors.firstName === undefined || !state.errors.firstName ? '' : state.errors.firstName,
    lastNameError: state.errors.lastName === undefined || !state.errors.lastName ? '' : state.errors.lastName,
    ninError: state.errors.nin === undefined || !state.errors.nin ? '' : state.errors.nin,
    addressError: state.errors.address === undefined || !state.errors.address ? '' : state.errors.address,
    globalError: errors.global === undefined || !errors.global ? '' : errors.global,
    OCRError: state.errors.OCR === undefined || !state.errors.OCR ? '' : state.errors.OCR,
    emailInputState: state.errors.email === undefined || !state.errors.email ? 'default' : 'error',
    phoneInputState: state.errors.phone === undefined || !state.errors.phone ? 'default' : 'error',
    otpInputState: state.errors.otp === undefined || !state.errors.otp ? 'default' : 'error',
    passwordInputState: state.errors.password === undefined || !state.errors.password ? 'default' : 'error',
    nicknameInputState: state.errors.nickname === undefined || !state.errors.nickname ? 'default' : 'error',
    firstNameInputState: state.errors.firstName === undefined || !state.errors.firstName ? 'default' : 'error',
    lastNameInputState: state.errors.lastName === undefined || !state.errors.lastName ? 'default' : 'error',
    ninInputState: state.errors.nin === undefined || !state.errors.nin ? 'default' : 'error',
    addressInputState: state.errors.address === undefined || !state.errors.address ? 'default' : 'error',
    email: state.fields.email,
    phone: state.fields.phone,
    otp: state.fields.otp,
    password: state.fields.password,
    passwordConfirm: state.fields.passwordConfirm,
    nickname: state.fields.nickname,
    firstName: state.fields.firstName,
    lastName: state.fields.lastName,
    nin: state.fields.nin,
    address: state.fields.address,
    emailFocused: () => state.focusedFields.email,
    phoneFocused: () => state.focusedFields.phone,
    passwordFocused: () => state.focusedFields.password,
    nicknameFocused: () => state.focusedFields.nickname,
    firstNameFocused: () => state.focusedFields.firstName,
    lastNameFocused: () => state.focusedFields.lastName,
    ninFocused: () => state.focusedFields.nin,
    addressFocused: () => state.focusedFields.address,
    otpFocused: () => state.focusedFields.otp,
    isAnyFieldFocused:
      state.focusedFields.email ||
      state.focusedFields.phone ||
      state.focusedFields.password ||
      state.focusedFields.otp ||
      state.focusedFields.nickname ||
      state.focusedFields.firstName ||
      state.focusedFields.lastName ||
      state.focusedFields.nin ||
      state.focusedFields.address,

    marketingAccepted:
      state.marketingAccepted.email ||
      state.marketingAccepted.sms ||
      state.marketingAccepted.phone ||
      state.marketingAccepted.partner,
    marketing_sms: state.marketingAccepted.sms,
    marketing_email: state.marketingAccepted.email,
    marketing_phone: state.marketingAccepted.phone,
    marketing_partner: state.marketingAccepted.partner,

    bonusValue: marketingOffer?.register_value_units_numeric ?? 0,
    bonusCurrency: marketingOffer?.register_value_units_text
      ? Utils.formatCurrency(marketingOffer.register_value_units_text)
      : '',
    bonusImage: marketingOffer?.register_image_wl ?? '',
    // bonusBGImage: marketingOffer?.register_image ?? '',
    bonusUI: ui_elements,
    bonusUICTAText: ui_elements?.cta_text ? getText(ui_elements, `cta_text.${i18n.language}`, '') : '',
    bonusSubtitle: marketingOffer?.register_value_sub_title_text ?? '',

    onMarketingChange: onMarketingChange,
    onMarketingEmailChange: onMarketingEmailChange,
    onMarketingSmsChange: onMarketingSmsChange,
    onMarketingPhoneChange: onMarketingPhoneChange,
    onMarketingPartnerChange: onMarketingPartnerChange,
    onEmailChange: onEmailChange,
    onEmailBlur: onEmailBlur,

    onPhoneChange: onPhoneChange,
    onPhoneBlur: onPhoneBlur,
    onPasswordChange: onPasswordChange,
    onPasswordConfirmChange: onPasswordConfirmChange,
    onOtpChange: onOtpChange,
    onTcChange: onTcChange,
    onPhoneFocus: onPhoneFocus,
    onPasswordBlur: onPasswordBlur,
    onOtpBlur: onOtpBlur,
    onOtpFocus: onOtpFocus,
    onPasswordFocus: onPasswordFocus,
    onFocusEmail: onFocusEmail,
    nextStep: nextStep,
    onNextSteptWithAllConditions: onNextSteptWithAllConditions,
    onNextSteptWithBasicMarketingAccepted: onNextSteptWithBasicMarketingAccepted,
    // previousStep: previousStep,
    toggleShowPassword: toggleShowPassword,
    toggleShowConfirmPassword: toggleShowConfirmPassword,
    toggleShowCheckboxes: toggleShowCheckboxes,
    resendSMS: sendSMS,
    onNicknameChange: onNicknameChange,
    onNicknameBlur: onNicknameBlur,
    onNicknameFocus: onNicknameFocus,
    onFirstNameChange: onFirstNameChange,
    onFirstNameBlur: onFirstNameBlur,
    onFirstNameFocus: onFirstNameFocus,
    onLastNameChange: onLastNameChange,
    onLastNameBlur: onLastNameBlur,
    onLastNameFocus: onLastNameFocus,
    onNinChange: onNinChange,
    onNinBlur: onNinBlur,
    onNinFocus: onNinFocus,
    onAddressChange: onAddressChange,
    onAddressBlur: onAddressBlur,
    onAddressFocus: onAddressFocus,
    backHandler: goBack,

    nextButtonDisabled: isDisabled(),
    dialogOpen: state.dialogOpen,
    onCloseDialog: () => {
      // setState((prevState) => ({
      //   ...prevState,
      //   // dialogOpen: !state.dialogOpen
      //   dialogOpen: false,
      // }));
    },
    // @ts-ignore
    backDisabled: stepBackMappping[state.currentStep] === false,
    isTcAcceptedByDefault: props.properties.tcAcceptedByDefault,

    documents_max_file_size: documentsMaxFileSize,
    OCR_loading: druid.inProgress,
    showOCRUploadScreen: showUploadScreen,
    uploadOCRDocument: uploadDocument,
    openCamera: openCamera,
    onSelectOCRFile: onSelectFile,
    uploadedOCRFile: state.uploadedOCRFile ? state.uploadedOCRFile : '',
    toggleUploadOCRScreen: toggleUploadScreen,
    onCancelOCRUpload,
    document_name: state?.file?.name ? state?.file?.name : '',

    onPhoneNumberValidation: (valid: boolean) => {
      return valid && !state.errors.phone;
    },
    phoneCheckLoading: state.loading || loading ? true : false,
  };

  // React.useEffect(() => {
  //   console.log('REGISTER contextValue', {
  //     aaa: {
  //       passwordMessagePlaceholders: state.passwordMessagePlaceholders,
  //       step: contextValue.currentStep,
  //       phone: contextValue.phone,
  //       type: REGISTER_TYPE ?? 'no type',
  //       errors: contextValue.errors,
  //       isDisabled: isDisabled(),
  //       phoneCheckLoading: state.loading || loading,
  //       windowConfig: window.config,
  //     },
  //     contextValue,
  //   });
  // }, [
  //   contextValue.phone,
  //   contextValue.password,
  //   contextValue.tcAccepted,
  //   contextValue.currentStep,
  //   contextValue.otp,
  //   state.errors,
  //   state.currentStep,
  //   state.uploadedOCRFile,
  // ]);
  // console.log('render Register');
  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RegisterLegacy;
