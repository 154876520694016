import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

import { ComponentLayout, FileExtensions } from './constants';
import { useDocumentsState } from './hooks';
import { FilesUploaderProps, FilesUploaderState } from './types';
import {
  formatErrorMessage,
  formatExtensionsForInputAttribute,
  formatExtensionsForTooltip,
  formatFileSize,
  normalizeExtensions,
} from './utils/formatters';
import { inProgress, Loading, setLoadingState } from './utils/loading';
import { initialFilesUploaderState } from './utils/state';
import { validateFiles } from './utils/validators';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const FilesUploader = (componentProps: FilesUploaderProps) => {
  let props = componentProps;
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const {
    onSelectCallback,
    onClearCallback,
    onSaveCallback,
    onCancelCallback,
    onCloseCallback,
    isOpen = false,
  } = props.properties ?? {};

  const { documentsLoading, documentsMaxSize } = useDocumentsState();

  const [state, setState] = React.useState<FilesUploaderState>(initialFilesUploaderState());

  React.useEffect(() => {
    if (documentsLoading && !inProgress(state.loading)) {
      setState((prevState) => ({
        ...prevState,
        loading: setLoadingState(Loading.REQUEST),
      }));
    }

    if (!documentsLoading && inProgress(state.loading)) {
      setState((prevState) => ({
        ...prevState,
        loading: setLoadingState(Loading.SUCCESS),
      }));
    }
  }, [state.loading, documentsLoading]);

  const extensions = React.useMemo(() => {
    if (props.properties?.extensions !== undefined) {
      return normalizeExtensions(props.properties.extensions);
    } else {
      return FileExtensions;
    }
  }, [props.properties?.extensions]);

  const handleSelect = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const { files } = event.currentTarget;

      if (files === null || files.length === 0) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        loading: setLoadingState(Loading.REQUEST),
        layout: ComponentLayout.LOADING,
      }));

      validateFiles(files, { extensions, maxSize: documentsMaxSize })
        .then((validationResult) => {
          const loading = validationResult.isValid ? Loading.SUCCESS : Loading.FAILURE;
          const message = validationResult.isValid ? undefined : formatErrorMessage(validationResult.errors);
          const layout = validationResult.isValid ? ComponentLayout.CONFIRM : ComponentLayout.UPLOAD;

          setState((prevState) => ({
            ...prevState,
            ...validationResult,
            loading: setLoadingState(loading, message),
            layout,
            files,
          }));

          if (!documentsLoading) {
            const firstFile = files.item(0);

            if (onSelectCallback && firstFile) {
              onSelectCallback(firstFile, event);
            }
          }
        })
        .catch((message) => {
          setState((prevState) => ({
            ...prevState,
            loading: setLoadingState(Loading.FAILURE, message),
            layout: ComponentLayout.UPLOAD,
          }));
        });
    },
    [onSelectCallback, extensions, documentsLoading, documentsMaxSize],
  );

  const handleClear = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialFilesUploaderState(),
    }));

    if (onClearCallback) {
      onClearCallback();
    }
  }, [onClearCallback]);

  const handleSave = React.useCallback(() => {
    const { files } = state;

    if (files === undefined || files.length === 0) {
      return;
    }

    const firstFile = files.item(0);

    if (onSaveCallback && firstFile) {
      onSaveCallback(firstFile);
    }
  }, [onSaveCallback, state]);

  const handleCancel = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialFilesUploaderState(),
    }));

    if (onCancelCallback) {
      onCancelCallback();
    }
  }, [onCancelCallback]);

  const handleClose = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialFilesUploaderState(),
    }));

    if (onCloseCallback) {
      onCloseCallback();
    }
  }, [onCloseCallback]);

  const contextValue = React.useMemo(() => {
    const firstFile = state.files?.item(0) ?? undefined;

    return {
      isOpen,
      handleSelect,
      handleClear,
      handleSave,
      handleCancel,
      handleClose,
      layout: state.layout,
      fileContent: firstFile ? state.contents[firstFile.name] : '',
      fileName: firstFile ? firstFile.name : '',
      fileExtensions: formatExtensionsForTooltip(extensions),
      inputAccept: formatExtensionsForInputAttribute(extensions),
      maxSize: formatFileSize(documentsMaxSize),
      errorMessage: formatErrorMessage(state.errors),
      dialogTitle: props.properties?.dialogTitle,
      dialogDescription: props.properties?.dialogDescription,
    };
  }, [
    dataElementContext,
    componentProps,
    isOpen,
    handleSelect,
    handleClear,
    handleSave,
    handleCancel,
    handleClose,
    state.layout,
    state.contents,
    state.files,
    extensions,
    documentsMaxSize,
    state.errors,
    props.properties?.dialogTitle,
    props.properties?.dialogDescription,
  ]);

  // console.log('DEBUG [FilesUploader]:', contextValue, state);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default FilesUploader;
