import * as actionTypes from './actionTypes';

export const requestPaymentLink = (paymentDetails, isSkrill) => {
  return {
    type: actionTypes.paymentCheckout.REQUEST_PAYMENT_LINK,
    paymentDetails,
    isSkrill,
  };
};

export const receivedPaymentLink = (data) => {
  return {
    type: actionTypes.paymentCheckout.RECEIVED_PAYMENT_LINK,
    response: data,
  };
};

export const requestVivaToken = () => {
  return {
    type: actionTypes.paymentCheckout.REQUEST_VIVA_TOKEN,
  };
};

export const receivedVivaToken = (data) => {
  return {
    type: actionTypes.paymentCheckout.RECEIVED_VIVA_TOKEN,
    data: data,
  };
};

export const requestVivaCardToken = () => {
  return {
    type: actionTypes.paymentCheckout.REQUEST_VIVA_CARD_TOKEN,
  };
};

export const requestBPCardToken = () => {
  return {
    type: actionTypes.paymentCheckout.REQUEST_BP_CARD_TOKEN,
  };
};

export const requestBPWithdraw = (token, amount) => {
  return {
    type: actionTypes.paymentCheckout.REQUEST_BP_WITHDRAW,
    token: token,
    amount: amount,
  };
};

export const requestVivaWithdraw = (token, amount) => {
  return {
    type: actionTypes.paymentCheckout.REQUEST_VIVA_WITHDRAW,
    token: token,
    amount: amount,
  };
};

export const receivedCardToken = (data) => {
  return {
    type: actionTypes.paymentCheckout.RECEIVED_PAYMENT_TOKEN,
    data: data,
  };
};

export const requestVivaChargeToken = (playerData) => {
  return {
    type: actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN,
    playerData: playerData,
  };
};

export const sendVivaChargeToken = (data, paymentDetails) => {
  return {
    type: actionTypes.paymentCheckout.SEND_VIVA_CHARGE_TOKEN,
    data: data,
    paymentDetails
  };
};

export const receivedVivaChargeToken = (data) => {
  return {
    type: actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN,
    data: data,
  };
};

export const requestVivaChargeTokenWithCard = (cardToken, playerData) => {
  return {
    type: actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD,
    cardToken: cardToken,
    playerData: playerData,
  };
};

export const receivedVivaChargeTokenWithCard = (data) => {
  return {
    type: actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN,
    data: data,
  };
};
