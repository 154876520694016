import React from 'react';
import styled from 'styled-components';
import { extend } from 'lodash-es';
import { useNavigate, useMatches, Navigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../store';
import axios from 'axios';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { launchGameUrl, LaunchUrlResponse } from '../../../api/slot-games/gameApi';
import GameIframe, { GameActions } from './utils/GameIframe';
import { gameThumbnail } from './utils/functions';
import { fetchTournamentInfo } from '../game-header';
import resolveGameInfo from '../../../utils/game-info-api-cache';
import { reloadWalletsData, setGameIsRunning } from '../../../modules/casino/store/actions/games';
import { recordPlaySession } from './utils/favorite_store';
import { exitSlotGame } from '../../action-handler/actions';
import urlonStringify from '../../../utils/urlon';

import './index.scss';
import { changeUserScalableViewportMeta } from '@/page-components/utils/functions';

type SlotGameLauncherProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
    targetId: string;
    targetIdFromPath: boolean;
    pathParamKey: string;
    gameImageKey: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const SlotGameLauncherDiv = styled.div<{ $styleText: string }>`
  ${(props) => props.$styleText}
`;

interface FetchGameReturn {
  link?: string;
  error?: Error;
}

export const fetchGameId = (gameId: string | undefined, authenticated: boolean, force = 0) => {
  const [launchUrl, setLaunchUrl] = React.useState<FetchGameReturn>({});

  React.useEffect((): any => {
    let stillRelevant = true;

    if (authenticated && gameId !== 'egt_switch_game' && gameId != null && gameId !== '') {
      launchGameUrl(gameId)
        .then((value): void => {
          if (value) {
            if (stillRelevant) {
              recordPlaySession(gameId);
              setLaunchUrl({ link: (value as LaunchUrlResponse).data.game_data.link });
            }
          }
        })
        .catch((err) => setLaunchUrl({ error: err }));
    } else {
      setLaunchUrl({});
    }

    return () => {
      stillRelevant = false;
    };
  }, [gameId, authenticated, force]);

  return launchUrl;
};

interface IframeMessageProps {
  messageType: GameActions;
  command: GameActions;
  data: any;
}

const SlotGameLauncher = (componentProps: SlotGameLauncherProps) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const matches = useMatches();
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const hhRunning = useAppSelector((state) => state.happyHour.running);
  const [switchGameId, setSwitchGameId] = React.useState<string | null>(null);
  const [ifrKey, setIfrKey] = React.useState(0);
  const [closed, setClosed] = React.useState(false);

  const slugify = (str: string) => {
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
  };

  // try to initialize gameId from the properties object
  let gameId = componentProps.properties?.targetId;
  const gameImageKey = componentProps.properties?.gameImageKey ?? 'landscape_ar32_var_keycenter';

  if (
    componentProps.properties &&
    componentProps.properties.targetIdFromPath &&
    componentProps.properties.pathParamKey
  ) {
    if (matches && matches.length) {
      // there is no gameId set as componentProps; maybe were in a route that includes a :pathParamKey param
      const match = matches[0];
      if (match.params && match.params[componentProps.properties.pathParamKey] != null) {
        gameId = match.params[componentProps.properties.pathParamKey];
      }
    }
  }

  // egt might ask as to load a new game instead. they have the option to switch games inside their game.
  // so if this is the case let's overwrite
  if (switchGameId !== null) {
    gameId = switchGameId;
  }

  const { tournament } = fetchTournamentInfo(Number(gameId), i18n.language);

  React.useEffect(() => {
    // clear from-game path in local storage
    localStorage.removeItem('fgm');

    return () => {
      dispatch(reloadWalletsData());
    };
  }, []);

  // eslint-disable-next-line
  let { link, error } = fetchGameId(gameId, 'user' === authentication.auth_type);
  const { data: gameData, loaded: gameDataLoaded } = resolveGameInfo({
    dsId: window.config.dataSourceAllGames,
    gameId: gameId,
    authenticationToken: authentication?.access_token,
  });

  //console.log({ gameId, link, error, gameData });

  let uiState = '';
  if (!('user' === authentication.auth_type)) {
    uiState = 'authenticate';
  } else if ((!link && !error) || switchGameId === 'egt_switch_game') {
    uiState = 'loading';
  } else if (error) {
    console.error('Error fetching game link:', error);
    uiState = 'error';
  } else {
    uiState = 'game';
    if (gameData?.data?.name) localStorage.setItem('momentumGame', `${gameId}|${slugify(gameData?.data?.name)}`);
  }

  if (closed) {
    uiState = 'loading';
  }

  React.useEffect(() => {
    dispatch(setGameIsRunning(uiState === 'game' && !!link));
    return () => {
      dispatch(setGameIsRunning(false));
    };
  }, [uiState, link]);

  const onIframeMessage = React.useCallback(
    (message: IframeMessageProps) => {
      if (message.command === GameActions.CloseGame) {
        console.log('onIframeMessage:CloseGame');
        setClosed(true);
        exitSlotGame(navigate);
      } else if (message.command === GameActions.EgtReloadCurrentGame) {
        console.log('onIframeMessage:ReloadCurrentGame');
        setIfrKey((oldKey) => oldKey + 1);
      } else if (message.command === GameActions.EgtLoadNewGame) {
        console.log('onIframeMessage:LoadNewGame', message.data);
        if (message.data?.toString() !== gameData.data.metadata_rpid.toString()) {
          setSwitchGameId('egt_switch_game');
          axios
            .get(`${window.config.dataSourceApiUrl}/resolve/source`, {
              headers: {
                Authorization: 'Bearer ' + authentication?.access_token,
              },
              params: {
                q: urlonStringify({
                  id: window.config.dataSourceAllGames,
                  filter: [{ name: 'metadata_rpid', value: message.data?.toString() }],
                }),
              },
            })
            .then((resp) => {
              if (resp?.data?.data?.length === 1) {
                if (resp.data.data[0].url) {
                  navigate(resp.data.data[0].url);
                  setSwitchGameId(null);
                }
              }
            })
            .catch((e: Error) => {});
        }
      }
    },
    [navigate],
  );

  const aspectRatioClass = React.useMemo(() => {
    const re = RegExp('ar([0-9.]+)');
    const matches = re.exec(gameImageKey);

    if (matches && matches.length === 2) {
      let val = matches[1];
      val = val.replaceAll('.', '_');

      if (val) {
        return `ar-${val}`;
      }
    }
    return 'ar-11';
  }, [gameImageKey]);

  if (!gameId) return <Navigate to="/" replace />;

  const game = gameDataLoaded && gameData ? gameData.data : null; //games.find((g) => g.game_id.toString() === gameId);

  React.useEffect(() => {
    // add user-scalable=no to viewport meta
    changeUserScalableViewportMeta(true);

    // remove user-scalable=no to viewport meta
    return () => changeUserScalableViewportMeta(false);
  }, []);

  const contextValue = {
    uiState: uiState,
    gameId: game?.id,
    id: game?.id,
    name: game?.name,
    providerId: game?.provider_id,
    providerGameId: game?.id,
    image: game ? gameThumbnail(game, gameImageKey) : null,
    noGameHeader: !((hhRunning && hhRunning['online-slots']) || tournament),
    aspectRatioClass: aspectRatioClass,
    toggleGameFullScreen: () => {
      const elem = document.getElementById('game-frame');
      if (elem) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        }
        // @ts-ignore
        else if (elem.webkitRequestFullscreen) {
          /* Safari */ // @ts-ignore
          elem.webkitRequestFullscreen();
        }
        // @ts-ignore
        else if (elem.msRequestFullscreen) {
          /* IE11 */ // @ts-ignore
          elem.msRequestFullscreen();
        }
      }
    },
  };

  //console.log('SlotGameLauncher[contextValue]', contextValue);

  if (link && !link?.startsWith('http')) {
    console.error('Invalid game link:', link);
    link = undefined;
    contextValue.uiState = 'error';
  }

  return (
    <SlotGameLauncherDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      {uiState === 'game' && !!link && <GameIframe key={ifrKey} gameUrl={link} onIframeMessage={onIframeMessage} />}
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </SlotGameLauncherDiv>
  );
};

export default SlotGameLauncher;
