import { ComponentLayout } from '../constants';
import { FilesUploaderState } from '../types';
import { Loading, setLoadingState } from './loading';

export function initialFilesUploaderState(partialState?: Partial<FilesUploaderState>): FilesUploaderState {
  return {
    isValid: false,
    errors: {},
    contents: {},
    files: undefined,
    loading: setLoadingState(Loading.IDLE),
    layout: ComponentLayout.UPLOAD,
    ...partialState,
  };
}
