import { store } from '../../store';
import evBus from '../../utils/evbus';
import { styleToObj } from '../../components/modules/wheel/utils/index';
import {
  specialDateToString,
  getIsOver30Days,
  getTranslatedText,
  getCurrencyNumberInfo,
  getNumberInfo,
  getLanguageCode,
} from './functions';
import { saveCalendarSelection } from '../../modules/bets/store/actions/calendar';
import { prematchFetchSportByDay } from '../../modules/bets/store/actions/prematch';
import { formatDate } from '@/components/modules/bets/utils/functions';

interface Props {
  [key: string]: any;
}
interface Context {
  [key: string]: any;
}

interface Returns extends Array<any | boolean | null> {
  /**
   * value
   *
   * @type {any|null}
   */
  0: any | null;
  /** appliedFromContext */
  1: boolean;
}

export type PagesSEORules = Array<{ path: string; text: unknown; }>;

interface EvaluatePropFunction {
  (props: Props, context?: Context): boolean;
}

// const regExp = /<[^>]*>?/gm; // moved to functions.ts

const globalFN = {
  styleToObj,
  specialDateToString,
  getIsOver30Days,
  slugify: (str: string) => {
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
  },
  setPrematchCalendarToAll: (e: any) => {
    //console.log('setPrematchCalendarToAll', e);
    if (e?.target?.dataset?.mType === 'prematch') {
      const idSport = e?.target?.dataset?.idSport;
      if (idSport) {
        store.dispatch(saveCalendarSelection(0));
        store.dispatch(prematchFetchSportByDay({ day: 0, idSport: idSport, noDays: 31 }));
      }
    }
  },
  getTranslatedText,
  getLanguageCode,
  getCurrencyNumberInfo,
  getNumberInfo,
  getSEOFooterContent: (pages: PagesSEORules, pathName: string) => {
    const orderedRules = [...pages].sort((a, b) => {
      const pathA = a.path;
      const pathB = b.path;

      if (pathA === '*') {
        return 1;
      } else if (pathB === '*') {
        return -1;
      } else {
        const segmentsA = pathA.split('/').filter(s => s !== '').length;
        const segmentsB = pathB.split('/').filter(s => s !== '').length;

        if (segmentsA !== segmentsB) {
          return segmentsB - segmentsA;
        } else {
          return pathA.localeCompare(pathB);
        }
      }
    });

    const foundRule = orderedRules.find(rule => {
      const isAsteriskPresent = rule.path.includes('*');
      const strippedPath = isAsteriskPresent ? rule.path.split('*')[0] : rule.path;
      // console.log('Rule to test: ', rule.path);
      // console.log('Path: ', pathName);
      return isAsteriskPresent ? pathName.includes(strippedPath) : pathName === strippedPath;
    });

    return foundRule;
  },
  formatMatchDate: (date: number) => {
    const appLang: string = store.getState().application.language ?? window.config.defaultLanguage;
    return formatDate(date, appLang);
  },
};

// store pro
let functionsCache: any = {};

export const invalidatePropsFunctionsCache = () => {
  functionsCache = {};
};

export const evaluatePropFunction: EvaluatePropFunction = (props, context) => {
  const state = store.getState();
  const propFunction = props?.properties['propFunction'] != null ? props?.properties['propFunction'] : null;

  if (props != null && propFunction != null && propFunction) {
    try {
      let fn = null;
      if (props.uniqueId && functionsCache[props.uniqueId] == null) {
        fn = window.Function(`"use strict"; return ${propFunction}`)();
        //console.log('evaluatePropFunction[CacheMiss]', { uniqueId: props.uniqueId });
      } else if (
        props.uniqueId &&
        functionsCache[props.uniqueId] != null &&
        functionsCache[props.uniqueId].length === propFunction.length
      ) {
        fn = functionsCache[props.uniqueId].fn;
        //console.log('evaluatePropFunction[CacheHit]', { uniqueId: props.uniqueId });
      } else {
        fn = window.Function(`"use strict"; return ${propFunction}`)();
        //console.log('evaluatePropFunction[NoCache]', { uniqueId: props.uniqueId });
      }

      if (fn && props.uniqueId) {
        functionsCache[props.uniqueId] = {
          fn,
          length: propFunction.length,
        };
      }

      // this function might change the props values
      fn(props, context, state, evBus, globalFN);
    } catch (err) {
      try {
        console.log('evaluatePropFunction: function call', { err, props: JSON.parse(JSON.stringify(props)), context });
      } catch (e) {
        console.log('evaluatePropFunction: function call', { err, props, context });
      }
    }
    return true;
  }
  return false;
};
