import React from 'react';

import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { loginMethod, loginUsername, scanDocument } from '@/modules/casino/store/actions';
import {
  register,
  sendRegisterSMS,
  setPartialAccount,
  setRegisterSignUpLoading,
} from '../../../../modules/casino/store/actions/register';

import { loginPassword, loginStart } from '@/modules/casino/store/actions/login';
import ExpiringLocalStorage from '../../../../modules/casino/utils/ExpiringLocalStorage';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';
import MarketingEvents from '@/utils/marketing/MarketingEvents';
import { IS_EMAIL } from '@/modules/casino/utils/LoginMethods';
import { useRegisterForm } from '@/components/modules/register/register-dynamic/hooks/useRegisterForm';
import { RegisterModuleStateProps, RegisterProps } from '@/components/modules/register/register-dynamic/types';
import { useRegisterConfig } from '@/components/modules/register/register-dynamic/hooks/useRegisterConfig';
import { useRegisterMarketingOffer } from '@/components/modules/register/register-dynamic/hooks/useRegisterMarketingOffer';
import { TYPE_PHONE } from '@/components/modules/register/register-dynamic/const';
import Utils from '@/modules/casino/utils/Utils';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Register = (componentProps: RegisterProps) => {
  const navigate = useNavigate();

  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const { errors, signedUp, partialAccount } = useAppSelector((state) => state.register);
  const { client_player_id } = useAppSelector((state) => state.profile);
  const { currentCountry, validationPhoneError } = useAppSelector<any>((state) => state.allCountries);
  const tcAcceptedByDefault = props.properties.tcAcceptedByDefault ?? false;

  const initialState = {
    currentStep: 0,
    savedStep: 0,
    loading: false,
    errors: {
      global: errors.global ?? false,
      OCR: false,
    },
    fields: {
      // added dynamically
    },
    checkboxes: {
      tc: {
        checked: false,
      },
    },

    bonusValueUnlocked: 0,
    bonusValueToUnlock: 0,
    resendTimer: 0,
    showCheckboxes: false,

    passwordInputType: 'password',
    passwordStrengthScore: 0,
    passwordStrengthShortLabel: 'Poor',
    passwordStrengthLongLabel: 'Password strength is poor',
    passwordInputIcon: 'bi-eye-fill',
    showConfirmPassword: false,
    passwordMessagePlaceholders: {
      ERROR_MESSAGE: '',
    },

    dialogOpen: false,
    file: null,
    uploadedOCRFile: null,
    OCRModalOpen: false,
    OCRModalScreen: '',
    documentUploadError: false,
    trigger: 'initial-State',
  };

  const [state, setState] = React.useState<RegisterModuleStateProps>(initialState);

  const registerForm = useRegisterForm(state, setState, props.properties);

  const config = useRegisterConfig(state, setState, props.properties.config);

  const marketingOffer = useRegisterMarketingOffer(state, setState, props.properties);

  React.useEffect(() => {
    // force processing the url
    PlayOnline.processUrl(); // in some cases this function is triggered two times. This is not a problem
    // autocomplete from localstorage
    const {fields, checkboxes} = registerForm.getValuesFromLocalStorage();
    setState((prevState) => ({
      ...prevState,
      checkboxes: {
        ...prevState.checkboxes,
        tc: {
          checked: tcAcceptedByDefault,
        },
        ...checkboxes,
      },
      fields: {
        ...state.fields,
        ...fields,
      },
    }));
  }, []);

  React.useEffect(() => {
    if (partialAccount.existingAccount) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        trigger: 'step-existing-account',
      }));

      return navigate('/login');
    }
  }, [partialAccount]);

  /**
   * This function is called when the resend timer is changed
   */
  React.useEffect(() => {
    // timeout to decrease resend timer every second
    if (state.resendTimer > 0) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          resendTimer: prevState.resendTimer - 1,
          trigger: 'useEffect-resendTimer',
        }));
      }, 1000);
    }
  }, [state.resendTimer]);

  /**
   * This function is called when we have a response from the server regarding the sign-up
   */
  React.useEffect(() => {
    if (signedUp) {
      setState((prevState) => ({
        ...prevState,
        currentStep:
          config.stepsMap.congratsElementStep !== -1 ? config.stepsMap.congratsElementStep : prevState.currentStep,
        dialogOpen: config.stepsMap.congratsElementStep === -1 && config.stepsMap.congratsModalStep !== -1,
        loading: false,
      }));

      if (window.config.authenticationSettings.enableEmail) {
        dispatch(loginUsername(state.fields.email.value));
        dispatch(loginMethod(IS_EMAIL));
      } else if (window.config.authenticationSettings.enablePhone) {
        dispatch(loginUsername(state.fields.phone.value));
        dispatch(loginMethod(undefined));
      }

      dispatch(loginPassword(state.fields.password.value));
      dispatch(loginStart(undefined));

      ExpiringLocalStorage.set('registerFields', JSON.stringify({}), 0);
      PlayOnline.clearPaid();
      MarketingEvents.signUp();
    } else {
      setState({
        ...state,
        currentStep: initialState.currentStep,
        loading: false,
        trigger: 'signedUp-false',
      });
    }
  }, [signedUp]);

  /**
   *
   */

  /**
   * This function is called after login is done
   */
  React.useEffect(() => {
    if (signedUp) {
      MarketingEvents.signUpCompleted({ playerId: client_player_id });
    }
  }, [client_player_id]);

  const goBack = () => {
    setState((prevState) => ({
      ...prevState,
      currentStep: config.getPreviousStep(prevState.currentStep),
      trigger: 'goBack',
    }));
  };

  const isBackDisabled = () => {
    return (
      state.currentStep === 0 ||
      (state.currentStep === config.stepsMap.final && config.stepsMap.congratsElementStep === config.stepsMap.final)
    );
  };

  const isDisabled = () => {
    if (state.loading || validationPhoneError) {
      return true;
    }
    return registerForm.isNextButtonDisabled();
  };

  const sendSMS = () => {
    if (state.resendTimer > 0) {
      return;
    }

    dispatch(
      sendRegisterSMS({
        phone: state.fields.phone.value,
        phone_country_code: currentCountry ?? '',
      }),
    );

    setState((prevState) => ({
      ...prevState,
      resendTimer: window.config.resendSMSTimer,
      trigger: 'sendSMS',
    }));
  };

  const signUp = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      trigger: 'signUp',
    }));
    const forSignUp = true;
    const fields = registerForm.getValues(forSignUp);

    const data = {
      phone_country_code: currentCountry ?? 'RO',
      // TODO: product_id: not implemented on FE yet;
      ...fields,
    };

    if (window.config.registerSettings.registerUsePartialAccount !== '1') {
      // @ts-ignore
      data.phoneValidationCode = state.fields.otp;
    }

    MarketingEvents.signUpStarted();

    // console.log('DEBUG RUNNING [signUp] DISPATCH', { data, state });
    dispatch(setRegisterSignUpLoading(true));
    dispatch(register(data));
  };

  const nextStep = (type: string = '') => {
    // check if we can go to next step
    if (isDisabled()) {
      return;
    }
    const nextStep = state.currentStep + 1;
    // check if last step
    if (state.currentStep === config.stepsMap.final) {
      signUp();
      if (config.stepsMap.congratsElementStep === -1) {
        // stop here if we don't have congrats element step
        return;
      }
    } else if (nextStep === config.stepsMap.otp) {
      sendSMS();
    }

    // check if we have OTP
    if (state.fields.otp?.value && nextStep >= config.stepsMap.otp) {
      nextStepUsingPartialAccount(nextStep);
      return;
    }

    setState((prevState) => {
      const newState = {
        ...prevState,
        currentStep: nextStep,
        trigger: 'nextStep',
      };

      switch (type) {
        case 'continue':
          // do nothing;
          break;
        case 'accept-and-continue':
          newState.checkboxes = {
            ...prevState.checkboxes,
            marketing: {
              ...prevState.checkboxes.marketing,
              checked: true,
            },
          };
          delete newState.checkboxes.marketing_email;
          delete newState.checkboxes.marketing_sms;
          delete newState.checkboxes.marketing_phone;
          delete newState.checkboxes.marketing_partner;
          break;
        case 'accept-and-continue-basic':
          newState.checkboxes = {
            ...prevState.checkboxes,
            marketing: {
              ...prevState.checkboxes.marketing,
              checked: true,
            },
          };
          delete newState.checkboxes.marketing_email;
          delete newState.checkboxes.marketing_sms;
          delete newState.checkboxes.marketing_phone;
          delete newState.checkboxes.marketing_partner;
          break;
        default:
          console.warn('Register button type not supported for next step!');
          break;
      }
      return newState;
    });
  };

  const nextStepUsingPartialAccount = (nextStep: number) => {
    if (window.config.registerSettings.registerUsePartialAccount !== '1') {
      // go to next step
      setState((prevState) => ({
        ...prevState,
        currentStep: nextStep,
        trigger: 'usePartialAccount-inactive',
      }));
      return;
    } else {
      // save next step for later
      setState((prevState) => ({
        ...prevState,
        savedStep: nextStep,
        trigger: 'usePartialAccount-active',
      }));
    }
    const forSignUp = false;
    const fields = registerForm.getValues(forSignUp);

    const data: any = {
      phone_country_code: currentCountry ?? 'RO',
      ...fields,
    };

    if (state.fields.otp && state.currentStep === config.stepsMap.otp) {
      data.phoneValidationCode = state.fields.otp.value;
      // reset sms timer;
      setState((prevState) => ({
        ...prevState,
        resendTimer: 0,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      loading: true,
      trigger: 'usePartialAccount',
    }));

    dispatch(setPartialAccount(data));
  };

  const onStepClick = (step: number) => {
    if (step > state.currentStep || (step === config.stepsMap.final && config.stepsMap.congratsElementStep !== -1) || signedUp) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      currentStep: step,
      trigger: 'onStepClick',
    }));
  };
  const stepperConfig = React.useMemo(() => {
    const stepsCount = config.steps[config.stepsMap.final].stepperIndex;
    const steps = [];
    const currentStep = config.steps[state.currentStep].stepperIndex;

    for (let i = 0; i < stepsCount; i++) {
      steps.push({
        step: i + 1,
        currentStep: currentStep,
        stepsCount: stepsCount,
        onClick: () => onStepClick(i + 1),
      });
    }
    return {
      steps: steps,
    };
  }, [config.steps, state.currentStep]);

  const onOCRButtonClick = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      OCRModalOpen: true,
      trigger: 'choice-button-ocr',
    }));
  }, [state]);

  const closeOCRModal = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      OCRModalOpen: false,
    }));
  }, [state]);

  // register DS: https://micros-dev1.play-online.com/betsAdmin/data/data-element-template/1efb76c5-bb90-410e-86fb-3cf8105debe3
  // component DS: https://micros-dev1.play-online.com/betsAdmin/data/data-element-template/dc20201f-3c0b-4124-85da-214a83da61bb
  const contextValue = {
    components: config.steps[state.currentStep]?.components.map((component: any) => {
      // @ts-ignore
      if (component) {
        component = {
          ...component,
          showComponent: true,
        };
      }
      switch (component.layout) {
        case 'registration-bonus-card':
          component.registrationBonusContext = {
            value: marketingOffer.details?.register_value_units_numeric ?? 0,
            currency: marketingOffer.details?.register_value_units_text
              ? Utils.formatCurrency(marketingOffer.details.register_value_units_text)
              : '',
            image: marketingOffer.details?.register_image_wl ?? '',
            bonusUI: marketingOffer.ui_elements,
            title: marketingOffer.details.register_value_sub_title_text ?? '',
          };
          break;
        case 'page-heading':
          component.pageHeadingContext = {
            title: `REGISTER.PAGE-HEADING.STEP-${config.steps[state.currentStep].stepperIndex}-${config.steps[state.currentStep].stepperSubIndex}.TITLE`,
            description: `REGISTER.PAGE-HEADING.STEP-${config.steps[state.currentStep].stepperIndex}-${config.steps[state.currentStep].stepperSubIndex}.DESCRIPTION`,
            showIcon: !!component.context?.iconName,
            ...component.context,
          };
          break;
        case 'input':
          component.inputContext = {
            helperText: state.fields[component.type]?.error ?? '',
            value: state.fields[component.type]?.value ?? '',
            name: component.type,
            helper: !!state.fields[component.type]?.error,
            state: state.fields[component.type]?.error ? 'error' : 'default',
            inputType: component.type === TYPE_PHONE ? 'tel' : 'text',
            label: true,
            labelText: `REGISTER.INPUT.LABEL.${component.type.toUpperCase()}`,
            placeholder: `REGISTER.INPUT.PLACEHOLDER.${component.type.toUpperCase()}`,
            onChange: registerForm.onFieldChange,
            onBlur: registerForm.onFieldBlur,
            onFocus: registerForm.onFieldFocus,
          };
          break;
        case 'password-input':
          component.inputPasswordContext = {
            bootstrapRightIcon: state.passwordInputType === 'password' ? 'fi-eye-off' : 'fi-eye',
            errorMessage: state.fields[component.type]?.error ?? '',
            showPasswordStrength: component.type === 'password' && !state.fields[component.type]?.error,
            strengthDescription: state.passwordStrengthLongLabel,
            strengthLabel: state.passwordStrengthLongLabel,
            strengthShortLabel: state.passwordStrengthShortLabel,
            strengthProgressValue: state.passwordStrengthScore,
            errorMessagePlaceholders: state.passwordMessagePlaceholders, //
            value: state.fields[component.type]?.value ?? '',
            name: component.type,
            status: state.fields[component.type]?.error ? 'error' : 'default',
            inputType: state.passwordInputType,
            placeholder: `REGISTER.INPUT.LABEL.${component.type.toUpperCase()}`,
            onChange: registerForm.onFieldChange,
            onBlur: registerForm.onFieldBlur,
            onFocus: registerForm.onFieldFocus,
            onClickRightIcon: () => {
              setState((prevState: RegisterModuleStateProps) => ({
                ...prevState,
                passwordInputType: prevState.passwordInputType === 'password' ? 'text' : 'password',
              }));
            },
          };
          break;
        case 'checkbox':
          component.inputContext = {
            state:
              component.type === 'tc' ? (props.properties.tcAcceptedByDefault ? 'disabled' : 'default') : 'default',
            label: true,
            labelText: `REGISTER.CHECKBOX.LABEL.${component.type.toUpperCase()}`,
            value: state.checkboxes[component.type]?.checked ?? '',
            name: component.type,
            onChange: registerForm.onFieldChange,
          };
          break;
        case 'congrats-modal':
          // TODO: implement congrats-modal with [eko] modal-content & [eko] modal-footer
          break;
        case 'congrats-element':
          // TODO: implement congrats-element with [eko] modal-content & [eko] modal-footer
          break;
        case 'button':
          component.buttonContext = {
            state: isDisabled() ? 'disabled' : 'default',
            onClick: () => nextStep(component.type),
            text: `REGISTER.BUTTON.STEP-${config.steps[state.currentStep].stepperIndex}-${config.steps[state.currentStep].stepperSubIndex}.${component.type.toUpperCase()}`,
          };
          break;
        case 'choice-button':
          component.choiceButtonContext = {
            showLeadingIcon: !!component.context?.leadingIconName,
            showTrailingIcon: !!component.context?.trailingIconName,
            title: `REGISTER.CHOICE-BUTTON.STEP-${config.steps[state.currentStep].stepperIndex}-${config.steps[state.currentStep].stepperSubIndex}.${(component.type ?? 'default').toUpperCase()}.TITLE`,
            description: `REGISTER.CHOICE-BUTTON.STEP-${config.steps[state.currentStep].stepperIndex}-${config.steps[state.currentStep].stepperSubIndex}.${(component.type ?? 'default').toUpperCase()}.DESCRIPTION`,
            onClick: () => nextStep(),
            showAlertMessage: false,
            ...component.context,
          };
          break;
        case 'choice-button-ocr':
          const error = state.fields.ocr_scan?.error ? 'REGISTER.OCR-BUTTON.ERROR' : '';
          component.choiceButtonContext = {
            showLeadingIcon: !!component.context?.leadingIconName,
            showTrailingIcon: !!component.context?.trailingIconName,
            title: 'REGISTER.OCR-BUTTON.TITLE',
            description: 'REGISTER.OCR-BUTTON.DESCRIPTION',
            showAlertMessage: !!error,
            alertMessage: {
              message: error,
              state: 'error',
            },
            onClick: onOCRButtonClick,
            opts: {
              isOpen: state.OCRModalOpen,
              onCloseCallback: closeOCRModal,
              onCancelCallback: closeOCRModal,
              onSaveCallback: (file: File) => {
                // upload document
                dispatch(scanDocument(file));
              },
            },
            ...component.context,
          };
          break;
        case 'resend-sms':
          component = {
            ...component,
            resendTimer: state.resendTimer,
            resendSMS: sendSMS,
          };
          break;
        case 'global-error':
          component = {
            ...component,
            alertBoxContext: {
              message: 'REGISTER.GLOBAL-ERROR',
              state: 'error',
            },
            showComponent: !!state.errors.global,
          };
          break;
        case 'divider':
        case 'text':
        default:
          break;
      }
      return {
        ...component,
      };
    }),
    stepperContext: stepperConfig,
    goBack,
    isBackDisabled: isBackDisabled(),
  };

  React.useEffect(() => {
    console.log('RUNNING [REGISTER] useEffect ', { state, trg: state.trigger });
  }, [state.trigger]);
  // console.log('[REGISTER] contextValue', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Register;
