import { PaymentProviderInterface } from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import PaymentProvider, {
  PaymentProviderConstructor,
} from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import { PaymentProvider as PaymentProviderConstant } from '@/constants/paymentProvider';
import { oktoCreatePaymentCode, oktoGetCustomer, oktoReset } from '@/modules/casino/store/actions/deposit';
import cordovaRedirect from '../../utils/cordovaRedirect';

class Okto extends PaymentProvider implements PaymentProviderInterface {
  constructor(data: PaymentProviderConstructor) {
    super(data);
    this.setType(PaymentProviderConstant.okto);
  }
  init(data: any): void {
    super.init(data);
    if (data.getCustomer) {
      this.dispatch(oktoGetCustomer()); // this is needed to know if we need to show T&C to user
    }
  }

  destroy() {
    this.dispatch(oktoReset());
  }

  confirmPayment(): void {
    this.dispatch(oktoCreatePaymentCode(this.paymentDetails));
  }

  redirect(url: string): void {
    if (window.config.cordova) {
      this.openedPopupUrl = cordovaRedirect(url, this.openedPopupUrl);
      return;
    }
    window.location.href = url;
  }
}

export default Okto;
