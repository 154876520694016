import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import store, { useAppDispatch, useAppSelector } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { getData } from '../utils/hooks';
import {
  testValues,
  liveScore,
  formatDate,
  formatOddName,
  formatBetTitle,
  getTr,
  floorFigure,
  isWin1Odd,
  isWin2Odd,
  isDrawOdd,
} from '../utils/functions';
import { prematchFetchMatch } from '../../../../modules/bets/store/actions/prematch';
import { liveFetchMatch } from '../../../../modules/bets/store/actions/live';
import { digitainWidgetLiveUrl } from '../../../../modules/bets/api/widgets';
import evBus from '../../../../utils/evbus';
import { configLoadBetsMarket } from '../../../../modules/bets/store/actions/config';
import { betBuilderOpenUpdate } from '../../../../modules/bets/store/actions/betBuilder';

import './index.scss';
import { MatchType, BetType } from '../utils/types';

import { processComponentProps } from '@/page-components/utils/processComponentProps';
import crests from '../../../../modules/bets/utils/crests';
import { getTranslatedText } from '@/page-components/utils/functions';

type MatchDetails = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const extractUriParams = (match: any, props: any) => {
  let mType = props.properties.mType;
  let idSport = props.properties.idSport;
  let idCategory = props.properties.idCategory;
  let idTournament = props.properties.idTournament;
  let idMatch = props.properties.idMatch;

  let paramMType = props.properties.paramMType ?? 'mType';
  if (paramMType[0] === ':') paramMType = paramMType.substr(1);
  if (match.params && paramMType && match.params[paramMType] != null) {
    mType = match.params[paramMType];
  }

  if (mType === 'live-match') {
    mType = 'live';
  } else if (mType === 'pre-match') {
    mType = 'prematch';
  }

  let paramIdSport = props.properties.paramIdSport ?? 'idSport';
  if (paramIdSport[0] === ':') paramIdSport = paramIdSport.substr(1);
  if (match.params && paramIdSport && match.params[paramIdSport] != null) {
    idSport = match.params[paramIdSport];
  }

  let paramIdCategory = props.properties.paramIdCategory ?? 'idCategory';
  if (paramIdCategory[0] === ':') paramIdCategory = paramIdCategory.substr(1);
  if (match.params && paramIdCategory && match.params[paramIdCategory] != null) {
    idCategory = match.params[paramIdCategory];
  }

  let paramIdTournament = props.properties.paramIdTournament ?? 'idTournament';
  if (paramIdTournament[0] === ':') paramIdTournament = paramIdTournament.substr(1);
  if (match.params && paramIdTournament && match.params[paramIdTournament] != null) {
    idTournament = match.params[paramIdTournament];
  }

  let paramIdMatch = props.properties.paramIdMatch ?? 'idMatch';
  if (paramIdMatch[0] === ':') paramIdMatch = paramIdMatch.substr(1);
  if (match.params && paramIdMatch && match.params[paramIdMatch] != null) {
    idMatch = match.params[paramIdMatch];
  }
  return [mType, idSport, idCategory, idTournament, idMatch];
};

const validPeriod = (p: MatchType, mType: string) => {
  if (!p) return false;

  if (mType && p.mType !== mType) {
    return false;
  }

  if (p.mType === 'live' && p.currentStatus && (!p.currentStatus.IsLiveStarted || p.currentStatus.IsLiveFinished)) {
    return false;
  }

  if (!(p && p.active && p.bettingStatus && p.matchBets && Array.isArray(p.matchBets) && p.matchBets.length > 0)) {
    return false;
  }

  let oddActive = false;

  for (const mb of p.matchBets) {
    for (const o of mb.mbOutcomes) {
      if (o.mboActive) {
        oddActive = true;
        break;
      }
    }
  }

  return oddActive;
};

const getMatchMarketGroups = (match: MatchType, matchBets: BetType[], noFirst: boolean = false) => {
  const bst = store.getState().bets;
  const smg: any = {
    // @ts-ignore
    ...bst[match.mType].marketGroups[0],
    // @ts-ignore
    ...bst[match.mType].marketGroups[match.idSport],
  };
  // @ts-ignore
  const bets: any = bst[match.mType].bets[match.idSport];

  if (typeof bets === 'undefined') {
    return [];
  }

  const mkg: any = {};

  const others: any[] = [];

  matchBets.forEach((mb) => {
    if (!(mb.idBet in bets)) {
      return;
    }

    const b = bets[mb.idBet];

    if (typeof b === 'undefined' || b === null) {
      return;
    }

    if (typeof b.betGroups !== 'undefined' && b.betGroups !== null && b.betGroups.length > 0) {
      b.betGroups.forEach((bgId: string) => {
        let addToOthers = false;

        if (bgId in smg) {
          mkg[bgId] = mkg[bgId] || { ...smg[bgId], matchBets: [] };
          mkg[bgId].matchBets.push(mb.idMb);
        } else {
          addToOthers = true;
        }

        if (addToOthers) {
          others.push(mb.idMb);
        }
      });
    } else {
      others.push(mb.idMb);
    }
  });

  if (!noFirst)
    mkg['all'] = {
      id: 'all',
      name: 'All',
      isAll: true,
      position: -1,
    };

  const mkgs = Object.values(mkg);
  mkgs.sort((e1: any, e2: any) => {
    return testValues(e1['position'], e2['position']);
  });

  return mkgs;
};

export const buildOutcomes = (
  market: BetType,
  match: MatchType | null,
  bets: any,
  selectedBets: any,
  language: string,
) => {
  if (!match) return { marketName: '', outcomes: [] };

  const marketName = formatBetTitle(market, match, bets, language);

  const res: any[] = [];

  const selectedHash: any = {};
  selectedBets.forEach((bet: any) => {
    selectedHash[bet.idMbo] = true;
  });

  market.mbOutcomes.forEach((outcome) => {
    if (outcome.deactivateTime && outcome.deactivateTime < Date.now()) return;

    if (outcome.mboActive) {
      let oddName = formatOddName(market.idBet, outcome, match, bets, language);
      if (isWin1Odd(marketName, oddName)) {
        oddName = getTr(match.team1Name, language);
        oddName = oddName?.replace?.('/', ' / ');
      } else if (isWin2Odd(marketName, oddName)) {
        oddName = getTr(match.team2Name, language);
        oddName = oddName?.replace?.('/', ' / ');
      } else if (isDrawOdd(marketName, oddName)) {
        oddName = 'ODD_DRAW';
        oddName = oddName?.replace?.('/', ' / ');
      }

      const data = {
        mType: match.mType,
        idSport: match.idSport,
        idMatch: match.idMatch,
        idBet: market.idBet,
        idMb: market.idMb,
        idBo: outcome.idBo,
        idMbo: outcome.idMbo,
        oddValue: floorFigure(outcome.mboOddValue),
        changeDir: outcome.changeDir,
        oddName: oddName,
        marketName: marketName,
        selected: !!selectedHash[outcome.idMbo],
      };

      res.push(data);
    }
  });

  return { marketName, outcomes: res };
};

enum STATUS {
  NO_BET = 1,
  CAN_BET,
}

type BuildMarketsResult = {
  markets: BetType[];
  marketGroups: any[];
  marketDescriptions: {
    [idBet: string]: string;
  };
  finalIdx: number;
  hasMatch?: boolean;
  fromPeriod?: boolean;
};
type BuildMarketsReturn = [number | null, BuildMarketsResult | null];

const buildMarkets = (
  match: MatchType,
  bets: any,
  selectedBets: any,
  language: string,
  descriptions: any,
  periodId: any,
): BuildMarketsReturn => {
  if (!match) return [null, null];
  if (!match.active) return [null, null];
  if (!match.bettingStatus) return [null, null];
  if (!match.matchBets) return [null, null];

  let status = 0;

  if (!match.bettingStatus) {
    status = STATUS.NO_BET;

    return [status, null];
  }

  status = STATUS.CAN_BET;

  let mb = match && match.matchBets ? [...match.matchBets] : [];

  // periods
  if (match.periods && Array.isArray(match.periods)) {
    for (const p of match.periods) {
      if (p.active && p.bettingStatus && p.matchBets && Array.isArray(p.matchBets) && p.matchBets.length > 0) {
        for (const pmb of p.matchBets) {
          const nmb = { ...pmb };

          const mbDisplayName: any = {};
          Object.keys(pmb.mbDisplayName).forEach((key: any) => {
            if (p && p.periodShortName[key]) {
              mbDisplayName[key] = p.periodShortName[key] + ' - ' + pmb.mbDisplayName[key];
            } else {
              mbDisplayName[key] = pmb.mbDisplayName[key];
            }
          });

          nmb.mbDisplayName = mbDisplayName;
          nmb.fromPeriod = true;
          mb.push(nmb);
        }
      }
    }
  }

  mb = mb.filter((market) => {
    if (!market.mbActive) return false;
    if (market.mbOutcomes.length === 0) return false;

    const hasActive = market.mbOutcomes.find((outcome) => outcome.mboActive === true);
    return !!hasActive;
  });

  const mkg: any[] = getMatchMarketGroups(match, mb, true);

  mb.sort((e1, e2) => {
    const tv = testValues(e1['mbPosition'], e2['mbPosition']);
    if (tv !== 0) {
      return tv;
    }

    return testValues(e1['mbSpecialValue'], e2['mbSpecialValue']);
  });

  let finalIdx = -1;

  const hDescription: { [idBet: string]: string } = {};

  mb.forEach((m, index) => {
    let title = formatBetTitle(m, match, bets, language);
    if (typeof title === 'object' && (title['2'] || title['0'])) {
      title = title['2'] ?? title['0'] ?? '';
    }

    if (title && typeof descriptions[title.toLowerCase()] !== 'undefined') {
      hDescription[m.idBet] = descriptions[title.toLowerCase()];
    }
    if (title && (title.toLowerCase() === 'final' || title.toLowerCase() === 'result')) {
      finalIdx = index;
    }
  });

  return [
    status,
    {
      markets: mb,
      marketGroups: mkg,
      marketDescriptions: hDescription,
      finalIdx,
      hasMatch: true,
    },
  ];
};

const MatchDetails = (componentProps: MatchDetails) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const { i18n, t } = useTranslation();

  const [mounted, setMounted] = React.useState(false);
  const uriMatches = useMatches();
  const dispatch = useAppDispatch();

  const [selectedMarketGroup, setSelectedMarketGroup] = React.useState<string | null>(null);
  const [selectedMarketGroupType, setSelectedMarketGroupType] = React.useState<string | null>(null);
  const [loadedMarkets, setLoadedMarkets] = React.useState('');
  const [showScout, setShowScout] = React.useState(false);
  const [showLiveTV, setShowLiveTV] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const betsMarketsOrder: any = useAppSelector((state) => state.bets.config.betsMarketsOrder);
  const isWinnerFun = useAppSelector((state) => state.bets.app.isWinnerFun);
  const [bannerIndex, setBannerIndex] = React.useState(0);
  const betBuilder: any = useAppSelector((state) => state.bets.betBuilder);
  const [tabIndex, setTabIndex] = React.useState(0);

  const onTabChange = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.tabIndex) {
      const tabIndex = parseInt(e.currentTarget.dataset.tabIndex, 10) ?? 0;
      setTabIndex(tabIndex);
    }
  }, []);

  const getShownNumber = () => {
    let shown = 0;
    const tmp = localStorage.getItem('bb-shown');
    if (tmp && !isNaN(parseInt(tmp, 10))) {
      shown = parseInt(tmp, 10);
    }
    return shown;
  };

  const [showBannerIfo, setShowBannerInfo] = React.useState(getShownNumber() < 3 ? true : false);

  const hideBannerInfo = () => {
    setShowBannerInfo(false);
    const shown = getShownNumber();
    localStorage.setItem('bb-shown', (shown + 1).toString());
  };

  const [expanded, setExpanded] = React.useState<{ [index: string]: boolean }>({});
  const [search, setSearch] = React.useState({
    enabled: false,
    query: '',
  });

  // try to initialize gameId from the properties object
  let mType = props.properties.mType;
  let idSport = props.properties.idSport;
  let idCategory = props.properties.idCategory;
  let idTournament = props.properties.idTournament;
  let idMatch = props.properties.idMatch;

  if (props.properties.targetIdFromPath) {
    if (uriMatches && uriMatches.length) {
      // there is no gameId set as props; maybe were in a route that includes a :gameId param
      const match = uriMatches[0];

      [mType, idSport, idCategory, idTournament, idMatch] = extractUriParams(match, props);
    }
  }

  const { sports, categories, tournaments, matchesList, bets, selectedBets, betDesc, fullStateLoaded } = getData({
    type: mType,
  });

  React.useEffect(() => {
    setMounted(true);
  }, []);

  React.useEffect(() => {
    setShowScout(false);
    setHeight(0);
    setTabIndex(0);
  }, [idMatch]);

  React.useEffect(() => {
    if (betsMarketsOrder && betsMarketsOrder[idSport] == null) {
      dispatch(configLoadBetsMarket(idSport));
    }
  }, [idSport, betsMarketsOrder]);

  // listen for resize message from digitain iframe
  const handleResizeMessage = React.useCallback((message: any) => {
    if (message.origin === 'https://widgets.feeddigi.com' && message.data) {
      if ('liveInfoHeight' === message.data.type) {
        setHeight(message.data.value);
      }
    }
  }, []);

  React.useEffect(() => {
    if (showScout) {
      window.addEventListener('message', handleResizeMessage);
    }
    return () => {
      if (showScout) {
        window.removeEventListener('message', handleResizeMessage);
      }
    };
  }, [showScout]);

  React.useEffect(() => {
    if (fullStateLoaded && idSport && idCategory && idTournament && idMatch) {
      const key = [mType, idSport, idCategory, idTournament, idMatch].join('-');
      if (loadedMarkets === key) return;
      if (mType === 'live') {
        if (matchesList[idMatch]?._loaded === false) {
          dispatch(liveFetchMatch([idMatch]));
        }
      } else {
        dispatch(prematchFetchMatch([idMatch]));
      }
      setLoadedMarkets(key);
    }
  }, [fullStateLoaded, mType, idSport, idCategory, idTournament, idMatch, loadedMarkets, matchesList[idMatch]]);

  React.useEffect(() => {
    dispatch(betBuilderOpenUpdate(selectedMarketGroup === 'betBuilder'));
  }, [selectedMarketGroup]);

  // opens or closes a market
  const onMarketToggle = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.idmb) {
      const idmb = e.currentTarget.dataset.idmb;
      const exp = e.currentTarget.dataset.exp;
      setExpanded((v) => ({
        ...v,
        // toggle expanded state for a market; some markets are by default expanded at
        // first so we need to check for that
        [idmb]: v[idmb] != null ? !v[idmb] : exp === 'true' ? false : true,
      }));
    }
  }, []);

  // handles the click on a market group
  const onMarketGroupToggle = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.id && e.currentTarget.dataset.type) {
      const id = e.currentTarget.dataset.id;
      const type = e.currentTarget.dataset.type;
      setSelectedMarketGroup(id);
      setSelectedMarketGroupType(type);

      if (props.properties.inPage !== true) window.scrollTo(0, 0);
    }
  }, []);

  // handles the market search input change
  const onChangeSearch = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const value = e.target ? (e.target as HTMLInputElement).value : '';
    setSearch((v) => ({ ...v, query: value }));
    if (props.properties.inPage !== true) window.scrollTo(0, 0);
  }, []);

  const clearSearch = React.useCallback(() => {
    setSearch((v) => ({ ...v, query: '' }));
  }, []);

  const removeAccents = (name: any) => {
    let str = name;
    if (typeof name === 'object') {
      str = getTranslatedText(name);
    }

    if (!str) return str;
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };

  const contextValue = React.useMemo(() => {
    let error = 'Match not found';

    const buildListParams = [];

    if (idMatch) {
      if (sports[idSport] != null && sports[idSport].sportActive) {
        if (idCategory) {
          if (categories[idCategory] != null && categories[idCategory].categoryActive) {
            if (idTournament) {
              if (tournaments[idTournament] != null && tournaments[idTournament].tournamentActive) {
                if (idMatch) {
                  if (matchesList[idMatch] != null) {
                    buildListParams.push(idSport, idCategory, idTournament, idMatch);
                    error = '';
                  }
                }
              }
            }
          }
        }
      }
    }

    let res: BuildMarketsResult = {
      markets: [],
      marketGroups: [],
      marketDescriptions: {},
      finalIdx: -1,
      hasMatch: true,
    };

    let match: MatchType | null = null;

    if (!error && buildListParams.length > 0) {
      if (buildListParams.length === 4 && matchesList[idMatch]) {
        match = { ...matchesList[idMatch] };
        match.matchDateTimeString = formatDate(match.matchDateTime, i18n.language, false, true);
        if (match.mType === 'live') match.scoreInfo = liveScore(match, match.currentStatus);

        const [status, result] = buildMarkets(
          match,
          bets,
          selectedBets,
          i18n.language,
          betDesc,
          selectedMarketGroupType === 'period' && selectedMarketGroup ? selectedMarketGroup : null,
        );

        if (status === STATUS.CAN_BET && result) {
          res = result;
        }
      } else {
        res.hasMatch = false;
      }
    } else {
      res.hasMatch = false;
    }

    let markets = res.markets;
    // filter all markets by the selected market group
    if (selectedMarketGroup && selectedMarketGroupType === 'group') {
      const mgIndex = res.marketGroups.findIndex((mg) => mg.id.toString() === selectedMarketGroup);

      if (mgIndex > -1 && res.marketGroups.length > 0 && res.marketGroups[mgIndex] != null) {
        const mg = res.marketGroups[mgIndex];

        if (mg && !mg.isAll && !search.query) {
          markets = markets.filter((m) => {
            return mg.matchBets.indexOf(m.idMb) > -1 && !m.fromPeriod;
          });
        }
      }
    } else if (selectedMarketGroup && selectedMarketGroupType === 'period' && match) {
      const mb = [];

      // make sure the period exists, is active and betting is enabled
      let p: MatchType | null | undefined = null;
      if (match.periods && Array.isArray(match.periods)) {
        p = match.periods.find((p) => p.idMatch === selectedMarketGroup);
      }
      if (p && validPeriod(p, match.mType)) {
        for (const pmb of p.matchBets) {
          const nmb = { ...pmb };

          const mbDisplayName: any = {};
          Object.keys(pmb.mbDisplayName).forEach((key: any) => {
            if (p && p.periodShortName[key]) {
              mbDisplayName[key] = p.periodShortName[key] + ' - ' + pmb.mbDisplayName[key];
            } else {
              mbDisplayName[key] = pmb.mbDisplayName[key];
            }
          });

          nmb.mbDisplayName = mbDisplayName;
          mb.push(nmb);
        }
      }

      markets = mb;
    }

    // build the final list of markets with reduced data
    let finalMarkets = markets
      .map((m, index) => {
        // exclude ultra odds from winner fun
        if (isWinnerFun && m.idBet.indexOf('dup') === 0) return null;

        let exp = false;
        if (expanded[m.idMb] != null) {
          exp = expanded[m.idMb];
        } else if (index < 3) {
          exp = true;
        }

        // build the list of outcomes for each market
        const { marketName, outcomes } = buildOutcomes(m, match, bets, selectedBets, i18n.language);

        return {
          idBet: m.idBet,
          idMb: m.idMb,
          expanded: exp,
          name: marketName,
          outcomes: outcomes,
        };
      })
      .filter((m) => m != null);

    if (search.query) {
      finalMarkets = finalMarkets.filter((m: any) => {
        const name: string = removeAccents(m.name);
        const sq = removeAccents(search.query);

        return name.includes(sq);
      });
    }

    finalMarkets = finalMarkets.filter((m: any) => {
      return m.outcomes.length > 0;
    });

    const urlScout = match?.hasScout
      ? digitainWidgetLiveUrl(match?.idMatch, i18n.language, match?.hasScout, match?.hasLiveTV, 1)
      : '';
    const urlLiveTV = match?.hasLiveTV
      ? digitainWidgetLiveUrl(match?.idMatch, i18n.language, match?.hasScout, match?.hasLiveTV, 2)
      : '';

    if (match) {
      match.team1LogoUrl = crests(match.team1Name, match, 1);
      match.team2LogoUrl = crests(match.team2Name, match, 2);
    }

    const marketGroupsTabs: any = [];
    res.marketGroups.forEach((mg, index) => {
      marketGroupsTabs.push({
        id: mg.id,
        clickId: mg.id,
        name: mg.name,
        nameCompare: mg.nameEN?.toLowerCase(),
        position: mg.position * 10000,
        type: 'group',
        selected:
          selectedMarketGroup != null &&
          selectedMarketGroup.toString() === mg.id.toString() &&
          selectedMarketGroupType === 'group'
            ? true
            : false,
      });
    });
    if (match) {
      match.periods
        ?.filter((p) => {
          if (!match) return false;
          return validPeriod(p, match.mType);
        })
        .forEach((p, i) => {
          marketGroupsTabs.push({
            id: p.idMatch,
            clickId: p.idMatch,
            name: p.periodShortName,
            nameCompare: p.periodShortNameEN?.toLowerCase(),
            position: p.position * 10000,
            type: 'period',
            selected:
              selectedMarketGroup != null &&
              selectedMarketGroup.toString() === p.idMatch.toString() &&
              selectedMarketGroupType === 'period'
                ? true
                : false,
          });
        });
    }

    const finalTabs = [
      {
        id: 'all',
        clickId: -1,
        isAll: true,
        position: -1,
        name: 'All',
        nameCompare: 'all',
        selected:
          selectedMarketGroup == null || (selectedMarketGroup === 'all' && selectedMarketGroupType === 'group')
            ? true
            : false,
        type: 'group',
      },
      window.config.betBuilderEnable === '1'
        ? {
            id: 'betBuilder',
            clickId: -1,
            name: t('Bet Builder'),
            nameCompare: 'bet builder',
            position: 0,
            selected: selectedMarketGroup === 'betBuilder' ? true : false,
            type: 'betBuilder',
          }
        : null,
      ...(marketGroupsTabs ?? []),
    ]
      .filter((e) => e != null)
      .sort((a, b) => a.position - b.position);

    if (match && betsMarketsOrder?.[idSport]?.length > 0) {
      let overrideOrder = ['For You', 'All', 'Bet Builder', 'Most Popular', 'Main'];
      if (betsMarketsOrder[idSport] && betsMarketsOrder[idSport].length > 0) {
        overrideOrder = betsMarketsOrder[idSport];
      }
      const bmobv: any = {};
      overrideOrder.forEach((id, i) => {
        bmobv[id?.toLowerCase()] = i;
      });

      finalTabs.sort((a, b) => {
        if (bmobv[a.nameCompare?.trim()] != null && bmobv[b.nameCompare?.trim()] != null) {
          return bmobv[a.nameCompare?.trim()] - bmobv[b.nameCompare?.trim()];
        }
        if (bmobv[a.nameCompare?.trim()] != null) {
          return -1;
        }
        if (bmobv[b.nameCompare?.trim()] != null) {
          return 1;
        }
        return 0;
      });
    }

    let statistics = '';
    if (match?.head2HeadId && !isNaN(Math.abs(match?.head2HeadId))) {
      let url = '';
      if (match?.mType === 'live') {
        /*
        url = `https://livescore.winner.ro/matchview/${match?.headToHeadSportId}/g/${Math.abs(match?.head2HeadId)}/?lg=${
          i18n.language
        }`;
        */

        url = `https://statsinfo.co/live/${match?.headToHeadSportId}/g/${Math.abs(match?.head2HeadId)}/?lg=${
          i18n.language
        }&guid=4a8a4633-1e72-4274-aefe-ff53396605b0`;
      } else {
        /*
        url = `https://stats.winner.ro/matchview/${match?.headToHeadSportId}/g/${Math.abs(match?.head2HeadId)}/?lg=${
          i18n.language
        }`;
        */
        url = `https://statsinfo.co/stats/${match?.headToHeadSportId}/g/${Math.abs(match?.head2HeadId)}/?lg=${
          i18n.language
        }&guid=4a8a4633-1e72-4274-aefe-ff53396605b0`;
      }

      if (url) {
        statistics = url;
      }
    }

    const contextValue = {
      tabIndex,
      onTabChange,
      markets: finalMarkets,
      marketGroups: finalTabs,
      marketDescriptions: res.marketDescriptions,
      finalIdx: res.finalIdx,
      onMarketToggle: onMarketToggle,
      onMarketGroupToggle: onMarketGroupToggle,
      hasMatch: res.hasMatch,
      // @ts-ignore
      match: match,

      iframeHeight: showScout ? height : showLiveTV ? 320 : 0,
      iframeUrl: showScout ? urlScout : showLiveTV ? urlLiveTV : '',
      showScout: showScout && res.hasMatch,
      showLiveTV: showLiveTV && res.hasMatch,
      statisticsUrl: statistics,
      toggleScout: () => {
        if (showScout) {
          setHeight(0);
        }
        setShowScout((v) => {
          return !v;
        });
        setShowLiveTV(false);
      },
      toggleLiveTV: () => {
        if (showLiveTV) {
          setHeight(0);
        }
        setShowLiveTV((v) => {
          return !v;
        });
        setShowScout(false);
      },

      searchEnabled: search.enabled,
      searchQuery: search.query,
      toggleSearch: () => {
        setSearch((v) => ({ query: '', enabled: !v.enabled }));
      },
      onChangeSearch: onChangeSearch,
      clearSearch: clearSearch,
      inPage: props.properties.inPage === true,
      closeInPage: () => {
        evBus.emit('HIDE_MATCH_DETAILS', {});
      },
      selectedMarketGroup,
      hasBetBuilder:
        mType === 'prematch' &&
        window.config.betBuilderEnable === '1' &&
        (match?.idSport === '1' || match?.idSport === '3' || match?.idSport === '4'),
      hasBetBuilderBets: match?.idMatch === betBuilder?.idMatch && betBuilder?.bbInfo?.totalBets > 0,
      bannerIndex,
      setBannerIndex,

      showBannerIfo,
      hideBannerInfo,

      goOnTopOfThePage: () => {
        window.scrollTo(0, 0);
      },
    };

    //console.log('MatchDetails[contextValue]', contextValue);

    return contextValue;
  }, [
    tabIndex,
    onTabChange,
    sports,
    categories,
    tournaments,
    matchesList,
    bets,
    selectedBets,
    betDesc,
    fullStateLoaded,
    mType,
    idSport,
    idCategory,
    idTournament,
    idMatch,
    expanded,
    search,
    showBannerIfo,
    selectedMarketGroup,
    selectedMarketGroupType,
    loadedMarkets,
    showScout,
    showLiveTV,
    height,
    betsMarketsOrder,
    isWinnerFun,
    bannerIndex,
    betBuilder,
    hideBannerInfo,
    onMarketToggle,
    onMarketGroupToggle,
    onChangeSearch,
    clearSearch,
  ]);

  if (!mounted) return null;

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default MatchDetails;
