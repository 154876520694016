import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip);

//import './index.scss';

type DoughnutChartProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

    const DoughnutChart = (componentProps: DoughnutChartProps) => {
  let props = componentProps;

  const data = {
    labels: [ 'Live Sports', 'Sports', 'Live Casino', 'Casino'],
    datasets: [
      {
        data: [20, 50, 20, 10],
        backgroundColor: [
            '#00CD78',
            '#10C',
            '#FD0',
            '#FF008C',
        ],
        borderColor: '#fff',
        borderRadius: 12,
        spacing: 0,
        borderWidth: 10,
        hoverOffset: 1,
      },
    ],
  };
  const chartOptions = {
    cutout: '70%',
    responsive: true,
    maintainAspectRatio: true,
  };

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const contextValue = React.useMemo(() => {
    return {};
  }, [dataElementContext, componentProps]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
        <DataElementContext.Provider value={contextValue}>
            <Doughnut data={data} options={chartOptions} />
            {componentProps.children}
        </DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default DoughnutChart;
