import * as React from 'react';

import './page-layout.scss';
import { useAppSelector, useAppDispatch } from '../../store';
import { fetchElements, clearDataElements } from '../../store/slices/dataElements';
import { fetchSources, clearDataSources } from '../../store/slices/dataSources';
import craftJsParser from '../utils/craftJsParser';
import PageLoader from '../page-loader';
import parseDataSources from '../utils/parseDataSources';
import useMaintenanceGateway from '@/modules/maintenance/MaintenanceGateway';

type PageLayoutProps = {
  id: string;
  pageId: string;
};

const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const { id, pageId } = props;
  const config = useAppSelector((state) => state.templatesConfig);
  const isAuthInProgress = useAppSelector((state) => state.authentication.in_progress);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user') as boolean,
  );
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);

  const action = useMaintenanceGateway();

  //console.log('Page[props]', props);

  const dispatch = useAppDispatch();

  const pageLayout = config.pageLayouts[id];
  let projectData: any = pageLayout.projectData;

  const page = pageId ? config.pages[pageId] : null;

  if (page && page.a && !authenticated && config.setPages && config.setPages['/login']) {
    if (config.setPages['/login'].page_layout_id) {
      projectData = config.pageLayouts[config.setPages['/login'].page_layout_id].projectData;
    } else {
      projectData = config.pages[config.setPages['/login'].page_id].projectData;
    }
  }

  React.useEffect(() => {
    if (authenticationToken) {
      const [dataSources, dataElements] = parseDataSources({ craftState: projectData });
      if (dataSources.length) {
        const noRefresh: any = [];
        const withRefresh: any = [];

        dataSources.forEach((ds) => {
          if (config.dataSources[ds] && config.dataSources[ds].enable_auto_refresh) {
            withRefresh.push(ds);
          } else {
            noRefresh.push(ds);
          }
        });

        if (withRefresh?.length) dispatch(fetchSources({ ids: withRefresh }));
        if (noRefresh?.length) dispatch(fetchSources({ ids: noRefresh }));
      }
      if (dataElements.length) {
        // @ts-ignore
        dispatch(fetchElements({ ids: dataElements }));
      }
    }
  }, [projectData, authenticationToken, config.dataSources]);

  const data: any = React.useMemo(() => {
    //console.log('render page layout', id, pageId);
    if (action?.redirect && config?.setPages?.['/maintenance/default']?.page_layout_id) {
      const content = craftJsParser({
        craftState: projectData,
        rootNodeId: 'ROOT',
        pageLayoutId: config.setPages['/maintenance/default'].page_layout_id,
        pageId: config.setPages['/maintenance/default'].page_id,
        pageType: 'page',
        options: {
          authenticated,
        },
        withProvider: true,
      });

      return { content: <div className="page-layout">{content}</div>, isPage: false };
    } else if (page && page.a && !authenticated) {
      if (isAuthInProgress) {
        return { content: <PageLoader />, isPage: false };
      }

      if (config.setPages && config.setPages['/login']) {
        const content = craftJsParser({
          craftState: projectData,
          rootNodeId: 'ROOT',
          pageLayoutId: config.setPages['/login'].page_layout_id,
          pageId: config.setPages['/login'].page_id,
          pageType: 'page',
          options: {
            authenticated,
          },
          withProvider: true,
        });

        return { content: <div className="page-layout">{content}</div>, isPage: false };
      }
    }

    const content = craftJsParser({
      craftState: projectData,
      rootNodeId: 'ROOT',
      pageLayoutId: id,
      pageId,
      pageType: 'layout',
      options: {
        authenticated,
      },
      withProvider: true,
    });

    return { content, isPage: true };
  }, [action, config, projectData, page, authenticated, isAuthInProgress]);

  if (!data.isPage) {
    return data.content;
  }

  return <div className="page-layout">{data.content}</div>;
};

export default PageLayout;
