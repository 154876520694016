export const IS_EMAIL = 1;
export const IS_PHONE = 2;
export const IS_SEAMLESS_TOKEN = 3; 
export const IS_NOT = 0;
export const IS_UNDEFINED = undefined;
export const METHODS = {
  phone: 'PHONE_NUMBER',
  email: 'EMAIL_ADDRESS',
  etok: 'ETOK'
};
