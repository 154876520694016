import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import LockIcon from '@material-ui/icons/Lock';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { gsap, TimelineMax, TweenMax, TweenLite, Power1, Linear, CustomEase } from 'gsap/all';


import './result-dialog.scss';
import SimpleTimer from '../components/simple_timer';
import CloseIcon from '../assets/close.svg';
import { imgPath } from '../utils/functions';
import EvBusMain from '../../../utils/evbus';
import { freeBetsFetch, freeSpinsFetch } from '../../casino/store/actions';
import KeysRulesDialog from '../components/keys-rules';
import { getData, styleToObj } from '../../lobby/utils/functions';
import { useMediaQuery } from '@/components/utils/useQueryMedia';

gsap.registerPlugin(TweenMax, TimelineMax, TweenLite, Power1, Power1, Linear, CustomEase);

gsap.registerEffect({
  name: 'counter',
  extendTimeline: true,
  defaults: {
    end: 0,
    duration: 0.5,
    ease: 'power4',
    increment: 1,
  },
  effect: (targets, config) => {
    if (targets && targets.length) {
      let tl = gsap.timeline();
      let num = targets[0].innerText.replace(/,/g, '');
      targets[0].innerText = num;

      tl.to(targets, {
        duration: config.duration,
        innerText: config.end,
        //snap:{innerText:config.increment},
        modifiers: {
          innerText: function (innerText) {
            return gsap.utils.snap(config.increment, innerText).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        },
        ease: config.ease
      }, 0);

      return tl;
    }
    return null;
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const dialogBgStyle = {
  backgroundImage: `url(${imgPath('dialog-bg.png')})`
};

const getString = (val, def) => {
  if (val) return val;
  return def;
};

const getParsedValue = (val, def) => {
  const parsedValue = parseFloat(val);
  if (!isNaN(parsedValue)) return parsedValue;
  return def;
};

const durationByValue = (val, parameters) => {
  let retVal = 0;

  if (val < 11) {
    retVal = getParsedValue(parameters.counterL1, 800);
  } else if (val < 101) {
    retVal = getParsedValue(parameters.counterL2initial, 800) + val * getParsedValue(parameters.counterL2increment, 50);
  } else if (val < 501) {
    retVal = getParsedValue(parameters.counterL3initial, 1250) + val * getParsedValue(parameters.counterL3increment, 4);
  } else {
    retVal = getParsedValue(parameters.counterL4initial, 2050);
  }

  return retVal / 1000;
};


const ResultsDialog = (props) => {
  const { open, onClose, results, cfg, t } = props;

  const isDesktop = useMediaQuery('(min-width:900px)');

  const tl = React.useRef(null);

  const [openKeysRules, setOpenKeysRules] = React.useState(false);
  const navigate = useNavigate();

  const [mounted, setMounted] = React.useState(false);
  const handleRef = React.useCallback((node) => {
    if (node) {
      setMounted(true);
    } else {
      setMounted(false);
    }
  }, []);

  React.useEffect(() => {
    if (mounted) {
      if (tl.current) tl.current.kill();

      tl.current = gsap.timeline();

      let value = results.value;
      let multiplier = results.multiplier;
      if (results.specialPrize && (results.specialPrize.type === 1 || results.specialPrize.type === 2)) {
        multiplier = results.specialPrize.multiplier_final_value;
      }

      let valueDuration = durationByValue(value, cfg.parameters);
      let multiplierDuration = durationByValue(multiplier, cfg.parameters);
      let totalDuration = durationByValue(multiplier * value, cfg.parameters);
      let ease = getString(cfg.parameters.counterEase, 'power4');
      ease = ease.indexOf('M0,') === 0 ? CustomEase.create('custom', ease) : ease;

      tl.current.from('#results-value', { opacity: 0, duration: 1 });
      tl.current.counter('#results-value', { ease: ease, end: value, duration: valueDuration }, '-=0.5');
      tl.current.from('#result-multiplier-wrapper', { opacity: 0 }, '+=0.5');
      tl.current.counter('#results-multiplier', { ease: ease, end: multiplier, duration: multiplierDuration }, '-=0.5');
      tl.current.from('#result-total-wrapper', { opacity: 0 }, '+=0.5');
      tl.current.counter('#results-total', { ease: ease, end: multiplier * value, duration: totalDuration }, '-=0.5');
      tl.current.from('#full-wrapper', { y: 50 }, `-=${valueDuration + multiplierDuration + totalDuration}`);
    }

    return () => {
      if (tl.current) tl.current.kill();
    };
  }, [results, mounted, cfg]);

  const reSpin = (accept) => () => {
    onClose({ spin_id: results.specialPrize.spin_id, accept, type: 'reSpin' });
  };

  const spinAgain = () => {
    onClose({ results: results.specialPrize.extra_spin_results, type: 'spin' });
  };

  const handleClose = () => {
    if (results.productType === '2') { // Sport
      props.freeBetsFetch();
    } else if (results.productType === '3') { // Lotto
      props.freeBetsFetch();
    } else if (results.productType === '1') { // Casino
      props.freeSpinsFetch();
    }

    if (window.config && window.config.wheelResultsRedirect === '1') {
      if (results.productType === '2') { // Sport
        EvBusMain.emit('CLOSE-WHEEL-DIALOG');
        navigate.push('/bets/pre-match/1');
        return;
      } else if (results.productType === '3') { // Lotto
        EvBusMain.emit('CLOSE-WHEEL-DIALOG');
        navigate('/lotto');
        return;
      } else if (results.productType === '1') { // Casino
        EvBusMain.emit('CLOSE-WHEEL-DIALOG');
        navigate('/profile/wallet');
        return;
      } else if (results.productType === '5') { // Winner Fun
        EvBusMain.emit('CLOSE-WHEEL-DIALOG');
        navigate('/winnerfun/pre-match/1');
        return;
      }
    }

    onClose();
  };

  const handleOpenRules = () => {
    setOpenKeysRules(true);
  };
  const handleCloseRules = () => {
    setOpenKeysRules(false);
  };

  const isWildCard = results && results.specialPrize && results.specialPrize.type === 3 && results.specialPrize.spin_id;
  const isDoubleSpin = results && results.specialPrize && results.specialPrize.type === 4 && results.specialPrize.extra_spin_results;

  const mainStyle = { ...dialogBgStyle, ...styleToObj(getData(cfg, 'skinning.result_window_image_background.style', '')) };
  const mainBGUrl = getData(cfg, 'skinning.result_window_image_background.url', '');
  if (mainBGUrl) {
    mainStyle.backgroundImage = `url("${mainBGUrl}")`;
  }

  return (
    <Dialog
      fullScreen={!isDesktop}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className="wheel-result-dialog"
    >
      <div className="result-dialog-body" style={mainStyle}>
        <div className="close-header" onClick={handleClose}>
          <div className="close-icon">
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className="results-dialog-logo">
          <img src={imgPath('winner-logo.svg')} alt="" />
        </div>
        <div className="result-dialog-title">
          {t('You won')}!
        </div>
        <div className={`wrapper-info ${results.spinType === 'free' ? '' : 'mon'}`}>
          <div className="center-piece">
            <div className="top-piece"></div>
            <div className="product">
              <div className="product-title">
                {results.title}
              </div>
              <div className="product-subtitle">
                {results.subtitle}
              </div>
            </div>
            <div className="bottom-piece"></div>
          </div><div className="outer-wrapper">
            <div id="full-wrapper">
              <div className="value-piece" id="results-value" ref={handleRef}>
                0
              </div>
              <div className="value-name">
                {results.productSubtype === '3' ? t('rotiri gratuite') : results.productSubtype === '5' ? 'wincoins' : 'lei'}
              </div>

              <div className="multiplier-piece" id="result-multiplier-wrapper">
                <div className="naming">
                  <div className="lock-icon"><LockIcon /></div>
                  <div className="txt">x<span id="results-multiplier">0</span></div>
                  <div className="lock-icon"><LockIcon /></div>
                </div>
              </div>

              <div className="total-piece" id="result-total-wrapper">
                <div>
                  <div className={`value ${results.spinType === 'free' ? 'strike-through' : ''}`} id="results-total">
                    0
                  </div>
                  <div className="value-name">
                    {results.productSubtype === '3' ? t('rotiri gratuite') : results.productSubtype === '5' ? 'wincoins' : 'lei'}
                  </div>
                </div>
                {results.spinType === 'free' && <div className="with-keys-text">
                  {t('Your winnings if the spin was with the keys')}
                </div>}
              </div>
            </div>
          </div>
        </div>

        {results.spinType === 'free' && <div className="multiplier-info-text" onClick={handleOpenRules}>
          {t('How to activate multiplier?')} <span className="sep">|</span> <span className="question">?</span>
        </div>}

        <div className="buttons-column">
          {isWildCard && <div className="timer">
            <div className="txt">{t('The prize will be automatically applied in')}:</div>
            <div className="counter">
              <SimpleTimer date={moment(results.specialPrize.accept_time_limit, 'x')} onLive={reSpin(true)} />
            </div>
          </div>}
          {isDoubleSpin && <div className="highlighted" onClick={spinAgain}>
            <div className="middle">
              <div className="inner">
                <div className="txt">
                  {t('One more spin left')}
                </div>
              </div>
            </div>
          </div>}
          {isWildCard && <div className="highlighted" onClick={reSpin(false)}>
            <div className="middle">
              <div className="inner">
                <div className="txt">
                  {t("I'll try again")}
                </div>
              </div>
            </div>
          </div>}

          {isWildCard && <div className="regular" onClick={reSpin(true)}>{t('Activate prize')}</div>}
          {!isWildCard && !isDoubleSpin && <div className="regular" onClick={handleClose}>{t('Activate prize')}</div>}
        </div>
      </div>
      {openKeysRules && <KeysRulesDialog open={openKeysRules} type={'multiplier'} onClose={handleCloseRules} />}
    </Dialog>
  );
};

const mapActions = {
  freeBetsFetch,
  freeSpinsFetch
};


export default connect(null, mapActions)(withTranslation()(ResultsDialog));
