import { put, takeLatest } from 'redux-saga/effects';

import { deposit } from '../actions/actionTypes';
import ClientAPI from './../../ClientAPI/ClientAPI';
import {
  betLionDepositInitiatedFailed,
  betLionDepositInitiatedPending,
  betLionReceivedAlternativePhoneNumber,
  betLionReceivedChannels,
  betLionReceivedEkwanza,
  betLionReceivedPayPay,
  bridgerApplePayDepositResponse,
  bridgerApplePayInitiatedFailed as initiateBridgerApplePayDepositFailed,
  bridgerPayDepositResponse,
  bridgerPayInitiatedFailed as initiateBridgerPayDepositFailed,
  bridgerPayInitiatedSuccess as initiateBridgerPayDepositSuccess,
  oktoReceivedCustomer,
  oktoReceivedPaymentCode,
  receivedAircashPaymentLink,
  receivedPlayerPaymentMethods,
  receivedTax,
} from '../actions/deposit';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';
import { BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY } from '@/constants/paymentProvider';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';

function* initiateBridgerPayDepositSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  const data = action.data;
  if (PlayOnline.getGclid()) {
    data.gclid = PlayOnline.getGclid();
  }
  try {
    const response = yield axios({
      url: '/api/pay-checkout/initiate-bridger-pay-deposit',
      method: 'post',
      data: data,
    });
    if (!response.status) {
      throw new Error('[Bridger Pay] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[Bridger Pay] Status NOK');
    }
    if (!response.result) {
      throw new Error('[Bridger Pay] Empty result');
    }
    if (!response.result.cashier_token) {
      throw new Error('[Bridger Pay] Empty result');
    }
    yield put(initiateBridgerPayDepositSuccess(response.result));
  } catch (e) {
    console.log('[Bridger Pay Init]', e);
    yield put(initiateBridgerPayDepositFailed());
  }
}

function* initiateBridgerApplePayDepositSaga(action: any): any {
  const MINIMUM_SESSION_TIME = 600; // 10 minutes

  const storedData = ExpiringLocalStorage.getWithExpiry(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);

  if (storedData?.ttl > MINIMUM_SESSION_TIME) {
    yield put(bridgerApplePayDepositResponse(storedData.value));
  } else {
    const axios = ClientAPI.getInstance();
    const pac = PlayerAbuseChecker.getInfo();
    const data = {
      amount: action.data.amount,
      currencyId: 1, // TODO: change to currencyCode
      ...pac,
    };

    if (action.data.bonusId) {
      // @ts-ignore
      data.bonusId = action.data.bonusId;
    }
    if (action.data.rewardId) {
      // @ts-ignore
      data.rewardId = action.data.rewardId;
    }


    if (PlayOnline.getGclid()) {
      // @ts-ignore
      data.gclid = PlayOnline.getGclid();
    }
    try {
      const response = yield axios({
        url: '/api/pay-checkout/initiate-bridger-apple-pay-deposit',
        method: 'post',
        data: data,
      });
      if (!response.status) {
        throw new Error('[Bridger Pay] No status response');
      }

      if (!('OK' === response.status)) {
        throw new Error('[Bridger Pay] Status NOK');
      }
      if (!response.result) {
        throw new Error('[Bridger Pay] Empty result');
      }
      if (!response.result.cashier_token) {
        throw new Error('[Bridger Pay] Empty result');
      }

      if (response.result?.deposit_id) {
        ExpiringLocalStorage.set(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY, response.result, response.result.expires_in ?? 0);
      }

      yield put(bridgerApplePayDepositResponse(response.result));
    } catch (e) {
      console.log('[Bridger Pay Init]', e);
      yield put(initiateBridgerApplePayDepositFailed());
    }
  }
}

function* updateBridgerApplePayDepositSaga(action: any): any {
  const storedData = ExpiringLocalStorage.get(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);

  if (storedData) {
    const axios = ClientAPI.getInstance();
    const data = {
      amount: action.data.amount,
      depositId: storedData?.deposit_id,
    };

    if (action.data.bonusId) {
        // @ts-ignore
        data.bonusId = action.data.bonusId;
    }
    if (action.data.rewardId) {
        // @ts-ignore
        data.rewardId = action.data.rewardId;
    }
    try {
      const response = yield axios({
        url: '/api/pay-checkout/update-bridger-apple-pay-deposit',
        method: 'post',
        data: data,
      });
      if (!response.status) {
        throw new Error('[Bridger Pay] No status response');
      }

      if (!('OK' === response.status)) {
        throw new Error('[Bridger Pay] Status NOK');
      }
    } catch (e) {
      console.log('[Bridger Pay Init]', e);
      yield put(initiateBridgerApplePayDepositFailed());
    }
  }
}

function* finishBridgerPayDepositSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  const { data } = action;
  const state = ClientAPI.getStore().getState();
  // {
  //               amount: this.paymentDetails.amount,
  //               bonusId: this.paymentDetails.bonusId,
  //               card: {
  //                 token: response.token,
  //                 encryptedCVV: response.encryptedCvv,
  //                 bin: response.card.bin,
  //               },
  //             }

  try {
    if (data.card?.bin) {
      const response = yield axios({
        url: '/api/pay-checkout/bridger-pay-fingerprint',
        method: 'post',
        data: {
          currency: state.currencies.currentCurrency,
          cardBin: data.card.bin,
        },
      });
      if (!response.status) {
        throw new Error('[Bridger Pay] No status response');
      }

      if (!('OK' === response.status)) {
        throw new Error('[Bridger Pay] Status NOK');
      }
      if (!response.result) {
        throw new Error('[Bridger Pay] Empty result');
      }
      if (!response.result.html) {
        throw new Error('[Bridger Pay] Empty result');
      }
      // create iframe with response.result.html
      const iframe = document.createElement('iframe');
      iframe.srcdoc = response.result.html;
      iframe.setAttribute('style', 'display: none;');
      document.body.prepend(iframe);

      yield put(bridgerPayDepositResponse({ fingerprintReceived: true }));
    } else {
      const cardToken = data?.card?.token;
      if (!cardToken) {
        return;
      }
      let body: any = {
        currencyId: 1,
        amount: data.amount * 100,
        cardToken: cardToken,
        encryptedCvv: data.card.encryptedCVV,
        fingerprint: data.fingerprint,
      };
      if (data.bonusId) {
        body.bonusId = data.bonusId;
      }
      if (data.rewardId) {
        body.rewardId = data.rewardId;
      }
      const pac = PlayerAbuseChecker.getInfo();
      body = {
        ...pac,
        ...body,
      };

      if (PlayOnline.getGclid()) {
        body.gclid = PlayOnline.getGclid();
      }
      const response = yield axios({
        url: '/api/pay-checkout/bridger-pay-deposit',
        method: 'post',
        data: body,
      });
      // {
      //     "status": "OK",
      //     "result": {
      //         "status": "approved",
      //         "has_3ds_challenge": false,
      //         "url_3ds": null
      //     },
      //     "type": "bridger-deposit"
      // }
      if (!response.status) {
        throw new Error('[Bridger Pay] No status response');
      }
      if (!('OK' === response.status)) {
        throw new Error('[Bridger Pay] Status NOK');
      }
      if (!response.result) {
        throw new Error('[Bridger Pay] Empty result');
      }
      if (!response.result.status) {
        throw new Error('[Bridger Pay] Empty result');
      }

      yield put(bridgerPayDepositResponse(response.result));
    }
  } catch (e) {
    console.log('[Bridger Pay Init]', e);
    yield put(bridgerPayDepositResponse({ error: true }));
  }
}

export function* requestOktoCreatePaymentCodeSaga(action: any) {
  const axios = ClientAPI.getInstance();
  try {
    let data: {
        amount: number;
        currencyId: number;
        bonusId?: number;
        rewardId?: number;
        gclid?: any;
        platformType?: number;
        dvfo?: any;
        nwfp?: any;
        newfp?: any;
    } = {
      amount: (action.paymentDetails.amount ?? 0) * 100,
      currencyId: 1, // TODO: change to currencyCode
    };

    if (action.paymentDetails.bonusId) {
      data.bonusId = action.paymentDetails.bonusId;
    }

    if (action.paymentDetails.rewardId) {
        data.rewardId = action.paymentDetails.rewardId;
    }

    const pac = PlayerAbuseChecker.getInfo();

    data = {
      ...pac,
      ...data,
    };

    if (PlayOnline.getGclid()) {
      data.gclid = PlayOnline.getGclid();
    }

    const platformType = window.config.cordova
      ? window.config.native_platform === 'ios'
        ? 3
        : 4
      : window.config.platformType ?? 5;
    // @ts-ignore
    data.platformType = platformType;

    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/okto-create-payment-code',
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Request Okto Create Payment Code response is empty!');
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Request Okto Create Payment Code response has no 'result' property");
    }

    if (response.result.http) {
      throw new Error('[ERROR] Request Okto Create Payment Code response has an http status');
    }

    yield put(oktoReceivedPaymentCode(response.result));
  } catch (error) {
    yield put(oktoReceivedPaymentCode({ error: true }));
    console.log(error);
    return;
  }
}

export function* oktoGetCustomerSaga(action: any) {
  const axios = ClientAPI.getInstance();
  try {
    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/okto-get-customer',
      method: 'GET',
    });

    if (!response) {
      throw new Error('[ERROR] Request Okto GET Customer response is empty!');
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Request Okto GET CUSTOMER response has no 'result' property");
    }

    yield put(oktoReceivedCustomer(response.result));
  } catch (error) {
    return;
  }
}

export function* requestAircashPaymentLinkSaga(action: any) {
  const axios = ClientAPI.getInstance();
  const state = ClientAPI.getStore().getState();
  try {
    const pac = PlayerAbuseChecker.getInfo();
    const platformType = window.config.cordova
      ? window.config.native_platform === 'ios'
        ? 3
        : 4
      : window.config.platformType;

    const data = {
      amount: (action.paymentDetails.amount ?? 0) * 100,
      currencyId: 1,
      currencyCode: state.currencies.currentCurrency,
      languageCode: state.application.language,
      clientId: window.config.clientId,
      ...pac,
      platformType: platformType,
    };
    if (PlayOnline.getGclid()) {
      // @ts-ignore
      data.gclid = PlayOnline.getGclid();
    }
    if (action.paymentDetails.bonusId) {
      // @ts-ignore
      data.bonus_id = action.paymentDetails.bonusId;
    }
    if (action.paymentDetails.rewardId) {
        // @ts-ignore
        data.rewardId = action.paymentDetails.rewardId;
    }

    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/aircash-checkout',
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Payment Checkout response is empty!');
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Payment Checkout response has no 'result' property");
    }

    yield put(receivedAircashPaymentLink(response.result));
  } catch (error) {
    yield put(receivedAircashPaymentLink({ error: true }));
    return;
  }
}

export function* fetchPlayerPaymentMethodsSaga(action: any) {
  const axios = ClientAPI.getInstance();

  try {
    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/get-player-payment-methods',
      method: 'get',
    });
    if (!response) {
      throw new Error('[ERROR] Player payment methods response is empty!');
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Player payment methods response has no 'result' property");
    }

    // if we have a http result, we can add it to the server response
    if (response.result.http) {
      throw new Error('[ERROR] Player payment methods response has an http status');
    }

    yield put(receivedPlayerPaymentMethods(response.result));
  } catch (error) {
    yield put(receivedPlayerPaymentMethods({ error: true }));
    return;
  }
}

function* initiateBetLionDepositSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  const currentCurrency = ClientAPI.getStore().getState().currencies.currentCurrency;
  const data = {
    amount: action.data.amount * 100,
    currencyCode: currentCurrency,
    ...PlayerAbuseChecker.getInfo(),
  };

  if(action.data.bonusId) {
    // @ts-ignore
    data.bonusId = action.data.bonusId;
  }
  if(action.data.rewardId) {
    // @ts-ignore
      data.rewardId = action.data.rewardId;
  }

  if (PlayOnline.getGclid()) {
    // @ts-ignore
    data.gclid = PlayOnline.getGclid();
  }
  let url = '/api/pay-checkout/initiate-betlion-deposit';
  if (action.data.channelId === 'ekwanza') {
    url = '/api/pay-checkout/betlion-ekwanza-code';
  } else if (action.data.channelId === 'paypay') {
    url = '/api/pay-checkout/betlion-paypay';
  }
  try {
    const response = yield axios({
      url: url,
      method: 'post',
      data: data,
    });
    if (!response.status) {
      throw new Error('[Bet Lion] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[Bet Lion] Status NOK');
    }
    if (!response.result) {
      throw new Error('[Bet Lion] Empty result');
    }
    if (action.data.channelId === 'ekwanza') {
      if (!response.result.payment_code) {
        throw new Error('[Bet Lion] Empty result');
      }

      yield put(betLionReceivedEkwanza(response.result.payment_code));
    } else if (action.data.channelId === 'paypay') {
      if (!response.result.url) {
        throw new Error('[Bet Lion] Empty url result');
      }
      if (!response.result.qrcode) {
        throw new Error('[Bet Lion] Empty qrcode result');
      }
      yield put(betLionReceivedPayPay(response.result.url, response.result.qrcode));
    } else {
      if (!response.result.status) {
        throw new Error('[Bet Lion] Empty result');
      }
      if (response.result.status !== 'Pending') {
        throw new Error('[Bet Lion] Status not pending');
      }

      yield put(betLionDepositInitiatedPending());
    }
  } catch (e) {
    console.log('[Bridger Pay Init]', e);
    yield put(betLionDepositInitiatedFailed());
  }
}

function* betlionGetChannelsSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  const currentCurrency = ClientAPI.getStore().getState().currencies.currentCurrency;
  try {
    const response = yield axios({
      url: '/api/pay-checkout/betlion-get-channels',
      method: 'POST',
      data: {
        paymentTypeId: action.channelsType, //deposit
        currencyCode: currentCurrency,
      },
    });
    if (!response.status) {
      throw new Error('[Bet Lion] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[Bet Lion] Status NOK');
    }
    if (!response.result) {
      throw new Error('[Bet Lion] Empty result');
    }
    if (!response.result.channels) {
      throw new Error('[Bet Lion] Empty result');
    }
    yield put(
      betLionReceivedChannels({
        channelsType: action.channelsType,
        ...response.result,
      }),
    );
    // yield put(betLionGetChannelsSuccess(response.result));
  } catch (e) {
    yield put(betLionReceivedChannels([]));
    console.log('[Bridger Pay Init]', e);
    // yield put(betLionGetChannelsFailed());
  }
}

function* betLionGetAlternativePhoneNumberSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  const currentCurrency = ClientAPI.getStore().getState().currencies.currentCurrency;
  try {
    const response = yield axios({
      url: '/api/pay-checkout/betlion-alternative-number',
      method: 'POST',
      data: {
        currencyCode: currentCurrency,
      },
    });
    if (!response.status) {
      throw new Error('[Bet Lion] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[Bet Lion] Status NOK');
    }
    if (!response.result) {
      throw new Error('[Bet Lion] Empty result');
    }
    if (!response.result.alternative_number) {
      throw new Error('[Bet Lion] Empty result');
    }
    yield put(betLionReceivedAlternativePhoneNumber(response.result.alternative_number));
    // yield put(betLionGetChannelsSuccess(response.result));
  } catch (e) {
    yield put(betLionReceivedAlternativePhoneNumber(''));
    console.log('[Bridger Pay Init]', e);
    // yield put(betLionGetChannelsFailed());
  }
}

function* getDepositTaxSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  try {
    const url = window.config.useLegacyTaxation === '1' ? '/api/profile/calculateTax' : '/api/profile/calculateTaxes';
    let data :any = {};
    if(window.config.useLegacyTaxation === '1') {
      data = {
        amount: action.data[0].totalAmount/100,
        type: action.data[0].taxType,
        key: action.data[0].key,
      };
    } else {
      data = {
        taxes: action.data
      };
    }

    const response = yield axios({
      url: url,
      method: 'POST',
      data: data,
    });
    if (!response.status) {
      throw new Error('[Deposit Tax] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[DepositTax] Status NOK');
    }
    if (!response.result) {
      throw new Error('[DepositTax] Empty result');
    }
    let result = {};

    if (window.config.useLegacyTaxation === '1') {
        result = {
            [data.key]: {
              rawTaxAmount: response.result.tax*100,
                taxAmount: response.result.tax*100,
                originalAmount: data.amount*100,
                amountAfterTax: (response.result.amount - response.result.tax) *100,

            }
        };

    } else {
        result = response.result;
    }

    yield put(receivedTax(result));
  } catch (e) {
    console.log('[DepositTax]', e);
    // yield put(betLionGetChannelsFailed());
  }
}

export default function* watchDeposit() {
  yield takeLatest(deposit.INITIATE_BRIDGER_PAY_DEPOSIT, initiateBridgerPayDepositSaga);
  yield takeLatest(deposit.FINISH_BRIDGER_PAY_DEPOSIT, finishBridgerPayDepositSaga);

  yield takeLatest(deposit.INITIATE_BRIDGER_APPLE_PAY_DEPOSIT, initiateBridgerApplePayDepositSaga);
  yield takeLatest(deposit.UPDATE_BRIDGER_APPLE_PAY_DEPOSIT, updateBridgerApplePayDepositSaga);

  yield takeLatest(deposit.INITIATE_BETLION_DEPOSIT, initiateBetLionDepositSaga);
  yield takeLatest(deposit.BETLION_GET_CHANNELS, betlionGetChannelsSaga);
  yield takeLatest(deposit.BETLION_GET_ALTERNATIVE_PHONE_NUMBER, betLionGetAlternativePhoneNumberSaga);

  yield takeLatest(deposit.OKTO_CREATE_PAYMENT_CODE, requestOktoCreatePaymentCodeSaga);
  yield takeLatest(deposit.OKTO_GET_CUSTOMER, oktoGetCustomerSaga);

  yield takeLatest(deposit.AIRCASH_REQUEST_PAYMENT_LINK, requestAircashPaymentLinkSaga);

  yield takeLatest(deposit.FETCH_PLAYER_PAYMENT_METHODS, fetchPlayerPaymentMethodsSaga);

  yield takeLatest(deposit.GET_TAX, getDepositTaxSaga);
}
