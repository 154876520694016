import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import urlonStringify from '@/utils/urlon';

import { RootState } from '..';
import { sortSlotGameDataSources } from './dataSources';

interface SlotGame {
  id: number | string;
  name: string;
  provider_id: string;
  _pinned?: boolean;
}

interface ReelGame {
  gameId: number | string;
  name: string;
  category: number | string;
  backgroundImagePath?: string;
  headerLogoPath?: string;
  headerLogoTextPath?: string;
  hlsPath?: string;
}

type Game = SlotGame | ReelGame;

type GamesOrderMap = Record<string | 'length', number>;

interface PlayerGamesOrder {
  items: GamesOrderMap;
  loading: boolean;
  loaded: boolean;
  error?: string;
}

type FetchConfigProps = void;

type FetchConfigResult = {
  data: SlotGame[];
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

const DEBUG = false;

const apiUrl = window.config.dataSourceApiUrl;
const dataSourceId = window.config.dataSourceAllPlayerGames;

export const fetchPlayerGamesOrder = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'playerGamesOrder/list',
  async (props, { dispatch, getState, rejectWithValue }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(`${apiUrl}/resolve/sources`, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
        params: {
          q: urlonStringify({ ids: [dataSourceId] }),
        },
      });

      if (response.data && typeof response.data === 'object' && response.data[dataSourceId]) {
        setTimeout(() => {
          dispatch(sortSlotGameDataSources(gamesToIdsMap(response.data[dataSourceId].data)));
        }, 500);

        return { data: response.data[dataSourceId].data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch sources",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };

      return rejectWithValue(errResp);
    }
  },
);

export function initialGamesOrderMap(): GamesOrderMap {
  return {
    length: 0,
  };
}

export const playerGamesOrderSlice = createSlice({
  name: 'playerGamesOrder',
  initialState: <PlayerGamesOrder>{
    items: initialGamesOrderMap(),
    loading: false,
    loaded: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerGamesOrder.fulfilled, (state, action) => {
        if (action.payload.success && action.payload.data) {
          state.loading = false;
          state.loaded = true;
          state.items = gamesToIdsMap(action.payload.data);
        }

        DEBUG && console.log('fetchPlayerGamesOrder.fulfilled', action.payload);
      })
      .addCase(fetchPlayerGamesOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPlayerGamesOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;

        DEBUG && console.log('fetchPlayerGamesOrder.rejected', action.payload);
      });
  },
});

function isSlotGame(game: Game): game is SlotGame {
  return game && typeof game === 'object' && 'id' in game;
}

function isReelGame(game: Game): game is ReelGame {
  return game && typeof game === 'object' && 'gameId' in game;
}

function isGameDataSource(dataSource: any): boolean {
  return dataSource && dataSource?.element_type?.type_id === 'slot_game';
}

function isReelDataSource(dataSource: any): boolean {
  return dataSource && dataSource?.element_type?.type_id === 'video-reel';
}

function gamesToIdsMap(data: SlotGame[]): GamesOrderMap {
  const map: GamesOrderMap = {};

  data.forEach((game, index) => {
    map[game.id] = index;
  });

  map.length = data.length;

  return map;
}

const BLACKLIST: string[] = ['slot-games-cold', 'slot-games-hot', 'slot-games-popular-now'];

export function sortGameDataSources(
  dataSources: Record<string, any>,
  orderMap: GamesOrderMap = initialGamesOrderMap(),
): Record<string, any> {
  if (!orderMap.length) {
    return {};
  }

  const gameDataSources: Record<string, any> = {};

  Object.keys(dataSources).forEach((sourceId) => {
    if (sourceId === dataSourceId || BLACKLIST.includes(sourceId)) {
      return;
    }

    const dataSource = dataSources[sourceId];

    if (isGameDataSource(dataSource) || isReelDataSource(dataSource)) {
      // First, sort only unpinned games
      const unpinnedGames = dataSource.data
        .filter((game: Game) => (isSlotGame(game) ? !game._pinned : true))
        .sort((a: Game, b: Game) => {
          // If not found in order map, put them at the end of the list
          const firstItem = orderMap[isSlotGame(a) ? a.id : a.gameId] ?? orderMap.length;
          const secondItem = orderMap[isSlotGame(b) ? b.id : b.gameId] ?? orderMap.length + 1;

          return firstItem - secondItem;
        });

      const sorted: SlotGame[] = [];
      let sortedIndex = 0;

      // Second, push pinned games in same position and unpinned games in between
      for (let i = 0; i < dataSource.data.length; i++) {
        if (dataSource.data[i]._pinned) {
          sorted.push(dataSource.data[i]);
        } else {
          sorted.push(unpinnedGames[sortedIndex]);
          sortedIndex++;
        }
      }

      gameDataSources[sourceId] = {
        ...dataSource,
        data: sorted,
      };
    }
  });

  return gameDataSources;
}

export function hasGameDataSources(dataSources: Record<string, any>): boolean {
  let hasGameDataSources = false;

  Object.keys(dataSources).every((sourceId) => {
    const dataSource = dataSources[sourceId];

    if (isGameDataSource(dataSource)) {
      hasGameDataSources = true;

      return false;
    }

    return true;
  });

  return hasGameDataSources;
}

export default playerGamesOrderSlice.reducer;
